import styled from "styled-components";
// Components
// Assets
import HeaderImage from "../../../assets/img/header-img.jpg";
import Dots from "../../../assets/svg/Dots";
import QuotesIcon from "../../../assets/svg/Quotes";
import { darkColor } from "../../../constants/colorConstants";

export default function Header() {
	return (
		<Wrapper id="home" className="container flexSpaceCenter">
			<LeftSide className="flexCenter">
				<div>
					<h1 className="extraBold font60" style={{ color: darkColor }}>
						Your Trusted HR Partner
					</h1>
					<HeaderP className="font18 semiBold">
						{/* Numla Asia Pvt Ltd, a sister concern of Numla Limited (numla.com) based in Ireland, is poised to
						revolutionize the HR services landscape in Pakistan through its strategic collaboration with
						ALLIED SERVICES. With a strong foundation in innovative HR solutions and a global perspective,
						Numla Asia is set to bring international standards of excellence to the local market. ALLIED
						SERVICES, renowned for its expertise in providing HR contractual services to the corporate
						sector in Pakistan, will benefit from Numla Asia’s cutting-edge technologies and methodologies.
						This partnership aims to enhance the quality and efficiency of HR services, offering bespoke
						solutions that meet the dynamic needs of businesses in Pakistan. Together, Numla Asia (Pvt.)
						Ltd. committed to empowering organizations with the best talent and HR practices, driving growth
						and success across the corporate landscape. */}
						At Numla Asia Pvt. Ltd., we&apos;re transforming HR services in Pakistan through our partnership
						with Allied Services. Backed by global expertise from our sister company, Numla Limited
						(Ireland), we&apos;re here to provide innovative, tailored solutions that meet the unique needs
						of local businesses.
					</HeaderP>
					{/* <BtnWrapper>
						<FullButton title='Get Started' />
					</BtnWrapper> */}
				</div>
			</LeftSide>
			<RightSide>
				<ImageWrapper>
					<Img className="radius8" src={HeaderImage} alt="office" style={{ zIndex: 9 }} />
					<QuoteWrapper className="flexCenter darkBg radius8">
						<QuotesWrapper>
							<QuotesIcon />
						</QuotesWrapper>
						<div>
							<p className="font15 whiteColor">
								<em>
									At Numla Asia Pvt Ltd, We are more than just an HR services company we are a symbol
									of efficiency, teamwork, and resilience, much like the ants from which we draw our
									inspiration.
								</em>
							</p>
							<p className="font13 orangeColor textRight" style={{ marginTop: "10px" }}></p>
						</div>
					</QuoteWrapper>
					<DotsWrapper>
						<Dots />
					</DotsWrapper>
				</ImageWrapper>
				<GreyDiv className="lightBg"></GreyDiv>
			</RightSide>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	padding-top: 80px;
	width: 100%;
	min-height: 840px;
	@media (max-width: 960px) {
		flex-direction: column;
	}
`;
const LeftSide = styled.div`
	width: 50%;
	height: 100%;
	@media (max-width: 960px) {
		width: 100%;
		order: 2;
		margin: 50px 0;
		text-align: center;
	}
	@media (max-width: 560px) {
		margin: 80px 0 50px 0;
	}
`;
const RightSide = styled.div`
	width: 50%;
	height: 100%;
	@media (max-width: 960px) {
		width: 100%;
		order: 1;
		margin-top: 30px;
	}
`;
const HeaderP = styled.div`
	max-width: 470px;
	padding: 15px 0 50px 0;
	line-height: 1.5rem;
	@media (max-width: 960px) {
		padding: 15px 0 50px 0;
		text-align: center;
		max-width: 100%;
	}
`;
const BtnWrapper = styled.div`
	max-width: 190px;
	@media (max-width: 960px) {
		margin: 0 auto;
	}
`;
const GreyDiv = styled.div`
	width: 30%;
	height: 700px;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 0;
	@media (max-width: 960px) {
		display: none;
	}
`;
const ImageWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	position: relative;
	z-index: 9;
	@media (max-width: 960px) {
		width: 100%;
		justify-content: center;
	}
`;
const Img = styled.img`
	@media (max-width: 560px) {
		width: 80%;
		height: auto;
	}
`;
const QuoteWrapper = styled.div`
	position: absolute;
	left: 0;
	bottom: 50px;
	max-width: 330px;
	padding: 30px;
	z-index: 99;
	@media (max-width: 960px) {
		left: 20px;
	}
	@media (max-width: 560px) {
		bottom: -50px;
	}
`;
const QuotesWrapper = styled.div`
	position: absolute;
	left: -20px;
	top: -10px;
`;
const DotsWrapper = styled.div`
	position: absolute;
	right: -100px;
	bottom: 100px;
	z-index: 2;
	@media (max-width: 960px) {
		right: 100px;
	}
	@media (max-width: 560px) {
		display: none;
	}
`;
