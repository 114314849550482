import { useNavigate } from "react-router-dom";
import { EditSupervisorPayloadInterface } from "../../../utils/types";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/config";
import { USERS_COLLECTION } from "../../../constants/firebaseConstants";
import { enqueueSnackbar } from "notistack";
import { genericErrorFunction } from "../../../utils/helpers";
import SupervisorFields from "../../../components/authedComponents/crudComponents/Supervisor/SupervisorFields";

export default function EditSupervisor() {
	const navigate = useNavigate();

	const editSupervisor = async (payload: EditSupervisorPayloadInterface) => {
		try {
			const { id, ...rest } = payload;
			await setDoc(doc(firestore, USERS_COLLECTION, id), rest)
				.then(() => {
					enqueueSnackbar("Supervisor Edited!", { variant: "success" });
				})
				.finally(() => {
					navigate(-1);
				});
		} catch (error) {
			genericErrorFunction({ logMessage: "Error occurred while editing supervisor: ", error });
		}
	};

	return <SupervisorFields type="Edit" apiCall={editSupervisor} />;
}
