import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
	ADMINS_,
	CLIENTS_,
	COMPLAINT_,
	COMPLAINTS_,
	CONTACTS_,
	DASHBOARD_,
	FORGOT_PASSWORD_,
	INVENTORY_,
	ISSUE_CATEGORIES_,
	LOGIN_,
	SUPERVISORS_,
} from "./constants/textConstants";
import AddAdmin from "./pages/authedPages/admins/Add";
import Admins from "./pages/authedPages/admins/Admins";
import EditAdmin from "./pages/authedPages/admins/Edit";
import ViewAdmin from "./pages/authedPages/admins/View";
import AddClient from "./pages/authedPages/clients/Add";
import Clients from "./pages/authedPages/clients/Clients";
import EditClient from "./pages/authedPages/clients/Edit";
import ViewClient from "./pages/authedPages/clients/View";
import Contacts from "./pages/authedPages/contacts/Contacts";
import ViewContacts from "./pages/authedPages/contacts/View";
import Dashboard from "./pages/authedPages/dashboard/Dashboard";
import AddIssueCategories from "./pages/authedPages/issue-categories/Add";
import EditIssueCategories from "./pages/authedPages/issue-categories/Edit";
import IssueCategories from "./pages/authedPages/issue-categories/IssueCategories";
import ViewIssueCategories from "./pages/authedPages/issue-categories/View";
import AddSupervisors from "./pages/authedPages/supervisors/Add";
import EditSupervisor from "./pages/authedPages/supervisors/Edit";
import Supervisors from "./pages/authedPages/supervisors/Supervisors";
import ViewSupervisor from "./pages/authedPages/supervisors/View";
import AddComplaint from "./pages/unAuthedPages/AddComplaint";
import ForgotPassword from "./pages/unAuthedPages/ForgotPassword";
import Landing from "./pages/unAuthedPages/Landing";
import ListComplaints from "./pages/unAuthedPages/ListComplaints";
import Login from "./pages/unAuthedPages/Login";
import NotFound from "./pages/unAuthedPages/NotFound";
import AuthedRouteWrapper from "./wrappers/AuthedRouteWrapper";
import UnAuthedRouteWrapper from "./wrappers/UnAuthedRouteWrapper";
import ViewComplaints from "./pages/authedPages/complaints/View";
import Complaints from "./pages/authedPages/complaints/Complaints";
import Inventory from "./pages/authedPages/inventory/Inventory";
import ViewInventory from "./pages/authedPages/inventory/View";

const routes = [
	{
		index: true,
		path: "/",
		element: (
			<UnAuthedRouteWrapper>
				<Landing />
			</UnAuthedRouteWrapper>
		),
	},
	{
		path: "/404",
		element: <NotFound />,
	},
	{
		path: LOGIN_,
		element: (
			<UnAuthedRouteWrapper>
				<Login />
			</UnAuthedRouteWrapper>
		),
	},
	{
		path: FORGOT_PASSWORD_,
		element: (
			<UnAuthedRouteWrapper>
				<ForgotPassword />
			</UnAuthedRouteWrapper>
		),
	},
	{
		path: `${COMPLAINT_}/add`,
		element: (
			<UnAuthedRouteWrapper>
				<AddComplaint />
			</UnAuthedRouteWrapper>
		),
	},
	{
		path: `${COMPLAINT_}/list`,
		element: (
			<UnAuthedRouteWrapper>
				<ListComplaints />
			</UnAuthedRouteWrapper>
		),
	},
	{
		path: DASHBOARD_,
		element: (
			<AuthedRouteWrapper route={DASHBOARD_}>
				<Dashboard />
			</AuthedRouteWrapper>
		),
	},
	{
		path: ADMINS_,
		element: (
			<AuthedRouteWrapper route={ADMINS_}>
				<Admins />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${ADMINS_}/add`,
		element: (
			<AuthedRouteWrapper route={`${ADMINS_}/add`}>
				<AddAdmin />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${ADMINS_}/edit`,
		element: (
			<AuthedRouteWrapper route={`${ADMINS_}/edit`}>
				<EditAdmin />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${ADMINS_}/view`,
		element: (
			<AuthedRouteWrapper route={`${ADMINS_}/view`}>
				<ViewAdmin />
			</AuthedRouteWrapper>
		),
	},
	{
		path: SUPERVISORS_,
		element: (
			<AuthedRouteWrapper route={SUPERVISORS_}>
				<Supervisors />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${SUPERVISORS_}/add`,
		element: (
			<AuthedRouteWrapper route={`${SUPERVISORS_}/add`}>
				<AddSupervisors />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${SUPERVISORS_}/edit`,
		element: (
			<AuthedRouteWrapper route={`${SUPERVISORS_}/edit`}>
				<EditSupervisor />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${SUPERVISORS_}/view`,
		element: (
			<AuthedRouteWrapper route={`${SUPERVISORS_}/view`}>
				<ViewSupervisor />
			</AuthedRouteWrapper>
		),
	},
	{
		path: CLIENTS_,
		element: (
			<AuthedRouteWrapper route={CLIENTS_}>
				<Clients />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${CLIENTS_}/add`,
		element: (
			<AuthedRouteWrapper route={`${CLIENTS_}/add`}>
				<AddClient />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${CLIENTS_}/edit`,
		element: (
			<AuthedRouteWrapper route={`${CLIENTS_}/edit`}>
				<EditClient />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${CLIENTS_}/view`,
		element: (
			<AuthedRouteWrapper route={`${CLIENTS_}/view`}>
				<ViewClient />
			</AuthedRouteWrapper>
		),
	},
	{
		path: ISSUE_CATEGORIES_,
		element: (
			<AuthedRouteWrapper route={ISSUE_CATEGORIES_}>
				<IssueCategories />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${ISSUE_CATEGORIES_}/add`,
		element: (
			<AuthedRouteWrapper route={`${ISSUE_CATEGORIES_}/add`}>
				<AddIssueCategories />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${ISSUE_CATEGORIES_}/edit`,
		element: (
			<AuthedRouteWrapper route={`${ISSUE_CATEGORIES_}/edit`}>
				<EditIssueCategories />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${ISSUE_CATEGORIES_}/view`,
		element: (
			<AuthedRouteWrapper route={`${ISSUE_CATEGORIES_}/view`}>
				<ViewIssueCategories />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${CONTACTS_}`,
		element: (
			<AuthedRouteWrapper route={`${CONTACTS_}`}>
				<Contacts />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${CONTACTS_}/view`,
		element: (
			<AuthedRouteWrapper route={`${CONTACTS_}/view`}>
				<ViewContacts />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${COMPLAINTS_}`,
		element: (
			<AuthedRouteWrapper route={`${COMPLAINTS_}`}>
				<Complaints />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${COMPLAINTS_}/view`,
		element: (
			<AuthedRouteWrapper route={`${COMPLAINTS_}/view`}>
				<ViewComplaints />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${INVENTORY_}`,
		element: (
			<AuthedRouteWrapper route={`${INVENTORY_}`}>
				<Inventory />
			</AuthedRouteWrapper>
		),
	},
	{
		path: `${INVENTORY_}/view`,
		element: (
			<AuthedRouteWrapper route={`${INVENTORY_}/view`}>
				<ViewInventory />
			</AuthedRouteWrapper>
		),
	},
];

function App() {
	return (
		<BrowserRouter>
			<Routes>
				{routes.map((item, index) => (
					<Route key={index} index={item.index ? true : false} path={item.path} element={item.element} />
				))}
				<Route path="*" element={<Navigate to="/404" replace />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
