import { Box } from "@mui/system";
import { onAuthStateChanged } from "firebase/auth";
import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SquareLoader from "../components/authedComponents/SquareLoader";
import { primaryColor } from "../constants/colorConstants";
import { auth } from "../firebase/config";
import { DASHBOARD_ } from "../constants/textConstants";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { getInitialPage } from "../utils/helpers";

export default function UnAuthedRouteWrapper({ children }: { children: ReactNode }) {
	// Checking if user is logged in.
	const navigate = useNavigate();
	const { role } = useSelector((state: RootState) => state.user);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unSubscribe = onAuthStateChanged(auth, (user) => {
			setLoading(false);
			if (user !== null && user.isAnonymous === false && role) {
				navigate(getInitialPage(role));
			}
		});
		return unSubscribe;
	}, []);

	return (
		<>
			{loading ? (
				<Box className="center" sx={{ width: "100%", height: "100vh", display: "flex" }}>
					<SquareLoader color={primaryColor} />
				</Box>
			) : (
				children
			)}
		</>
	);
}
