import { Box, Button, Grid2, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
	CNIC_REGEX,
	EMAIL_REGEX,
	LOWERCASE_CHARACTER_REGEX,
	NUMBER_CHARACTER_REGEX,
	SPECIAL_CHARACTER_REGEX,
	UPPERCASE_CHARACTER_REGEX,
} from "../../../../constants/helperConstants";
import useAdmin from "../../../../hooks/useAdmin";
import { AdminDefaultPermissions, capitalizeFirstLetter, renderHeading } from "../../../../utils/helpers";
import { AddAdminPayloadInterface, EditAdminPayloadInterface, UserPermissionsType } from "../../../../utils/types";
import PasswordTextField from "../../../PasswordTextField";
import BackdropLoader from "../../BackdropLoader";
import BackgroundPaper from "../../BackgroundPaper";
import CnicComponent from "../../CnicComponent";
import PermissionSelector from "../../PermissionSelector";

interface AddAdminFieldsProps {
	type: "Add";
	apiCall: (payload: AddAdminPayloadInterface) => Promise<void>;
}
interface EditAdminFieldsProps {
	type: "Edit";
	apiCall: (payload: EditAdminPayloadInterface) => Promise<void>;
}
interface ViewAdminFieldsProps {
	type: "View";
	apiCall?: never;
}

type AdminFieldsProps = AddAdminFieldsProps | EditAdminFieldsProps | ViewAdminFieldsProps;

interface AdminFields {
	firstName: string;
	lastName: string;
	email: string;
	cnic: string;
	info: string;
	password: string;
}

export default function AdminFields({ type, apiCall }: AdminFieldsProps) {
	const [searchParams] = useSearchParams();
	const { admin, loading } = useAdmin(searchParams.get("id")); // Only runs in Edit and View case
	const [componentLoading, setComponentLoading] = useState(false);
	const [permissions, setPermissions] = useState<UserPermissionsType>(AdminDefaultPermissions);

	const { control, handleSubmit, reset } = useForm<AdminFields>({
		mode: "onTouched",
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			cnic: "",
			info: "",
			password: "",
		},
	});

	useEffect(() => {
		if (admin) {
			reset({
				firstName: admin.firstName,
				lastName: admin.lastName,
				email: admin.email,
				cnic: admin.cnic,
				info: admin.info ? admin.info : "",
				password: "",
			});
			// DISABLE PERMISSIONS
			setPermissions(admin.permissions);
		}
	}, [admin]);

	const onSubmit: SubmitHandler<AdminFields> = async (data) => {
		const id = searchParams.get("id");
		if (apiCall) {
			setComponentLoading(true);
			if (type === "Add") {
				const payload: AddAdminPayloadInterface = {
					firstName: capitalizeFirstLetter(data.firstName),
					lastName: capitalizeFirstLetter(data.lastName),
					email: data.email,
					cnic: data.cnic,
					role: "ADMIN",
					info: data.info ? data.info : null,
					permissions: permissions,
					password: data.password,
				};

				await apiCall(payload).finally(() => {
					setComponentLoading(false);
				});
			} else if (type === "Edit" && id) {
				const payload: EditAdminPayloadInterface = {
					id: id,
					firstName: capitalizeFirstLetter(data.firstName),
					lastName: capitalizeFirstLetter(data.lastName),
					email: data.email,
					cnic: data.cnic,
					role: "ADMIN",
					info: data.info ? data.info : null,
					permissions: permissions,
				};

				await apiCall(payload).finally(() => {
					setComponentLoading(false);
				});
			}
		}
	};

	return (
		<>
			<BackdropLoader open={loading || componentLoading} />
			<BackgroundPaper>
				<Box>
					<Typography fontSize={25} fontWeight={600}>
						{renderHeading(type, "Admin")}
					</Typography>
				</Box>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="firstName"
								control={control}
								rules={{
									required: "First Name is required",
								}}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										disabled={type === "View"}
										fullWidth
										required
										label="First Name"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="lastName"
								control={control}
								rules={{
									required: "Last Name is required",
								}}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										disabled={type === "View"}
										fullWidth
										required
										label="Last Name"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="cnic"
								control={control}
								rules={{
									required: "CNIC is required",
									pattern: {
										value: CNIC_REGEX,
										message: "Invalid CNIC",
									},
								}}
								render={({ field: { ref, ...rest }, fieldState: { error } }) => (
									<CnicComponent
										{...rest}
										disabled={type === "View"}
										fullWidth
										required
										label="CNIC"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
					</Grid2>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="email"
								control={control}
								rules={{
									required: "Email is required",
									pattern: {
										value: EMAIL_REGEX,
										message: "Incorrect Email",
									},
								}}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										disabled={type === "View" || type === "Edit"}
										fullWidth
										required
										label="Email"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
						{type === "Add" && (
							<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
								<Controller
									name="password"
									control={control}
									rules={{
										required: "Password is required",
										minLength: {
											value: 8,
											message: "Password must be at least 8 characters",
										},
										validate: {
											mustContainOneUppercaseCharacter: (value) => {
												if (UPPERCASE_CHARACTER_REGEX.test(value) === false) {
													return "Password must contain at least one uppercase character";
												}
											},
											mustContainOneLowercaseCharacter: (value) => {
												if (LOWERCASE_CHARACTER_REGEX.test(value) === false) {
													return "Password must contain at least one lowercase character";
												}
											},
											mustContainOneNumber: (value) => {
												if (NUMBER_CHARACTER_REGEX.test(value) === false) {
													return "Password must contain at least one number";
												}
											},
											mustContainOneSpecialCharacter: (value) => {
												if (SPECIAL_CHARACTER_REGEX.test(value) === false) {
													return "Password must contain at least one special character";
												}
											},
										},
									}}
									render={({ field: { ref, ...rest }, fieldState: { error } }) => (
										<PasswordTextField
											{...rest}
											fullWidth
											required
											error={error ? true : false}
											helperText={error ? error.message : ""}
										/>
									)}
								/>
							</Grid2>
						)}
					</Grid2>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="info"
								control={control}
								render={({ field: { ref, ...rest }, fieldState: { error } }) => (
									<TextField
										{...rest}
										disabled={type === "View"}
										multiline
										minRows={3}
										maxRows={5}
										fullWidth
										label="Enter any relevant information"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
					</Grid2>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<PermissionSelector
								selectedPermissions={permissions}
								setSelectedPermissions={setPermissions}
							/>
						</Grid2>
					</Grid2>
					{type !== "View" && (
						<Button
							variant="contained"
							type="submit"
							sx={{
								width: "10%",
								marginTop: "20px",
							}}
						>
							Save
						</Button>
					)}
				</form>
			</BackgroundPaper>
		</>
	);
}
