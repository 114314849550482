import { useNavigate } from "react-router-dom";
import { EditIssueCategoryPayloadInterface } from "../../../utils/types";
import { doc, setDoc } from "firebase/firestore";
import { firestore } from "../../../firebase/config";
import { ISSUE_CATEGORIES_COLLECTION } from "../../../constants/firebaseConstants";
import { enqueueSnackbar } from "notistack";
import { genericErrorFunction } from "../../../utils/helpers";
import IssueCategoryFields from "../../../components/authedComponents/crudComponents/IssueCategory/IssueCategoryFields";

export default function EditIssueCategories() {
	const navigate = useNavigate();

	const editIssueCategory = async (payload: EditIssueCategoryPayloadInterface) => {
		try {
			const { id, ...rest } = payload;
			await setDoc(doc(firestore, ISSUE_CATEGORIES_COLLECTION, id), rest)
				.then(() => {
					enqueueSnackbar("Issue Category Edited!", { variant: "success" });
				})
				.finally(() => {
					navigate(-1);
				});
		} catch (error) {
			genericErrorFunction({ logMessage: "Error occurred while editing issue category: ", error });
		}
	};

	return <IssueCategoryFields type="Edit" apiCall={editIssueCategory} />;
}
