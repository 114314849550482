import { deleteDoc, doc } from "firebase/firestore";
import { type MRT_ColumnDef as MRTColumnDef } from "material-react-table";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { CONTACT_COLLECTION } from "../../../../constants/firebaseConstants";
import { CONTACTS, CONTACTS_ } from "../../../../constants/textConstants";
import { firestore } from "../../../../firebase/config";
import useContacts from "../../../../hooks/useContacts";
import { genericErrorFunction, truncate } from "../../../../utils/helpers";
import { Contact } from "../../../../utils/types";
import ConfirmationModal from "../../ConfirmationModal";
import Table from "../../Table";
import usePermissions from "../../../../hooks/usePermissions";

export default function ContactTable() {
	const navigate = useNavigate();
	const { getPermission } = usePermissions();
	const { contacts, loading, refetch } = useContacts();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const deleteContact = async () => {
		if (selectedContact) {
			try {
				setDeleteLoading(true);
				await deleteDoc(doc(firestore, CONTACT_COLLECTION, selectedContact.id))
					.then(() => {
						enqueueSnackbar("Contact Deleted!", { variant: "success" });
					})
					.finally(() => {
						setDeleteLoading(false);
						setOpenDeleteModal(false);
						setSelectedContact(null);
						refetch(true);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while deleting contact: ", error });
			}
		}
	};

	const contactColumns: Array<MRTColumnDef<Contact>> = [
		{
			accessorKey: "name",
			header: "Name",
			size: 150,
		},
		{
			accessorKey: "email",
			header: "Email",
			size: 150,
		},
		{
			accessorKey: "subject",
			header: "Subject",
			Cell: (row) => <span>{truncate(row.row.original.subject, 100)}</span>,
			size: 150,
		},
	];

	return (
		<>
			<Table
				columns={contactColumns}
				data={contacts}
				title={CONTACTS}
				isLoading={loading}
				deleteButtonOnClick={
					getPermission(CONTACTS_, "contact_delete")
						? (row) => {
								setOpenDeleteModal(true);
								setSelectedContact(row.original);
						  }
						: undefined
				}
				onRowClick={
					getPermission(CONTACTS_, "contact_view")
						? (event, row) => {
								navigate({
									pathname: `/${CONTACTS_}/view`,
									search: createSearchParams({
										id: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
			/>
			<ConfirmationModal
				open={openDeleteModal}
				onClose={() => {
					setOpenDeleteModal(false);
					setSelectedContact(null);
				}}
				title="Delete Contact"
				description="Are you sure you want to delete the following contact. This action is irreversible."
				apiCall={deleteContact}
				loading={deleteLoading}
			/>
		</>
	);
}
