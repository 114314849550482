import { Box, Button } from "@mui/material";
import { type MRT_ColumnDef as MRTColumnDef } from "material-react-table";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { COMPLAINTS_, ON_HOLD_COMPLAINTS } from "../../../../constants/textConstants";
import useComplaints from "../../../../hooks/useComplaints";
import {
	convertTimestampToDate,
	getClientName,
	getIssueCategoryName,
	onTrackingStatusChange,
	truncate,
} from "../../../../utils/helpers";
import { Complaint } from "../../../../utils/types";
import BackdropLoader from "../../BackdropLoader";
import Table from "../../Table";
import ComplaintConfirmationModal from "./ComplaintConfirmationModal";
import usePermissions from "../../../../hooks/usePermissions";
import useClients from "../../../../hooks/useClients";
import useIssueCategories from "../../../../hooks/useIssueCategories";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export default function OnHoldComplaintTable() {
	const navigate = useNavigate();
	const { role } = useSelector((state: RootState) => state.user);
	const { getPermission } = usePermissions();
	const { complaints, loading, refetch } = useComplaints("onHold");
	const [pageLoading, setPageLoading] = useState(false);
	const [selectedComplaint, setSelectedComplaint] = useState<Complaint | null>(null);
	const [openOnHoldModal, setOpenOnHoldModal] = useState(false);
	const { clients, loading: clientsLoading } = useClients();
	const { issueCategories, loading: issueCategoryLoading } = useIssueCategories();

	const complaintColumnsForAdmin: Array<MRTColumnDef<Complaint>> = [
		{
			accessorKey: "subject",
			header: "Subject",
			size: 150,
		},
		{
			accessorKey: "description",
			header: "Description",
			Cell: (row) => <span>{truncate(row.row.original.description, 30)}</span>,
			size: 150,
		},
		{
			accessorKey: "clientId",
			header: "Client",
			Cell: (row) => <span>{getClientName(clients, row.row.original.clientId)}</span>,
			size: 150,
		},
		{
			accessorKey: "issueCategoryId",
			header: "Issue Category",
			Cell: (row) => <span>{getIssueCategoryName(issueCategories, row.row.original.issueCategoryId)}</span>,
			size: 150,
		},
		{
			accessorKey: "createdAt",
			header: "Creation Date",
			Cell: (row) => <span>{convertTimestampToDate(row.row.original.createdAt, true)}</span>,
			size: 150,
		},
	];

	const complaintColumnsForSupervisor: Array<MRTColumnDef<Complaint>> = [
		{
			accessorKey: "subject",
			header: "Subject",
			size: 150,
		},
		{
			accessorKey: "description",
			header: "Description",
			Cell: (row) => <span>{truncate(row.row.original.description, 30)}</span>,
			size: 150,
		},
		{
			accessorKey: "issueCategoryId",
			header: "Issue Category",
			Cell: (row) => <span>{getIssueCategoryName(issueCategories, row.row.original.issueCategoryId)}</span>,
			size: 150,
		},
		{
			accessorKey: "createdAt",
			header: "Creation Date",
			Cell: (row) => <span>{convertTimestampToDate(row.row.original.createdAt, true)}</span>,
			size: 150,
		},
	];

	return (
		<>
			<ComplaintConfirmationModal
				state="completed"
				open={openOnHoldModal}
				onClose={() => {
					setOpenOnHoldModal(false);
				}}
				complaint={selectedComplaint}
				refetch={() => {
					setOpenOnHoldModal(false);
					refetch(true);
				}}
				loading={pageLoading}
				setLoading={setPageLoading}
			/>
			<BackdropLoader open={pageLoading} />
			<Table
				columns={role === "ADMIN" ? complaintColumnsForAdmin : complaintColumnsForSupervisor}
				data={complaints}
				title={ON_HOLD_COMPLAINTS}
				isLoading={loading || clientsLoading || issueCategoryLoading}
				containerHeight="88vh"
				onRowClick={
					getPermission(COMPLAINTS_, "view_on_hold")
						? (event, row) => {
								navigate({
									pathname: `/${COMPLAINTS_}/view`,
									search: createSearchParams({
										userId: row.original.userId,
										complaintId: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
				customActionButtonOne={
					getPermission(COMPLAINTS_, "mark_as_completed")
						? (row) => {
								return (
									<Button
										variant="contained"
										onClick={async (e) => {
											e.stopPropagation();
											setSelectedComplaint(row.original);
											setOpenOnHoldModal(true);
										}}
									>
										Mark As Completed
									</Button>
								);
						  }
						: undefined
				}
				customActionButtonTwo={
					getPermission(COMPLAINTS_, "move_to_in_progress")
						? (row) => {
								return (
									<Button
										variant="contained"
										onClick={async (e) => {
											e.stopPropagation();
											await onTrackingStatusChange(
												row.original,
												"inProgress",
												setPageLoading,
												() => {
													refetch(true);
												},
											);
										}}
										sx={{
											marginTop: "5px",
										}}
									>
										Move To In Progress
									</Button>
								);
						  }
						: undefined
				}
			/>
		</>
	);
}
