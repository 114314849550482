import { Box, Button, Fab, keyframes, Popover, styled, Typography } from "@mui/material";
import { useState } from "react";
import {
	fabButtonColor1,
	fabButtonColor2,
	fabHoverButtonColor1,
	fabHoverButtonColor2,
	fabPopupBackgroundColor,
	fabPopupHeadingColor,
} from "../../../constants/colorConstants";
import { theme } from "../../../theme/theme";
import { useNavigate } from "react-router-dom";
import { COMPLAINT_ } from "../../../constants/textConstants";

// Keyframes for the slow-moving gradient animation
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

// Keyframes for popover animation
const popoverEnter = keyframes`
  0% { opacity: 0; transform: scale(0.9); }
  100% { opacity: 1; transform: scale(1); }
`;

const popoverExit = keyframes`
  0% { opacity: 1; transform: scale(1); }
  100% { opacity: 0; transform: scale(0.9); }
`;

const buttonBaseGradient = `linear-gradient(45deg, ${fabButtonColor1}, ${fabButtonColor2})`;
const buttonHoverGradient = `linear-gradient(45deg, ${fabHoverButtonColor1}, ${fabHoverButtonColor2})`;

const StyledFab = styled(Fab)({
	position: "sticky",
	top: "calc(100vh - 70px)", // Sticks near the bottom
	marginBottom: "20px",
	right: 20,
	marginLeft: "auto", // Pushes the button to the right
	display: "block",
	background: buttonBaseGradient,
	backgroundSize: "200% 200%",
	color: theme.palette.lightColor.main,
	fontWeight: "bold",
	animation: `${gradientAnimation} 10s ease infinite`,
	"&:hover": {
		background: buttonHoverGradient,
		backgroundSize: "200% 200%",
		animation: `${gradientAnimation} 5s ease infinite`,
		transform: "scale(1.1)",
		transition: "all 0.3s ease",
	},
});

const StyledButton = styled(Button)({
	padding: "0px 20px 0px 20px",
	height: "48px",
	borderRadius: "24px",
	background: buttonBaseGradient,
	backgroundSize: "200% 200%",
	color: theme.palette.lightColor.main,
	fontWeight: "bold",
	animation: `${gradientAnimation} 10s ease infinite`,
	"&:hover": {
		background: buttonHoverGradient,
		backgroundSize: "200% 200%",
		animation: `${gradientAnimation} 5s ease infinite`,
		transform: "scale(1.1)",
		transition: "all 0.3s ease",
	},
});

const StyledPopover = styled(Popover)(({ theme }) => ({
	".MuiPopover-paper": {
		backgroundColor: fabPopupBackgroundColor,
		padding: theme.spacing(4),
		width: "400px", // Larger popover size
		height: "500px",
		animation: `${popoverEnter} 0.3s ease-out`, // Animation on open
		"&.popover-exit": {
			animation: `${popoverExit} 0.3s ease-in`, // Animation on close
		},
	},
}));

export default function ComplaintFAB() {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	// Handle FAB click to open popover
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	// Handle popover close
	const handleClose = () => {
		setAnchorEl(null);
	};

	const navigateToAddComplaint = () => {
		navigate(`${COMPLAINT_}/add`);
	};

	const navigateToViewComplaint = () => {
		navigate(`${COMPLAINT_}/list`);
	};

	const open = Boolean(anchorEl);
	const id = open ? "complaint-popover" : undefined;

	return (
		<>
			{/* FAB component */}
			<StyledFab onClick={handleClick} aria-label="complaint" variant="extended" size="large">
				Have a complaint?
			</StyledFab>
			{/* Popover component */}
			<StyledPopover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: 510,
					horizontal: "right",
				}}
				disableScrollLock
			>
				{/* Popover content */}
				<Typography color={fabPopupHeadingColor} fontWeight="600" variant="h5" gutterBottom>
					We&apos;re Here to Help!
				</Typography>
				<Typography mt={2} color={theme.palette.lightColor.main} variant="body2">
					At Numla, we offer a streamlined complaint management system through our website. If you&apos;re in
					one of our client buildings, you can quickly lodge a complaint about any issue directly on our site.
				</Typography>
				<Typography mt={1} color={theme.palette.lightColor.main} variant="body2">
					Your complaint will be sent to a supervisor for resolution, and you&apos;ll receive live updates on
					its status throughout the process.
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						gap: 1,
						marginTop: "20px",
					}}
				>
					<Typography mt={2} color={fabPopupHeadingColor} fontWeight="600" variant="h6" gutterBottom>
						Get started right now!
					</Typography>
					<StyledButton onClick={navigateToAddComplaint}>Lodge a complaint</StyledButton>
					<Typography color={theme.palette.lightColor.main} variant="body1" gutterBottom>
						Or
					</Typography>
					<StyledButton onClick={navigateToViewComplaint}>Track your complaint</StyledButton>
				</Box>
			</StyledPopover>
		</>
	);
}
