import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid2, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import useClient from "../../../../hooks/useClient";
import { capitalizeFirstLetter, renderHeading } from "../../../../utils/helpers";
import { AddClientPayloadInterface, EditClientPayloadInterface, Project } from "../../../../utils/types";
import BackdropLoader from "../../BackdropLoader";
import BackgroundPaper from "../../BackgroundPaper";
import { v4 as uuid4 } from "uuid";
import useIsMobile from "../../../../hooks/useIsMobile";

interface AddClientFieldsProps {
	type: "Add";
	apiCall: (payload: AddClientPayloadInterface) => Promise<void>;
}
interface EditClientFieldsProps {
	type: "Edit";
	apiCall: (payload: EditClientPayloadInterface) => Promise<void>;
}
interface ViewClientFieldsProps {
	type: "View";
	apiCall?: never;
}

type AdminFieldsProps = AddClientFieldsProps | EditClientFieldsProps | ViewClientFieldsProps;

interface ClientFields {
	name: string;
	description: string;
	projects: Array<Omit<Project, "id">>;
}

export default function ClientFields({ type, apiCall }: AdminFieldsProps) {
	const [searchParams] = useSearchParams();
	const { client, loading } = useClient(searchParams.get("id")); // Only runs in Edit and View case
	const [componentLoading, setComponentLoading] = useState(false);
	const isMobile = useIsMobile();

	const { control, handleSubmit, reset } = useForm<ClientFields>({
		mode: "onTouched",
		defaultValues: {
			name: "",
			description: "",
			projects: [{ name: "", description: "" }],
		},
	});

	const { fields, append, remove } = useFieldArray({
		control: control,
		name: "projects", // unique name for your Field Array
	});

	const addProjectField = () => {
		append({
			name: "",
			description: "",
		});
	};

	const removeProjectField = (index: number) => {
		remove(index);
	};

	useEffect(() => {
		if (client) {
			reset({
				name: client.name,
				description: client.description,
				projects: client.projects,
			});
		}
	}, [client]);

	const onSubmit: SubmitHandler<ClientFields> = async (data) => {
		const id = searchParams.get("id");
		if (apiCall) {
			setComponentLoading(true);
			if (type === "Add") {
				const payload: AddClientPayloadInterface = {
					name: capitalizeFirstLetter(data.name),
					description: data.description,
					projects: data.projects.map((item) => {
						return {
							id: uuid4(),
							...item,
						};
					}),
				};

				await apiCall(payload).finally(() => {
					setComponentLoading(false);
				});
			} else if (type === "Edit" && id) {
				const payload: EditClientPayloadInterface = {
					id: id,
					name: capitalizeFirstLetter(data.name),
					description: data.description,
					projects: data.projects.map((item) => {
						return {
							id: uuid4(),
							...item,
						};
					}),
				};

				await apiCall(payload).finally(() => {
					setComponentLoading(false);
				});
			}
		}
	};

	return (
		<>
			<BackdropLoader open={loading || componentLoading} />
			<BackgroundPaper>
				<Box>
					<Typography fontSize={25} fontWeight={600}>
						{renderHeading(type, "Client")}
					</Typography>
				</Box>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="name"
								control={control}
								rules={{
									required: "Name is required",
								}}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										disabled={type === "View"}
										fullWidth
										required
										label="Name"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
					</Grid2>
					<Grid2 container spacing={2} mt={"10px"} mb={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="description"
								control={control}
								render={({ field: { ref, ...rest }, fieldState: { error } }) => (
									<TextField
										{...rest}
										disabled={type === "View"}
										multiline
										minRows={3}
										maxRows={5}
										fullWidth
										label="Enter any relevant information"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
					</Grid2>
					<Grid2 container spacing={2}>
						<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }} ml={1}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
								}}
								gap={1}
							>
								<Typography fontSize={18} fontWeight={600}>
									Projects
								</Typography>
								{type !== "View" && (
									<IconButton onClick={addProjectField} style={{ borderRadius: 15 }}>
										<FontAwesomeIcon icon={faPlus} />
									</IconButton>
								)}
							</Box>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
								mt={1}
							>
								{fields.map((project, index) => (
									<Box
										key={project.id}
										sx={{
											display: "flex",
											...(isMobile && { flexDirection: "column" }),
										}}
										gap={1}
										mt={2}
									>
										<Controller
											name={`projects.${index}.name`}
											control={control}
											rules={{
												required: "Project name is required",
											}}
											render={({ field, fieldState: { error } }) => (
												<TextField
													{...field}
													disabled={type === "View"}
													fullWidth
													required
													label="Project Name"
													error={error ? true : false}
													helperText={error ? error.message : ""}
												/>
											)}
										/>
										<Controller
											name={`projects.${index}.description`}
											control={control}
											render={({ field, fieldState: { error } }) => (
												<TextField
													{...field}
													disabled={type === "View"}
													fullWidth
													label="Enter project information"
													error={error ? true : false}
													helperText={error ? error.message : ""}
												/>
											)}
										/>
										{type !== "View" && (
											<IconButton
												onClick={() => {
													removeProjectField(index);
												}}
												style={{ borderRadius: 15 }}
											>
												<FontAwesomeIcon icon={faMinus} />
											</IconButton>
										)}
									</Box>
								))}
							</Box>
						</Grid2>
					</Grid2>
					{type !== "View" && (
						<Button
							variant="contained"
							type="submit"
							sx={{
								width: "10%",
								marginTop: "40px",
								marginBottom: "40px",
							}}
						>
							Save
						</Button>
					)}
				</form>
			</BackgroundPaper>
		</>
	);
}
