import { doc, setDoc } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import AdminFields from "../../../components/authedComponents/crudComponents/Admin/AdminFields";
import { USERS_COLLECTION } from "../../../constants/firebaseConstants";
import { firestore } from "../../../firebase/config";
import { genericErrorFunction } from "../../../utils/helpers";
import { EditAdminPayloadInterface } from "../../../utils/types";

export default function EditAdmin() {
	const navigate = useNavigate();

	const editAdmin = async (payload: EditAdminPayloadInterface) => {
		try {
			const { id, ...rest } = payload;
			await setDoc(doc(firestore, USERS_COLLECTION, id), rest)
				.then(() => {
					enqueueSnackbar("Admin Edited!", { variant: "success" });
				})
				.finally(() => {
					navigate(-1);
				});
		} catch (error) {
			genericErrorFunction({ logMessage: "Error occurred while editing admin: ", error });
		}
	};

	return <AdminFields type="Edit" apiCall={editAdmin} />;
}
