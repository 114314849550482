import { Box, Grid2, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import useComplaint from "../../../../hooks/useComplaint";
import { formatPhoneNumber } from "../../../../utils/helpers";
import BackdropLoader from "../../BackdropLoader";
import BackgroundPaper from "../../BackgroundPaper";
import ImageDisplay from "../../../ImageDisplay";
import useIssueCategory from "../../../../hooks/useIssueCategory";

export default function ComplaintView() {
	const [searchParams] = useSearchParams();
	const { complaint, loading } = useComplaint(
		searchParams.get("userId"),
		searchParams.get("complaintId"),
		Boolean(searchParams.get("isCompleted")),
	);
	const { issueCategory, loading: issueCategoryLoading } = useIssueCategory(
		complaint ? complaint.complaint.issueCategoryId : "",
	);

	return (
		<>
			<BackdropLoader open={loading || issueCategoryLoading} />
			<BackgroundPaper>
				<Box>
					<Typography variant="h3">Complainant Information:</Typography>
				</Box>
				{complaint && (
					<>
						<Grid2 container spacing={2} mt={"5px"} mb={"10px"}>
							<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Name:</Typography>
									<Typography variant="body1" ml={1}>
										{complaint.name}
									</Typography>
								</Box>
							</Grid2>
							<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Phone Number:</Typography>
									<Typography variant="body1" ml={1}>
										{formatPhoneNumber(complaint.phoneNumber, "national")}
									</Typography>
								</Box>
							</Grid2>
							{complaint.email && (
								<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
									<Box className="align-items-center">
										<Typography variant="h6">Email:</Typography>
										<Typography variant="body1" ml={1}>
											{complaint.email}
										</Typography>
									</Box>
								</Grid2>
							)}
						</Grid2>
						<Grid2 container spacing={2}>
							<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Company:</Typography>
									<Typography variant="body1" ml={1}>
										{complaint.company}
									</Typography>
								</Box>
							</Grid2>
							<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Department:</Typography>
									<Typography variant="body1" ml={1}>
										{complaint.department}
									</Typography>
								</Box>
							</Grid2>
						</Grid2>
					</>
				)}
				<Box mt={"50px"}>
					<Typography variant="h4">Complaint Information:</Typography>
				</Box>
				{complaint && issueCategory && (
					<>
						<Grid2 container spacing={2} mt={"5px"} mb={"10px"}>
							<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Subject:</Typography>
									<Typography className="wrap-text" variant="body1" ml={1}>
										{complaint.complaint.subject}
									</Typography>
								</Box>
							</Grid2>
						</Grid2>
						<Grid2 container spacing={2} mt={"5px"} mb={"10px"}>
							<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Description:</Typography>
									<Typography className="wrap-text" variant="body1" ml={1}>
										{complaint.complaint.description}
									</Typography>
								</Box>
							</Grid2>
						</Grid2>
						<Grid2 container spacing={2} mt={"5px"} mb={"10px"}>
							<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Issue Category:</Typography>
									<Typography variant="body1" ml={1}>
										{issueCategory.name}
									</Typography>
								</Box>
							</Grid2>
						</Grid2>
					</>
				)}
				{complaint && complaint.complaint.files.length ? (
					<Grid2 container spacing={2} mt={"5px"} mb={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
							<ImageDisplay
								images={complaint.complaint.files.map((url, index) => {
									return {
										name: `File #${index + 1}`,
										url: url,
									};
								})}
							/>
						</Grid2>
					</Grid2>
				) : (
					<></>
				)}
				{complaint && complaint.complaint.status === "onHold" && complaint.complaint.onHoldReason && (
					<>
						<Box mt={"50px"}>
							<Typography variant="h4">Complaint On Hold Reason:</Typography>
						</Box>
						<Grid2 container spacing={2} mt={"5px"} mb={"10px"}>
							<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
								<Box className="align-items-center">
									<Typography className="wrap-text" variant="body1" ml={1}>
										{complaint.complaint.onHoldReason}
									</Typography>
								</Box>
							</Grid2>
						</Grid2>
					</>
				)}
				{complaint && complaint.complaint.status === "completed" && complaint.complaint.completedInfo && (
					<>
						<Box mt={"50px"}>
							<Typography variant="h4">Complaint Completion Info:</Typography>
						</Box>
						<Grid2 container spacing={2} mt={"5px"} mb={"10px"}>
							<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
								<Box className="align-items-center">
									<Typography className="wrap-text" variant="body1" ml={1}>
										{complaint.complaint.completedInfo}
									</Typography>
								</Box>
							</Grid2>
						</Grid2>
					</>
				)}
			</BackgroundPaper>
		</>
	);
}
