import { deleteDoc, doc } from "firebase/firestore";
import { type MRT_ColumnDef as MRTColumnDef } from "material-react-table";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { CLIENTS_COLLECTION } from "../../../../constants/firebaseConstants";
import { CLIENTS, CLIENTS_ } from "../../../../constants/textConstants";
import { firestore } from "../../../../firebase/config";
import useClients from "../../../../hooks/useClients";
import { genericErrorFunction, truncate } from "../../../../utils/helpers";
import { Client } from "../../../../utils/types";
import ConfirmationModal from "../../ConfirmationModal";
import Table from "../../Table";
import usePermissions from "../../../../hooks/usePermissions";

export default function ClientTable() {
	const navigate = useNavigate();
	const { getPermission } = usePermissions();
	const { clients, loading, refetch } = useClients();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [selectedClient, setSelectedClient] = useState<Client | null>(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const deleteClient = async () => {
		if (selectedClient) {
			try {
				setDeleteLoading(true);
				await deleteDoc(doc(firestore, CLIENTS_COLLECTION, selectedClient.id))
					.then(() => {
						enqueueSnackbar("Client Deleted!", { variant: "success" });
					})
					.finally(() => {
						setDeleteLoading(false);
						setOpenDeleteModal(false);
						setSelectedClient(null);
						refetch(true);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while deleting client: ", error });
			}
		}
	};

	const clientColumns: Array<MRTColumnDef<Client>> = [
		{
			accessorKey: "name",
			header: "Name",
			size: 150,
		},
		{
			accessorKey: "description",
			header: "Description",
			size: 150,
		},
		{
			accessorKey: "projects",
			header: "Projects",
			Cell: (row) => <span>{truncate(row.row.original.projects.map((item) => item.name).join(", "), 30)}</span>,
			size: 150,
		},
	];

	return (
		<>
			<Table
				columns={clientColumns}
				data={clients}
				title={CLIENTS}
				isLoading={loading}
				addButtonOnClick={
					getPermission(CLIENTS_, "client_add")
						? () => {
								navigate(`/${CLIENTS_}/add`);
						  }
						: undefined
				}
				editButtonOnClick={
					getPermission(CLIENTS_, "client_edit")
						? (row) => {
								navigate({
									pathname: `/${CLIENTS_}/edit`,
									search: createSearchParams({
										id: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
				deleteButtonOnClick={
					getPermission(CLIENTS_, "client_delete")
						? (row) => {
								setOpenDeleteModal(true);
								setSelectedClient(row.original);
						  }
						: undefined
				}
				onRowClick={
					getPermission(CLIENTS_, "client_view")
						? (event, row) => {
								navigate({
									pathname: `/${CLIENTS_}/view`,
									search: createSearchParams({
										id: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
			/>
			<ConfirmationModal
				open={openDeleteModal}
				onClose={() => {
					setOpenDeleteModal(false);
					setSelectedClient(null);
				}}
				title="Delete Client"
				description="Are you sure you want to delete the following client. This action is irreversible."
				apiCall={deleteClient}
				loading={deleteLoading}
			/>
		</>
	);
}
