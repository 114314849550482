import { Box, Grid2, Link, TextField, Typography } from "@mui/material";
import styledBase from "styled-components";
// Assets
import { styled } from "@mui/system";
import { FirebaseError } from "firebase/app";
import { signInAnonymously } from "firebase/auth";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { secondaryColor } from "../../../constants/colorConstants";
import { CONTACT_COLLECTION } from "../../../constants/firebaseConstants";
import { EMAIL_REGEX } from "../../../constants/helperConstants";
import { auth, firestore } from "../../../firebase/config";
import { theme } from "../../../theme/theme";
import { capitalizeFirstLetter, genericErrorFunction } from "../../../utils/helpers";
import { Contact as ContactType } from "../../../utils/types";
import Pkfg from "../../../assets/svg/Flags/pk.svg";
import Cafg from "../../../assets/svg/Flags/ca.svg";
import Ukfg from "../../../assets/svg/Flags/gb.svg";
import Iefg from "../../../assets/svg/Flags/ie.svg";
import Safg from "../../../assets/svg/Flags/sa.svg";
import Usfg from "../../../assets/svg/Flags/us.svg";

interface ContactFields {
	name: string;
	email: string;
	subject: string;
	message: string;
}

export default function Contact() {
	const { control, handleSubmit, reset } = useForm<ContactFields>({
		mode: "onTouched",
		defaultValues: {
			name: "",
			email: "",
			subject: "",
			message: "",
		},
	});

	const addMessage = async (payload: Omit<ContactType, "id">, userId: string) => {
		await setDoc(doc(firestore, CONTACT_COLLECTION, userId), {
			...payload,
			timestamp: serverTimestamp(),
		})
			.then(() => {
				enqueueSnackbar("Your message has been received!", { variant: "success" });
			})
			.finally(() => {
				reset();
			});
	};

	const onSubmit: SubmitHandler<ContactFields> = async (data) => {
		const payload = {
			name: capitalizeFirstLetter(data.name),
			email: data.email,
			subject: data.subject,
			message: data.message,
		};

		try {
			// If an anon user exists we use that and send the data
			if (auth.currentUser && auth.currentUser.isAnonymous) {
				await addMessage(payload, auth.currentUser.uid);
			}
			// else if anon user does not exist we create one and send data
			else if (auth.currentUser === null) {
				await signInAnonymously(auth).then(async (user) => {
					await addMessage(payload, user.user.uid);
				});
			}
		} catch (error) {
			if (error instanceof FirebaseError && error.code === "permission-denied") {
				enqueueSnackbar("A previous contact attempt has already been made! Please wait while we contact you!", {
					variant: "error",
				});
			} else {
				genericErrorFunction({
					logMessage: "Error occurred while adding contact message: ",
					error,
					snackbarMessage: "Something went wrong. Please try contacting us later!",
				});
			}
		}
	};

	return (
		<Wrapper id="contact">
			<div className="lightBg">
				<div className="container">
					<ContactUsContainer>
						<Box>
							<HeaderInfo>
								<h1 className="font40 extraBold">Let&apos;s get in touch</h1>
								<p className="font15">
									Connect with us for personalized service and expert support.
									<br />
								</p>
							</HeaderInfo>
							<form onSubmit={handleSubmit(onSubmit)} noValidate>
								<Form>
									<Controller
										name="name"
										control={control}
										rules={{
											required: "Name is required",
										}}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												variant="standard"
												fullWidth
												required
												label="Name"
												error={error ? true : false}
												helperText={error ? error.message : ""}
											/>
										)}
									/>
									<Controller
										name="email"
										control={control}
										rules={{
											required: "Email is required",
											pattern: {
												value: EMAIL_REGEX,
												message: "Incorrect Email",
											},
										}}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												variant="standard"
												sx={{
													marginTop: "15px",
												}}
												fullWidth
												required
												label="Email"
												error={error ? true : false}
												helperText={error ? error.message : ""}
											/>
										)}
									/>
									<Controller
										name="subject"
										control={control}
										rules={{
											required: "Subject is required",
										}}
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												variant="standard"
												sx={{
													marginTop: "15px",
												}}
												fullWidth
												required
												label="Subject"
												error={error ? true : false}
												helperText={error ? error.message : ""}
											/>
										)}
									/>
									<Controller
										name="message"
										control={control}
										render={({ field: { ref, ...rest }, fieldState: { error } }) => (
											<TextField
												{...rest}
												variant="standard"
												sx={{
													marginTop: "15px",
												}}
												multiline
												minRows={5}
												maxRows={7}
												fullWidth
												label="Message"
												error={error ? true : false}
												helperText={error ? error.message : ""}
											/>
										)}
									/>
								</Form>
								<SubmitWrapper className="flex">
									<ButtonInput
										type="submit"
										value="Send Message"
										className="pointer animate radius8"
										style={{ maxWidth: "220px" }}
									/>
								</SubmitWrapper>
							</form>
						</Box>
						<MainAddressContainer container spacing={2}>
							<SingleAddressContainer size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
								<AddressBox>
									<AddressTitleBox mb={2}>
										<CountryImage src={Pkfg} />
										<Typography variant="h5" fontWeight={600}>
											Pakistan
										</Typography>
									</AddressTitleBox>
									<Typography variant="body1" fontWeight={500} mb={1}>
										Fo-4, Mateen Shopping Galaxy, Block 10A, Gulshan e Iqbal, Rashid Minhas Road,
										Karachi.
									</Typography>
									<Link variant="body1" href="tel:+923453351144">
										+92 345 3351144
									</Link>
								</AddressBox>
							</SingleAddressContainer>
							<SingleAddressContainer size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
								<AddressBox>
									<AddressTitleBox mb={2}>
										<CountryImage src={Iefg} />
										<Typography variant="h5" fontWeight={600}>
											Ireland
										</Typography>
									</AddressTitleBox>
									<Typography variant="body1" fontWeight={500} mb={1}>
										East End House, Poulavone, Ballincollig, P31 Y309, Co Cork, Ireland
									</Typography>
									<Link variant="body1" href="tel:+35312549292">
										+353 1 254 9292
									</Link>
								</AddressBox>
							</SingleAddressContainer>
							<SingleAddressContainer size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
								<AddressBox>
									<AddressTitleBox mb={2}>
										<CountryImage src={Usfg} />
										<Typography variant="h5" fontWeight={600}>
											San Francisco
										</Typography>
									</AddressTitleBox>
									<Typography variant="body1" fontWeight={500} mb={1}>
										649 Mission Street, 5th Fl. San Francisco, CA94105, USA
									</Typography>
									<Link variant="body1" href="tel:+19253925392">
										+1(925) 392 5392
									</Link>
								</AddressBox>
							</SingleAddressContainer>
							<SingleAddressContainer size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
								<AddressBox>
									<AddressTitleBox mb={2}>
										<CountryImage src={Cafg} />
										<Typography variant="h5" fontWeight={600}>
											Toronto ON
										</Typography>
									</AddressTitleBox>
									<Typography variant="body1" fontWeight={500} mb={1}>
										Suite 2201, Eaton Centre 250 Yonge Street, Toronto, M5B 2L7, Canada
									</Typography>
									<Link variant="body1" href="tel:+16472475013">
										+1(647) 247 5013
									</Link>
								</AddressBox>
							</SingleAddressContainer>
							<SingleAddressContainer size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
								<AddressBox>
									<AddressTitleBox mb={2}>
										<CountryImage src={Ukfg} />
										<Typography variant="h5" fontWeight={600}>
											UK
										</Typography>
									</AddressTitleBox>
									<Typography variant="body1" fontWeight={500} mb={1}>
										20-22 Wenlock Road, London, N1 7GU, U.K.
									</Typography>
									<Link variant="body1" href="tel:+442032893800">
										+44 20 3289 3800
									</Link>
								</AddressBox>
							</SingleAddressContainer>
							<SingleAddressContainer size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
								<AddressBox>
									<AddressTitleBox mb={2}>
										<CountryImage src={Safg} />
										<Typography variant="h5" fontWeight={600}>
											KSA
										</Typography>
									</AddressTitleBox>
									<Typography variant="body1" fontWeight={500} mb={1}>
										Behind Stars Avenue, Al Khatib Road, Villa A3, Zahra District, Jeddah 23424, KSA
									</Typography>
									<Link variant="body1" href="tel:+966580411817">
										+966(58) 041 1817
									</Link>
								</AddressBox>
							</SingleAddressContainer>
						</MainAddressContainer>
					</ContactUsContainer>
				</div>
			</div>
		</Wrapper>
	);
}

const Wrapper = styledBase.section`
	width: 100%;
`;
const HeaderInfo = styledBase.div`
	padding: 70px 0 30px 0;
	@media (max-width: 860px) {
		text-align: center;
	}
`;
const Form = styledBase.div`
	padding: 30px 0 30px 0;
	@media (max-width: 860px) {
		padding: 30px 0;
	}
`;
const ButtonInput = styledBase.input`
	border: 1px solid ${theme.palette.primary.main};
	background-color: ${theme.palette.primary.main};
	width: 100%;
	padding: 15px;
	outline: none;
	color: #fff;
	:hover {
		background-color: ${theme.palette.primary.dark};
		border: 1px solid ${theme.palette.primary.main};
		color: #fff;
	}
	@media (max-width: 991px) {
		margin: 0 auto;
	}
`;
const SubmitWrapper = styledBase.div`
	@media (max-width: 991px) {
		width: 100%;
		margin-bottom: 50px;
	}
`;
const CountryImage = styledBase.img`
	width: 30px;
	height: 30px;
	border-radius: 25px
`;

const ContactUsContainer = styled(Box)(({ theme }) => ({
	paddingBottom: "30px",
	display: "flex",
	justifyContent: "space-between",
	gap: "30px",
	flexDirection: "row",
	[theme.breakpoints.down("sm")]: {
		flexDirection: "column",
	},
}));

const MainAddressContainer = styled(Grid2)(({ theme }) => ({
	marginLeft: 0,
	padding: "70px 30px 50px 0",
	[theme.breakpoints.down("sm")]: {
		padding: "0 30px 50px 0",
	},
}));

const SingleAddressContainer = styled(Grid2)(({ theme }) => ({}));

const AddressBox = styled(Box)(({ theme }) => ({
	width: "100%",
	height: "100%",
	backgroundColor: "white",
	borderRadius: "20px",
	boxShadow: `3px 3px 10px ${secondaryColor}`,
	padding: "10px 10px 10px 10px",
}));

const AddressTitleBox = styled(Box)(({ theme }) => ({
	display: "flex",
	gap: "10px",
	alignItems: "center",
}));
