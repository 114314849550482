import { parsePhoneNumber } from "libphonenumber-js/max";
import { enqueueSnackbar } from "notistack";
import {
	AllPermissionsType,
	Client,
	Complaint,
	ComplaintDoc,
	InventoryItem,
	InventoryLog,
	IssueCategory,
	UserPermissionsType,
} from "./types";
import {
	addDoc,
	arrayUnion,
	collection,
	doc,
	DocumentData,
	getDoc,
	getDocs,
	limit,
	orderBy,
	Query,
	query,
	setDoc,
	Timestamp,
	where,
} from "firebase/firestore";
import { firestore } from "../firebase/config";
import {
	COMPLAINTS_ARCHIVED_COLLECTION,
	COMPLAINTS_COLLECTION,
	COMPLAINTS_TRACKING_COLLECTION,
	INVENTORY_LOGS_COLLECTION,
} from "../constants/firebaseConstants";
import {
	ADMINS,
	ADMINS_,
	CLIENTS,
	CLIENTS_,
	COMPLAINTS,
	COMPLAINTS_,
	CONTACTS,
	CONTACTS_,
	DASHBOARD,
	DASHBOARD_,
	INVENTORY,
	INVENTORY_,
	ISSUE_CATEGORIES,
	ISSUE_CATEGORIES_,
	SUPERVISORS,
	SUPERVISORS_,
} from "../constants/textConstants";
import dayjs, { Dayjs } from "dayjs";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const special = [
	"zeroth",
	"first",
	"second",
	"third",
	"fourth",
	"fifth",
	"sixth",
	"seventh",
	"eighth",
	"ninth",
	"tenth",
	"eleventh",
	"twelfth",
	"thirteenth",
	"fourteenth",
	"fifteenth",
	"sixteenth",
	"seventeenth",
	"eighteenth",
	"nineteenth",
];
const deca = ["twent", "thirt", "fort", "fift", "sixt", "sevent", "eight", "ninet"];

export const allFeaturePermissions: AllPermissionsType = [
	{
		id: DASHBOARD_,
		sectionName: DASHBOARD,
		permissions: [
			{
				id: "dashboard_list",
				featureName: "List",
			},
		],
	},
	{
		id: ADMINS_,
		sectionName: ADMINS,
		permissions: [
			{
				id: "admin_list",
				featureName: "List",
			},
			{
				id: "admin_view",
				featureName: "View",
			},
			{
				id: "admin_add",
				featureName: "Add",
			},
			{
				id: "admin_edit",
				featureName: "Edit",
			},
			{
				id: "admin_delete",
				featureName: "Delete",
			},
		],
	},
	{
		id: SUPERVISORS_,
		sectionName: SUPERVISORS,
		permissions: [
			{
				id: "supervisor_list",
				featureName: "List",
			},
			{
				id: "supervisor_view",
				featureName: "View",
			},
			{
				id: "supervisor_add",
				featureName: "Add",
			},
			{
				id: "supervisor_edit",
				featureName: "Edit",
			},
			{
				id: "supervisor_delete",
				featureName: "Delete",
			},
		],
	},
	{
		id: CLIENTS_,
		sectionName: CLIENTS,
		permissions: [
			{
				id: "client_list",
				featureName: "List",
			},
			{
				id: "client_view",
				featureName: "View",
			},
			{
				id: "client_add",
				featureName: "Add",
			},
			{
				id: "client_edit",
				featureName: "Edit",
			},
			{
				id: "client_delete",
				featureName: "Delete",
			},
		],
	},
	{
		id: ISSUE_CATEGORIES_,
		sectionName: ISSUE_CATEGORIES,
		permissions: [
			{
				id: "issue_category_list",
				featureName: "List",
			},
			{
				id: "issue_category_view",
				featureName: "View",
			},
			{
				id: "issue_category_add",
				featureName: "Add",
			},
			{
				id: "issue_category_edit",
				featureName: "Edit",
			},
			{
				id: "issue_category_delete",
				featureName: "Delete",
			},
		],
	},
	{
		id: CONTACTS_,
		sectionName: CONTACTS,
		permissions: [
			{
				id: "contact_list",
				featureName: "List",
			},
			{
				id: "contact_view",
				featureName: "View",
			},
			{
				id: "contact_delete",
				featureName: "Delete",
			},
		],
	},
	{
		id: COMPLAINTS_,
		sectionName: COMPLAINTS,
		permissions: [
			{
				id: "complaint_list",
				featureName: "List Complaints",
			},
			{
				id: "complaint_view",
				featureName: "View Complaints",
			},
			{
				id: "list_incoming",
				featureName: "List Incoming",
			},
			{
				id: "list_accepted",
				featureName: "List Accepted",
			},
			{
				id: "list_inprogress",
				featureName: "List In Progress",
			},
			{
				id: "list_on_hold",
				featureName: "List On Hold",
			},
			{
				id: "list_completed",
				featureName: "List Completed",
			},
			{
				id: "list_rejected",
				featureName: "List Rejected",
			},
			{
				id: "view_incoming",
				featureName: "View Incoming",
			},
			{
				id: "view_accepted",
				featureName: "View Accepted",
			},
			{
				id: "view_inprogress",
				featureName: "View In Progress",
			},
			{
				id: "view_on_hold",
				featureName: "View On Hold",
			},
			{
				id: "view_completed",
				featureName: "View Completed",
			},
			{
				id: "view_rejected",
				featureName: "View Rejected",
			},
			{
				id: "accept",
				featureName: "Accept Complaint",
			},
			{
				id: "reject",
				featureName: "Reject Complaint",
			},
			{
				id: "move_to_in_progress",
				featureName: "Move Complaint To In Progress",
			},
			{
				id: "move_to_on_hold",
				featureName: "Move Complaint To On Hold",
			},
			{
				id: "mark_as_completed",
				featureName: "Mark Complaint As Completed",
			},
		],
	},
	{
		id: INVENTORY_,
		sectionName: INVENTORY,
		permissions: [
			{
				id: "inventory_list",
				featureName: "List",
			},
			{
				id: "inventory_view",
				featureName: "View",
			},
			{
				id: "inventory_add",
				featureName: "Add",
			},
			{
				id: "inventory_edit",
				featureName: "Edit",
			},
			{
				id: "inventory_delete",
				featureName: "Delete",
			},
			{
				id: "inventory_increment",
				featureName: "Inventory Increment",
			},
			{
				id: "inventory_decrement",
				featureName: "Inventory Issue",
			},
			{
				id: "inventory_export",
				featureName: "Export",
			},
		],
	},
];

export const AdminDefaultPermissions: UserPermissionsType = [
	{
		id: DASHBOARD_,
		sectionName: DASHBOARD,
		permissions: [
			{
				id: "dashboard_list",
				featureName: "List",
				isAllowed: true,
			},
		],
	},
	{
		id: ADMINS_,
		sectionName: ADMINS,
		permissions: [
			{
				id: "admin_list",
				featureName: "List",
				isAllowed: true,
			},
			{
				id: "admin_view",
				featureName: "View",
				isAllowed: true,
			},
			{
				id: "admin_add",
				featureName: "Add",
				isAllowed: true,
			},
			{
				id: "admin_edit",
				featureName: "Edit",
				isAllowed: true,
			},
			{
				id: "admin_delete",
				featureName: "Delete",
				isAllowed: true,
			},
		],
	},
	{
		id: SUPERVISORS_,
		sectionName: SUPERVISORS,
		permissions: [
			{
				id: "supervisor_list",
				featureName: "List",
				isAllowed: true,
			},
			{
				id: "supervisor_view",
				featureName: "View",
				isAllowed: true,
			},
			{
				id: "supervisor_add",
				featureName: "Add",
				isAllowed: true,
			},
			{
				id: "supervisor_edit",
				featureName: "Edit",
				isAllowed: true,
			},
			{
				id: "supervisor_delete",
				featureName: "Delete",
				isAllowed: true,
			},
		],
	},
	{
		id: CLIENTS_,
		sectionName: CLIENTS,
		permissions: [
			{
				id: "client_list",
				featureName: "List",
				isAllowed: true,
			},
			{
				id: "client_view",
				featureName: "View",
				isAllowed: true,
			},
			{
				id: "client_add",
				featureName: "Add",
				isAllowed: true,
			},
			{
				id: "client_edit",
				featureName: "Edit",
				isAllowed: true,
			},
			{
				id: "client_delete",
				featureName: "Delete",
				isAllowed: true,
			},
		],
	},
	{
		id: ISSUE_CATEGORIES_,
		sectionName: ISSUE_CATEGORIES,
		permissions: [
			{
				id: "issue_category_list",
				featureName: "List",
				isAllowed: true,
			},
			{
				id: "issue_category_view",
				featureName: "View",
				isAllowed: true,
			},
			{
				id: "issue_category_add",
				featureName: "Add",
				isAllowed: true,
			},
			{
				id: "issue_category_edit",
				featureName: "Edit",
				isAllowed: true,
			},
			{
				id: "issue_category_delete",
				featureName: "Delete",
				isAllowed: true,
			},
		],
	},
	{
		id: CONTACTS_,
		sectionName: CONTACTS,
		permissions: [
			{
				id: "contact_list",
				featureName: "List",
				isAllowed: true,
			},
			{
				id: "contact_view",
				featureName: "View",
				isAllowed: true,
			},
			{
				id: "contact_delete",
				featureName: "Delete",
				isAllowed: true,
			},
		],
	},
	{
		id: COMPLAINTS_,
		sectionName: COMPLAINTS,
		permissions: [
			{
				id: "complaint_list",
				featureName: "List Complaints",
				isAllowed: true,
			},
			{
				id: "complaint_view",
				featureName: "View Complaints",
				isAllowed: true,
			},
			{
				id: "list_incoming",
				featureName: "List Incoming",
				isAllowed: true,
			},
			{
				id: "list_accepted",
				featureName: "List Accepted",
				isAllowed: true,
			},
			{
				id: "list_inprogress",
				featureName: "List In Progress",
				isAllowed: true,
			},
			{
				id: "list_on_hold",
				featureName: "List On Hold",
				isAllowed: true,
			},
			{
				id: "list_completed",
				featureName: "List Completed",
				isAllowed: true,
			},
			{
				id: "list_rejected",
				featureName: "List Rejected",
				isAllowed: true,
			},
			{
				id: "view_incoming",
				featureName: "View Incoming",
				isAllowed: true,
			},
			{
				id: "view_accepted",
				featureName: "View Accepted",
				isAllowed: true,
			},
			{
				id: "view_inprogress",
				featureName: "View In Progress",
				isAllowed: true,
			},
			{
				id: "view_on_hold",
				featureName: "View On Hold",
				isAllowed: true,
			},
			{
				id: "view_completed",
				featureName: "View Completed",
				isAllowed: true,
			},
			{
				id: "view_rejected",
				featureName: "View Rejected",
				isAllowed: true,
			},
			{
				id: "accept",
				featureName: "Accept Complaint",
				isAllowed: true,
			},
			{
				id: "reject",
				featureName: "Reject Complaint",
				isAllowed: true,
			},
			{
				id: "move_to_in_progress",
				featureName: "Move Complaint To In Progress",
				isAllowed: true,
			},
			{
				id: "move_to_on_hold",
				featureName: "Move Complaint To On Hold",
				isAllowed: true,
			},
			{
				id: "mark_as_completed",
				featureName: "Mark Complaint As Completed",
				isAllowed: true,
			},
		],
	},
	{
		id: INVENTORY_,
		sectionName: INVENTORY,
		permissions: [
			{
				id: "inventory_list",
				featureName: "List",
				isAllowed: true,
			},
			{
				id: "inventory_view",
				featureName: "View",
				isAllowed: true,
			},
			{
				id: "inventory_add",
				featureName: "Add",
				isAllowed: true,
			},
			{
				id: "inventory_edit",
				featureName: "Edit",
				isAllowed: true,
			},
			{
				id: "inventory_delete",
				featureName: "Delete",
				isAllowed: true,
			},
			{
				id: "inventory_increment",
				featureName: "Inventory Increment",
				isAllowed: true,
			},
			{
				id: "inventory_decrement",
				featureName: "Inventory Issue",
				isAllowed: true,
			},
			{
				id: "inventory_export",
				featureName: "Export",
				isAllowed: true,
			},
		],
	},
];

export const SupervisorDefaultPermissions: UserPermissionsType = [
	{
		id: DASHBOARD_,
		sectionName: DASHBOARD,
		permissions: [
			{
				id: "dashboard_list",
				featureName: "List",
				isAllowed: true,
			},
		],
	},
	{
		id: ADMINS_,
		sectionName: ADMINS,
		permissions: [
			{
				id: "admin_list",
				featureName: "List",
				isAllowed: false,
			},
			{
				id: "admin_view",
				featureName: "View",
				isAllowed: false,
			},
			{
				id: "admin_add",
				featureName: "Add",
				isAllowed: false,
			},
			{
				id: "admin_edit",
				featureName: "Edit",
				isAllowed: false,
			},
			{
				id: "admin_delete",
				featureName: "Delete",
				isAllowed: false,
			},
		],
	},
	{
		id: SUPERVISORS_,
		sectionName: SUPERVISORS,
		permissions: [
			{
				id: "supervisor_list",
				featureName: "List",
				isAllowed: false,
			},
			{
				id: "supervisor_view",
				featureName: "View",
				isAllowed: false,
			},
			{
				id: "supervisor_add",
				featureName: "Add",
				isAllowed: false,
			},
			{
				id: "supervisor_edit",
				featureName: "Edit",
				isAllowed: false,
			},
			{
				id: "supervisor_delete",
				featureName: "Delete",
				isAllowed: false,
			},
		],
	},
	{
		id: CLIENTS_,
		sectionName: CLIENTS,
		permissions: [
			{
				id: "client_list",
				featureName: "List",
				isAllowed: false,
			},
			{
				id: "client_view",
				featureName: "View",
				isAllowed: false,
			},
			{
				id: "client_add",
				featureName: "Add",
				isAllowed: false,
			},
			{
				id: "client_edit",
				featureName: "Edit",
				isAllowed: false,
			},
			{
				id: "client_delete",
				featureName: "Delete",
				isAllowed: false,
			},
		],
	},
	{
		id: ISSUE_CATEGORIES_,
		sectionName: ISSUE_CATEGORIES,
		permissions: [
			{
				id: "issue_category_list",
				featureName: "List",
				isAllowed: false,
			},
			{
				id: "issue_category_view",
				featureName: "View",
				isAllowed: false,
			},
			{
				id: "issue_category_add",
				featureName: "Add",
				isAllowed: false,
			},
			{
				id: "issue_category_edit",
				featureName: "Edit",
				isAllowed: false,
			},
			{
				id: "issue_category_delete",
				featureName: "Delete",
				isAllowed: false,
			},
		],
	},
	{
		id: CONTACTS_,
		sectionName: CONTACTS,
		permissions: [
			{
				id: "contact_list",
				featureName: "List",
				isAllowed: false,
			},
			{
				id: "contact_view",
				featureName: "View",
				isAllowed: false,
			},
			{
				id: "contact_delete",
				featureName: "Delete",
				isAllowed: false,
			},
		],
	},
	{
		id: COMPLAINTS_,
		sectionName: COMPLAINTS,
		permissions: [
			{
				id: "complaint_list",
				featureName: "List Complaints",
				isAllowed: true,
			},
			{
				id: "complaint_view",
				featureName: "View Complaints",
				isAllowed: true,
			},
			{
				id: "list_incoming",
				featureName: "List Incoming",
				isAllowed: true,
			},
			{
				id: "list_accepted",
				featureName: "List Accepted",
				isAllowed: true,
			},
			{
				id: "list_inprogress",
				featureName: "List In Progress",
				isAllowed: true,
			},
			{
				id: "list_on_hold",
				featureName: "List On Hold",
				isAllowed: true,
			},
			{
				id: "list_completed",
				featureName: "List Completed",
				isAllowed: true,
			},
			{
				id: "list_rejected",
				featureName: "List Rejected",
				isAllowed: true,
			},
			{
				id: "view_incoming",
				featureName: "View Incoming",
				isAllowed: true,
			},
			{
				id: "view_accepted",
				featureName: "View Accepted",
				isAllowed: true,
			},
			{
				id: "view_inprogress",
				featureName: "View In Progress",
				isAllowed: true,
			},
			{
				id: "view_on_hold",
				featureName: "View On Hold",
				isAllowed: true,
			},
			{
				id: "view_completed",
				featureName: "View Completed",
				isAllowed: true,
			},
			{
				id: "view_rejected",
				featureName: "View Rejected",
				isAllowed: true,
			},
			{
				id: "accept",
				featureName: "Accept Complaint",
				isAllowed: true,
			},
			{
				id: "reject",
				featureName: "Reject Complaint",
				isAllowed: true,
			},
			{
				id: "move_to_in_progress",
				featureName: "Move Complaint To In Progress",
				isAllowed: true,
			},
			{
				id: "move_to_on_hold",
				featureName: "Move Complaint To On Hold",
				isAllowed: true,
			},
			{
				id: "mark_as_completed",
				featureName: "Mark Complaint As Completed",
				isAllowed: true,
			},
		],
	},
	{
		id: INVENTORY_,
		sectionName: INVENTORY,
		permissions: [
			{
				id: "inventory_list",
				featureName: "List",
				isAllowed: false,
			},
			{
				id: "inventory_view",
				featureName: "View",
				isAllowed: false,
			},
			{
				id: "inventory_add",
				featureName: "Add",
				isAllowed: false,
			},
			{
				id: "inventory_edit",
				featureName: "Edit",
				isAllowed: false,
			},
			{
				id: "inventory_delete",
				featureName: "Delete",
				isAllowed: false,
			},
			{
				id: "inventory_increment",
				featureName: "Inventory Increment",
				isAllowed: false,
			},
			{
				id: "inventory_decrement",
				featureName: "Inventory Issue",
				isAllowed: false,
			},
			{
				id: "inventory_export",
				featureName: "Export",
				isAllowed: false,
			},
		],
	},
];

export const genericErrorFunction = ({
	logMessage,
	error,
	snackbarMessage = "Something went wrong.",
}: {
	logMessage: string;
	error: any;
	snackbarMessage?: string;
}) => {
	enqueueSnackbar(snackbarMessage, { variant: "error" });
	console.error(logMessage, error);
};

/**
 *
 * @param date - A date string in format like "YYYY-MM-DD HH:MM:SS"
 * @returns A date string in format like
 */
export const formatDate = (date: string) => {
	const newDate = new Date(date).toLocaleDateString("en-US");
	return newDate;
};

/**
 *
 * @param number - A number from 0 to 99
 * @returns A string of the number e.g. "Zeroth", "First" ... "Ninety-Ninth"
 */
export const stringifyNumber = (number: number) => {
	if (number < 20) return special[number];
	if (number % 10 === 0) return deca[Math.floor(number / 10) - 2] + "ieth";
	return deca[Math.floor(number / 10) - 2] + "y-" + special[number % 10];
};

/**
 *
 * @param string - A string seperating text with spaces
 * @returns A String with the first and last initials
 */
export const getInitials = (string: string) => {
	const names = string.split(" ");
	let initials = names[0].substring(0, 1).toUpperCase();

	if (names.length > 1) {
		initials += names[names.length - 1].substring(0, 1).toUpperCase();
	}
	return initials;
};

/**
 *
 * @param string - String that needs to be capitalized
 * @returns Capitalized string
 */
export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 *
 * @param type - Type of page
 * @param pageName - Name that needs to be shown in heading
 * @returns Full heading
 */
export const renderHeading = (type: "Add" | "Edit" | "View", pageName: string) => {
	if (type === "Add") {
		return `Add ${pageName}`;
	} else if (type === "Edit") {
		return `Edit ${pageName}`;
	} else if (type === "View") {
		return `View ${pageName}`;
	} else {
		return "";
	}
};

/**
 *
 * @param string - String to truncate
 * @param length - Amount of letters to truncate after
 * @returns Truncated string
 */
export const truncate = (string: string, length: number) => {
	if (string.length <= length) {
		return string;
	}
	return string.substring(0, length) + "...";
};

/**
 *
 * @param phoneNumber - Phone number to format
 * @param format - "international" or "national". Is international by default
 * @returns Formatted phone number
 */
export const formatPhoneNumber = (phoneNumber: string, format?: "international" | "national") => {
	if (format === "national") {
		return parsePhoneNumber(phoneNumber, "PK").formatNational();
	}
	return parsePhoneNumber(phoneNumber, "PK").formatInternational();
};

/**
 *
 * @param complaint - Complaint who's status is being changed
 * @param status - New status of the complaint
 * @param setLoading - Loading set state function
 * @param refetch - Function that refetches table data
 */
export const onTrackingStatusChange = async (
	complaint: Complaint,
	status: "initial" | "accepted" | "inProgress" | "onHold" | "completed" | "rejected",
	setLoading: (value: React.SetStateAction<boolean>) => void,
	refetch: () => void,
) => {
	try {
		setLoading(true);
		// Reading the complaints document
		await getDoc(doc(firestore, COMPLAINTS_COLLECTION, complaint.userId)).then(async (document) => {
			if (document.exists()) {
				// Getting all the complaints in that document
				const complaintDoc = document.data() as Omit<ComplaintDoc, "id">;
				// Updating the status of the complaint that is being updated
				const payload = complaintDoc.complaints.map((item) => {
					if (item.id === complaint.id) {
						return {
							...item,
							status: status,
						};
					} else {
						return item;
					}
				});
				// Setting the updated complaints array
				await setDoc(
					doc(firestore, COMPLAINTS_COLLECTION, complaint.userId),
					{
						complaints: payload,
					},
					{ merge: true },
				).then(async () => {
					// Added a new document or updating in the complaint tracking collection
					const trackingPayload = {
						...complaint,
						status: status,
						phoneNumber: complaintDoc.phoneNumber,
					};
					await setDoc(doc(firestore, COMPLAINTS_TRACKING_COLLECTION, complaint.id), {
						...trackingPayload,
					})
						.then(() => {
							refetch();
						})
						.finally(() => {
							setLoading(false);
						});
				});
			}
		});
	} catch (error) {
		genericErrorFunction({ logMessage: "Error occurred while updating complaint status: ", error });
		setLoading(false);
	}
};

/**
 *
 * @param complaint - Complaint who's status is being changed
 * @param setLoading - Loading set state function
 * @param refetch - Function that refetches table data
 */
export const onTrackingStatusChangeToCompleted = async (
	complaint: Complaint,
	reason: string,
	setLoading: (value: React.SetStateAction<boolean>) => void,
	refetch: () => void,
) => {
	try {
		setLoading(true);
		// Reading the complaints document
		await getDoc(doc(firestore, COMPLAINTS_COLLECTION, complaint.userId)).then(async (document) => {
			if (document.exists()) {
				// Getting all the complaints in that document
				const complaintDoc = document.data() as Omit<ComplaintDoc, "id">;
				// Removing the complaint that is being marked as completed
				const payload = complaintDoc.complaints.filter((item) => item.id !== complaint.id);
				// Setting the new complaints array
				await setDoc(
					doc(firestore, COMPLAINTS_COLLECTION, complaint.userId),
					{
						complaints: payload,
					},
					{ merge: true },
				).then(async () => {
					// Added a new document in the complaint archived collection
					const archivedPayload = complaintDoc.complaints
						.filter((item) => item.id === complaint.id)
						.map((item) => {
							return {
								...item,
								status: "completed",
								completedInfo: reason,
							};
						})[0];
					await setDoc(
						doc(firestore, COMPLAINTS_ARCHIVED_COLLECTION, complaint.userId),
						{
							...complaintDoc,
							complaints: arrayUnion(archivedPayload),
						},
						{ merge: true },
					)
						.then(async () => {
							// Added a new document or updating in the complaint tracking collection
							const trackingPayload = {
								...complaint,
								status: "completed",
								phoneNumber: complaintDoc.phoneNumber,
								completedInfo: reason,
							};
							await setDoc(doc(firestore, COMPLAINTS_TRACKING_COLLECTION, complaint.id), {
								...trackingPayload,
							})
								.then(() => {
									refetch();
								})
								.finally(() => {
									setLoading(false);
								});
						})
						.finally(() => {
							setLoading(false);
						});
				});
			}
		});
	} catch (error) {
		genericErrorFunction({ logMessage: "Error occurred while updating complaint status: ", error });
		setLoading(false);
	}
};

/**
 *
 * @param role - Current user role
 * @returns Route where the user needs to be redirected to on login
 */
export const getInitialPage = (role: "ADMIN" | "SUPERVISOR") => {
	if (role === "ADMIN") {
		return `/${ADMINS_}`;
	} else if (role === "SUPERVISOR") {
		return `/${COMPLAINTS_}`;
	} else {
		return `/${DASHBOARD_}`;
	}
};

/**
 *
 * @param bytes - Size of file in bytes
 * @returns Size of file in mega bytes
 */
export const bytesToMegaBytes = (bytes: number) => {
	return bytes / (1024 * 1024);
};

/**
 *
 * @param allowedFileExtensions - Array of file extensions that are allowed i.e. ["pdf", "jpeg", "png"]
 * @param fileName - Name of the selected file. Should include extension after name i.e file.txt
 * @returns If file is allowed or not
 */
export const isFileTypeAllowed = (allowedFileExtensions: Array<string>, fileName: string) => {
	const fileExtensionSplit = fileName.split(".").at(-1);
	const fileExtension = fileExtensionSplit ? fileExtensionSplit.toLocaleLowerCase() : "";

	return allowedFileExtensions.includes(fileExtension);
};

/**
 *
 * @param currentFiles Files currently selected in the onChange function
 * @param allFiles Fall Files selected by the user, including the ones that were already added
 * @param setOpenSnackBar setState function to open snackbar
 * @param setSnackBarMessage setState function to set snackbar message
 * @param setSnackBarType setState function to set snackbar type
 * @returns - Names of all the files that are allowed
 */

export const errorHandlingOnFileSelection = (currentFiles: FileList | null, allFiles: Array<File>) => {
	// Checking if any of the files have an extension that is not allowed or if they're within the allowed size limit. If yes then throwing an error
	let allowedFiles: Array<File> = [];
	const allSelectedFilesNames = allFiles.map((item) => item.name);

	if (currentFiles) {
		// Initial check to see if user is not uploading more than 3 files
		if (allFiles.length > 3 || allFiles.length + currentFiles.length > 3) {
			enqueueSnackbar("Only 3 files can be uploaded.", { variant: "error" });
			return [];
		}

		for (let i = 0; i < currentFiles.length; i++) {
			const file: File | null = currentFiles.item(i);

			if (file) {
				// Checking if the currently selected file has not previously been added.
				if (allSelectedFilesNames.includes(file.name) === false) {
					// Then checking if the current files extension is allowed. If not we throw an error and not add the file in the array
					if (isFileTypeAllowed(["jpeg", "png", "jpg"], file.name) === false) {
						enqueueSnackbar("Only images can be uploaded ('jpeg', 'jpg', 'png')", { variant: "error" });
					}
					// Next checking if the file size is within the limit. If not we again throw an error and not add the file in the array.
					else if (bytesToMegaBytes(file.size) > 5) {
						enqueueSnackbar("Size of image files should be less than 5MB.", { variant: "error" });
					}
					// Finally if all the above conditions are met, only then is the file added into the allowed array
					else {
						allowedFiles = [...allowedFiles, file];
					}
				}
			}
		}
	}

	return allowedFiles;
};

export const scrollToElement = (id: string) => {
	const element = document.getElementById(id);
	if (element) {
		element.scrollIntoView({ behavior: "smooth" });
	}
};

export const convertTimestampToDate = (timestamp: Timestamp, dateTime?: boolean) => {
	const date = timestamp.toDate();
	if (dateTime) {
		return dayjs(date).format("ddd. D MMM YYYY") + " at " + dayjs(date).format("h:m A");
	} else {
		return dayjs(date).format("ddd. D MMM YYYY");
	}
};

// This function adds any missing permission sections. That can happen when a new feature is launched. This makes sure that there are no missing sections in the permissions array which helps in assigning those permissions in the future
export const addMissingSectionsToPreSelectedPermissions = (selectedPermissions: UserPermissionsType) => {
	allFeaturePermissions.forEach((section) => {
		const foundSection = selectedPermissions.find((item) => item.id === section.id);
		// If section is not found meaning it does not exist in the selectedPermissions, we add it
		if (foundSection === undefined) {
			selectedPermissions.push({
				...section,
				permissions: section.permissions.map((item) => {
					return { ...item, isAllowed: false };
				}),
			});
		}
		// Else checking all the permissions of the found section
		else {
			section.permissions.forEach((permission) => {
				const foundPermission = foundSection.permissions.find((item) => item.id === permission.id);
				// If a permission is missing in a section, we replace that section so it has the new permissions
				if (foundPermission === undefined) {
					selectedPermissions = [
						...selectedPermissions.filter((item) => item.id !== foundSection.id),
						{
							...section,
							permissions: section.permissions.map((item) => {
								return { ...item, isAllowed: false };
							}),
						},
					];
				}
			});
		}
	});
	return selectedPermissions;
};

export const addInventoryLog = async (
	payload: Omit<InventoryLog, "id">,
	onFinally?: () => void,
	successMessage?: string,
) => {
	try {
		await addDoc(collection(firestore, INVENTORY_LOGS_COLLECTION), payload)
			.then(() => {
				enqueueSnackbar(successMessage ? successMessage : "Inventory Log Created!", { variant: "success" });
			})
			.finally(onFinally);
	} catch (error) {
		genericErrorFunction({ logMessage: "Error occurred while adding inventory log: ", error });
	}
};

const returnIsoStringWithTimeAsZero = (date: Dayjs) => {
	return date.set("hours", 0).set("minutes", 0).set("seconds", 0).set("milliseconds", 0).toISOString();
};

const returnIsoStringWithTimeAsMax = (date: Dayjs) => {
	return date.set("hours", 23).set("minutes", 59).set("seconds", 59).set("milliseconds", 59).toISOString();
};

const getLogsQuery = (itemId: string, fromDate?: Dayjs, toDate?: Dayjs, customQuery?: Query<DocumentData>) => {
	if (customQuery) {
		return customQuery;
	} else if (fromDate !== undefined && toDate !== undefined) {
		return query(
			collection(firestore, INVENTORY_LOGS_COLLECTION),
			where("inventoryItemId", "==", itemId),
			where("createdAt", ">=", Timestamp.fromDate(new Date(returnIsoStringWithTimeAsZero(fromDate)))),
			where("createdAt", "<=", Timestamp.fromDate(new Date(returnIsoStringWithTimeAsMax(toDate)))),
			orderBy("createdAt", "asc"),
		);
	} else {
		return query(
			collection(firestore, INVENTORY_LOGS_COLLECTION),
			where("inventoryItemId", "==", itemId),
			orderBy("createdAt", "asc"),
		);
	}
};

export const getItemLogs = async (
	itemId: string,
	fromDate?: Dayjs,
	toDate?: Dayjs,
	customQuery?: Query<DocumentData>,
) => {
	const logs: Array<InventoryLog> = [];
	const itemLogsQuery = getLogsQuery(itemId, fromDate, toDate, customQuery);

	await getDocs(itemLogsQuery).then((snapshot) => {
		snapshot.forEach((doc) => {
			const data = doc.data() as Omit<InventoryLog, "id">;
			const itemLog: InventoryLog = {
				id: doc.id,
				inventoryItemId: data.inventoryItemId,
				quantityUpdatedFrom: data.quantityUpdatedFrom,
				quantityUpdatedTo: data.quantityUpdatedTo,
				updateNote: data.updateNote,
				createdAt: data.createdAt,
				createdBy: data.createdBy,
				createdByName: data.createdByName,
			};
			logs.push(itemLog);
		});
	});

	return logs;
};

export const getClientBasedOnRole = (
	role: "ADMIN" | "SUPERVISOR" | null,
	localClientId: string | null,
	selectedClient: Client | null,
	allClients: Array<Client>,
) => {
	if (role === "SUPERVISOR" && localClientId) {
		return allClients.filter((item) => item.id === localClientId)[0];
	} else if (role === "ADMIN" && selectedClient) {
		return selectedClient;
	} else {
		// Will never reach this condition
		return {
			id: "",
			name: "",
			description: "",
			projects: [],
		};
	}
};

export const getItemAdditionsAndSubtractions = (logs: Array<InventoryLog>) => {
	let totalAdditions = 0;
	let totalSubtraction = 0;

	logs.forEach((log) => {
		const from = log.quantityUpdatedFrom;
		const to = log.quantityUpdatedTo;

		// If the from value is greater than the to value, this means that in that log, the items were subtracted
		if (from > to) {
			const amountSubtracted = from - to;
			totalSubtraction = totalSubtraction + amountSubtracted;
		}
		// else if the to value is greater than the from value, this means that in that log, the items were added
		else if (to > from) {
			const amountAdded = to - from;
			totalAdditions = totalAdditions + amountAdded;
		}
	});

	return { totalAdditions, totalSubtraction };
};

export const formatInventoryToExportData = async (inventoryItems: Array<InventoryItem>, fromDate: Dayjs) => {
	const dataToExport = [];

	for (const item of inventoryItems) {
		// For items that have no change withing the asked time period, We fetch the last log it had, before the fromDate. Meaning the last entry before the specified time period and we show that logs To value as the starting and ending quantity
		if (item.logs.length === 0) {
			const previousLogs = await getItemLogs(
				item.id,
				undefined,
				undefined,
				query(
					collection(firestore, INVENTORY_LOGS_COLLECTION),
					where("inventoryItemId", "==", item.id),
					where("createdAt", "<", Timestamp.fromDate(new Date(returnIsoStringWithTimeAsZero(fromDate)))),
					orderBy("createdAt", "desc"),
					limit(1),
				),
			);

			dataToExport.push({
				["Name"]: item.name,
				["Starting Quantity"]: previousLogs[0].quantityUpdatedTo,
				["Additions"]: 0,
				["Issue"]: 0,
				["Ending Quantity"]: previousLogs[0].quantityUpdatedTo,
			});
		} else {
			const { totalAdditions, totalSubtraction } = getItemAdditionsAndSubtractions(item.logs);
			dataToExport.push({
				["Name"]: item.name,
				["Starting Quantity"]: item.logs[0].quantityUpdatedFrom,
				["Additions"]: totalAdditions,
				["Issue"]: totalSubtraction,
				["Ending Quantity"]: item.logs.at(-1)?.quantityUpdatedTo,
			});
		}
	}

	return dataToExport;
};

export const exportToExcel = (fileName: string, data: Array<any>) => {
	const worksheet = XLSX.utils.json_to_sheet(data);
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
	const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
	const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
	saveAs(blob, `${fileName}.xlsx`);
};

export const getClientName = (clients: Array<Client>, clientId: string) => {
	if (clients.length) {
		const foundClient = clients.find((item) => item.id === clientId);
		if (foundClient) {
			return foundClient.name;
		}
	}
	return "";
};

export const getIssueCategoryName = (issueCategories: Array<IssueCategory>, issueCategoryId: string) => {
	if (issueCategories.length) {
		const foundIssueCategory = issueCategories.find((item) => item.id === issueCategoryId);
		if (foundIssueCategory) {
			return foundIssueCategory.name;
		}
	}
	return "";
};
