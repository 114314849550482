import { doc, getDoc } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CLIENTS_COLLECTION } from "../constants/firebaseConstants";
import { firestore } from "../firebase/config";
import { genericErrorFunction } from "../utils/helpers";
import { Client } from "../utils/types";

export default function useClient(id: string | null) {
	const navigate = useNavigate();
	const [client, setClient] = useState<Client | null>(null);
	const [loading, setLoading] = useState(false);

	const getClient = async () => {
		if (id) {
			try {
				setLoading(true);
				const clientQuery = doc(firestore, CLIENTS_COLLECTION, id);

				await getDoc(clientQuery)
					.then((doc) => {
						if (doc.exists()) {
							const data = doc.data();
							const client: Client = {
								id: doc.id,
								name: data.name,
								description: data.description,
								projects: data.projects,
							};
							setClient(client);
						} else {
							enqueueSnackbar("Client does not exist", { variant: "error" });
							navigate(-1);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching client: ", error });
			}
		}
	};

	useEffect(() => {
		getClient();
	}, [id]);

	return { client, loading };
}
