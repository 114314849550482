import { StandardTextFieldProps, TextField } from "@mui/material";
import { useMask } from "@react-input/mask";

interface CnicComponentProps extends StandardTextFieldProps {
	onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export default function CnicComponent({ ...rest }: CnicComponentProps) {
	const inputRef = useMask({ mask: "_____-_______-_", replacement: "_" });

	const checkIfEnteredKeyShouldBeAllowed = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (
			Number(e.key) === 0 ||
			Number(e.key) === 1 ||
			Number(e.key) === 2 ||
			Number(e.key) === 3 ||
			Number(e.key) === 4 ||
			Number(e.key) === 5 ||
			Number(e.key) === 6 ||
			Number(e.key) === 7 ||
			Number(e.key) === 8 ||
			Number(e.key) === 9 ||
			e.key === "Backspace" ||
			e.key === "Delete" ||
			e.key === "ArrowLeft" ||
			e.key === "ArrowRight" ||
			e.key === "Tab" ||
			(e.ctrlKey && e.key === "a") ||
			(e.ctrlKey && e.key === "c")
		) {
			return;
		} else {
			e.preventDefault();
		}
	};

	return (
		<TextField
			{...rest}
			inputRef={inputRef}
			onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
			onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
				checkIfEnteredKeyShouldBeAllowed(e);
			}}
		/>
	);
}
