import { Button } from "@mui/material";
import { type MRT_ColumnDef as MRTColumnDef } from "material-react-table";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ACCEPTED_COMPLAINTS, COMPLAINTS_ } from "../../../../constants/textConstants";
import useComplaints from "../../../../hooks/useComplaints";
import {
	convertTimestampToDate,
	getClientName,
	getIssueCategoryName,
	onTrackingStatusChange,
	truncate,
} from "../../../../utils/helpers";
import { Complaint } from "../../../../utils/types";
import BackdropLoader from "../../BackdropLoader";
import Table from "../../Table";
import usePermissions from "../../../../hooks/usePermissions";
import useClients from "../../../../hooks/useClients";
import useIssueCategories from "../../../../hooks/useIssueCategories";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export default function AcceptedComplaintTable() {
	const navigate = useNavigate();
	const { role } = useSelector((state: RootState) => state.user);
	const { getPermission } = usePermissions();
	const { complaints, loading, refetch } = useComplaints("accepted");
	const { clients, loading: clientsLoading } = useClients();
	const { issueCategories, loading: issueCategoryLoading } = useIssueCategories();
	const [pageLoading, setPageLoading] = useState(false);

	const complaintColumnsForAdmin: Array<MRTColumnDef<Complaint>> = [
		{
			accessorKey: "subject",
			header: "Subject",
			size: 150,
		},
		{
			accessorKey: "description",
			header: "Description",
			Cell: (row) => <span>{truncate(row.row.original.description, 30)}</span>,
			size: 150,
		},
		{
			accessorKey: "clientId",
			header: "Client",
			Cell: (row) => <span>{getClientName(clients, row.row.original.clientId)}</span>,
			size: 150,
		},
		{
			accessorKey: "issueCategoryId",
			header: "Issue Category",
			Cell: (row) => <span>{getIssueCategoryName(issueCategories, row.row.original.issueCategoryId)}</span>,
			size: 150,
		},
		{
			accessorKey: "createdAt",
			header: "Creation Date",
			Cell: (row) => <span>{convertTimestampToDate(row.row.original.createdAt, true)}</span>,
			size: 150,
		},
	];

	const complaintColumnsForSupervisor: Array<MRTColumnDef<Complaint>> = [
		{
			accessorKey: "subject",
			header: "Subject",
			size: 150,
		},
		{
			accessorKey: "description",
			header: "Description",
			Cell: (row) => <span>{truncate(row.row.original.description, 30)}</span>,
			size: 150,
		},
		{
			accessorKey: "issueCategoryId",
			header: "Issue Category",
			Cell: (row) => <span>{getIssueCategoryName(issueCategories, row.row.original.issueCategoryId)}</span>,
			size: 150,
		},
		{
			accessorKey: "createdAt",
			header: "Creation Date",
			Cell: (row) => <span>{convertTimestampToDate(row.row.original.createdAt, true)}</span>,
			size: 150,
		},
	];

	return (
		<>
			<BackdropLoader open={pageLoading} />
			<Table
				columns={role === "ADMIN" ? complaintColumnsForAdmin : complaintColumnsForSupervisor}
				data={complaints}
				title={ACCEPTED_COMPLAINTS}
				isLoading={loading || clientsLoading || issueCategoryLoading}
				containerHeight="88vh"
				onRowClick={
					getPermission(COMPLAINTS_, "view_accepted")
						? (event, row) => {
								navigate({
									pathname: `/${COMPLAINTS_}/view`,
									search: createSearchParams({
										userId: row.original.userId,
										complaintId: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
				customActionButtonOne={
					getPermission(COMPLAINTS_, "move_to_in_progress")
						? (row) => {
								return (
									<Button
										variant="contained"
										onClick={async (e) => {
											e.stopPropagation();
											await onTrackingStatusChange(
												row.original,
												"inProgress",
												setPageLoading,
												() => {
													refetch(true);
												},
											);
										}}
									>
										Move To In Progress
									</Button>
								);
						  }
						: undefined
				}
			/>
		</>
	);
}
