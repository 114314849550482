import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { INVENTORY_COLLECTION } from "../constants/firebaseConstants";
import { firestore } from "../firebase/config";
import { genericErrorFunction } from "../utils/helpers";
import { Inventory } from "../utils/types";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export default function useInventory() {
	const { role, clientId: loggedInUserClient } = useSelector((state: RootState) => state.user);
	const [inventory, setInventory] = useState<Array<Inventory>>([]);
	const [loading, setLoading] = useState(true);
	const [shouldFetch, refetch] = useState(true);

	const getQuery = () => {
		if (role === "SUPERVISOR" && loggedInUserClient) {
			return query(
				collection(firestore, INVENTORY_COLLECTION),
				where("clientId", "==", loggedInUserClient),
				orderBy("createdAt", "asc"),
			);
		} else {
			return query(collection(firestore, INVENTORY_COLLECTION), orderBy("createdAt", "asc"));
		}
	};

	const getInventory = async () => {
		if (shouldFetch) {
			setInventory([]);
			setLoading(true);
			try {
				const inventoryQuery = getQuery();

				await getDocs(inventoryQuery)
					.then((snapshot) => {
						snapshot.forEach((doc) => {
							const data = doc.data();
							const inventoryItem: Inventory = {
								id: doc.id,
								name: data.name,
								quantity: data.quantity,
								clientId: data.clientId,
								description: data.description,
								createdAt: data.createdAt,
							};
							setInventory((prev) => [...prev, inventoryItem]);
						});
					})
					.finally(() => {
						setLoading(false);
						refetch(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching inventory: ", error });
			}
		}
	};

	useEffect(() => {
		getInventory();
	}, [shouldFetch]);

	return { inventory, loading, refetch };
}
