import { createSlice } from "@reduxjs/toolkit";
interface miscInterface {
	sideBarOpenStatus: boolean;
	complaintsTab: "initial" | "accepted" | "inProgress" | "onHold" | "completed" | "rejected";
	isComplaintSubmitted: boolean;
}

const initialState: miscInterface = {
	sideBarOpenStatus: false,
	complaintsTab: "initial",
	isComplaintSubmitted: false,
};

export const miscSlice = createSlice({
	name: "misc",
	initialState,
	reducers: {
		toggleSideBar: (state) => {
			state.sideBarOpenStatus = !state.sideBarOpenStatus;
		},
		changeComplaintTab: (state, action) => {
			state.complaintsTab = action.payload;
		},
		complaintSubmitted: (state) => {
			state.isComplaintSubmitted = true;
		},
		resetMisc: () => {
			return initialState;
		},
	},
});

export const { toggleSideBar, changeComplaintTab, resetMisc, complaintSubmitted } = miscSlice.actions;

export default miscSlice.reducer;
