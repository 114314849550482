import { Box } from "@mui/system";
import { onAuthStateChanged } from "firebase/auth";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import SquareLoader from "../components/authedComponents/SquareLoader";
import { primaryColor } from "../constants/colorConstants";
import { auth } from "../firebase/config";
import AuthedLayout from "../layouts/AuthedLayout";
import { resetUser } from "../redux/user";
import usePermissions from "../hooks/usePermissions";

export default function AuthedRouteWrapper({ children, route }: { children: ReactNode; route: string }) {
	// Checking if user is logged in.
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { allowedRoutes } = usePermissions();
	const [role, setRole] = useState<string | null>(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const unSubscribe = onAuthStateChanged(auth, (user) => {
			if (user === null || user.isAnonymous) {
				dispatch(resetUser());
				setLoading(false);
				navigate("/");
			} else {
				user.getIdTokenResult().then((idToken) => {
					setRole(idToken.claims.role);
					setLoading(false);
				});
			}
		});
		return unSubscribe;
	}, []);

	const isSelectedRouteAllowed = (): boolean => {
		return allowedRoutes.includes(route);
		// DISABLE PERMISSIONS
		// return true;
	};

	const renderRoute = () => {
		if (loading || role === null) {
			return (
				<Box className="center" sx={{ width: "100%", height: "100vh", display: "flex" }}>
					<SquareLoader color={primaryColor} />
				</Box>
			);
		} else {
			if (isSelectedRouteAllowed()) {
				return <AuthedLayout>{children}</AuthedLayout>;
			} else {
				return <Navigate to="/404" replace />;
			}
		}
	};

	return <>{renderRoute()}</>;
}
