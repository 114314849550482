import { collection, getDocs, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { COMPLAINTS_ARCHIVED_COLLECTION, COMPLAINTS_COLLECTION } from "../constants/firebaseConstants";
import { firestore } from "../firebase/config";
import { RootState } from "../redux/store";
import { genericErrorFunction } from "../utils/helpers";
import { Complaint, ComplaintDoc } from "../utils/types";

export default function useComplaints(
	status?: "initial" | "accepted" | "inProgress" | "onHold" | "completed" | "rejected",
) {
	const [complaints, setComplaints] = useState<Array<Complaint>>([]);
	const { issueCategories, clientId } = useSelector((state: RootState) => state.user);
	const [loading, setLoading] = useState(true);
	const [shouldFetch, refetch] = useState(true);

	const getQuery = () => {
		if (status === "completed") {
			return query(collection(firestore, COMPLAINTS_ARCHIVED_COLLECTION));
		} else {
			return query(collection(firestore, COMPLAINTS_COLLECTION));
		}
	};

	const getComplaints = async () => {
		if (shouldFetch) {
			setComplaints([]);
			setLoading(true);
			try {
				// Getting all the supervisors issue category ids. If user is admin this array is empty.
				const requiredIssueCategories: Array<string> =
					issueCategories && issueCategories.length ? issueCategories : [];

				const complaintsQuery = getQuery();

				await getDocs(complaintsQuery)
					.then((snapshot) => {
						snapshot.forEach((doc) => {
							// Extracting the complaints array from each document
							const { complaints: extractedComplaints, ...rest } = doc.data() as Omit<ComplaintDoc, "id">;

							// Creating a temporary array for the complaints that will be filtered by the supervisors issue categories
							let filteredExtractedComplaints: Array<Complaint> = [];

							// First we filter complaints by client. Meaning we only show the complaints of the client that is assigned to the supervisor. Then we filter by the issue categories assigned to the supervisor and lastly we filter by status that is sent in the props. If client or issue categories or status is null or undefined we send all the items from their respective filter function. (client and issue categories will only be null in case of admin user)
							filteredExtractedComplaints = extractedComplaints
								.filter((item) => {
									if (clientId) {
										return item.clientId === clientId;
									} else {
										return true;
									}
								})
								.filter((item) => {
									if (requiredIssueCategories.length) {
										return requiredIssueCategories.includes(item.issueCategoryId);
									} else {
										return true;
									}
								})
								.filter((item) => {
									if (status) {
										return item.status === status;
									} else {
										return true;
									}
								});

							// Lastly setting the data in the state. This all happens in a loop so that we don't have to filter over a large array.
							setComplaints((prev) => [...prev, ...filteredExtractedComplaints]);
						});
					})
					.finally(() => {
						setLoading(false);
						refetch(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching complaints: ", error });
			}
		}
	};

	useEffect(() => {
		getComplaints();
	}, [shouldFetch]);

	return { complaints, loading, refetch };
}
