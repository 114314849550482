import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { FirebaseError } from "firebase/app";
import { signInAnonymously } from "firebase/auth";
import { arrayUnion, doc, setDoc, Timestamp } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuid4 } from "uuid";
import ComplaineeInformation from "../../components/unAuthedComponents/Complaints/ComplaineeInformation";
import ComplaintFinish from "../../components/unAuthedComponents/Complaints/ComplaintFinish";
import ComplaintInformation from "../../components/unAuthedComponents/Complaints/ComplaintInformation";
import ComplaintIntroduction from "../../components/unAuthedComponents/Complaints/ComplaintIntroduction";
import PersonalInformation from "../../components/unAuthedComponents/Complaints/PersonalInformation";
import { COMPLAINTS_COLLECTION } from "../../constants/firebaseConstants";
import { auth, firestore, storage } from "../../firebase/config";
import useIsMediumDevice from "../../hooks/useIsMediumDevice";
import useIsMobile from "../../hooks/useIsMobile";
import { complaintSubmitted } from "../../redux/misc";
import { RootState } from "../../redux/store";
import { theme } from "../../theme/theme";
import { formatPhoneNumber, genericErrorFunction } from "../../utils/helpers";
import { Client, Complaint, ComplaintDoc, IssueCategory } from "../../utils/types";

const firstComplaintSteps = ["Complainee Information", "Complaint Information", "Personal Information"];
const nextComplaintSteps = ["Complainee Information", "Complaint Information"];

export interface ComplaintFields {
	complaintSubject: string;
	complaintDescription: string;
	complaintCompany: Client | null;
	complaintIssueCategory: IssueCategory | null;
	name: string;
	email: string;
	phoneNumber: string;
	company: string;
	department: string;
	files: Array<File>;
}

const defaultValues = {
	complaintSubject: "",
	complaintDescription: "",
	complaintCompany: null,
	complaintIssueCategory: null,
	name: "",
	email: "",
	phoneNumber: "",
	company: "",
	department: "",
	files: [],
};

export default function AddComplaint() {
	const dispatch = useDispatch();
	const isMobile = useIsMobile();
	const isMediumDevice = useIsMediumDevice();
	const navigate = useNavigate();
	const [step, setStep] = useState(-1);
	const [gradientTextClass, setGradientTextClass] = useState("hide");
	const [loading, setLoading] = useState(false);
	const { isComplaintSubmitted } = useSelector((state: RootState) => state.misc);

	const { control, handleSubmit, setValue, trigger } = useForm<ComplaintFields>({
		mode: "onTouched",
		defaultValues,
		// defaultValues: {
		// 	complaintSubject: "Test Complaint",
		// 	complaintDescription: "Test",
		// 	complaintCompany: {
		// 		id: "NxCLtb2RZCys2Wz6y0D4",
		// 		name: "Test Client",
		// 		description: "Test Client Description",
		// 		projects: [
		// 			{
		// 				name: "Project 1",
		// 				description: "Project 1 Description",
		// 				id: "3840ffa7-fac6-47a5-8055-ff5c7af31924",
		// 			},
		// 			{
		// 				name: "Project 2",
		// 				description: "Project 2 Description",
		// 				id: "919431b0-4622-4d64-b79c-7a91c7fa14d9",
		// 			},
		// 			{
		// 				name: "Project 3",
		// 				description: "Project 3 Description",
		// 				id: "c7de0dc9-2ecd-468a-8a67-a9219f80a7a8",
		// 			},
		// 		],
		// 	},
		// 	complaintIssueCategory: {
		// 		id: "D1Wuh4oCdx2mn5VFnh9k",
		// 		name: "Electrical",
		// 		description: "asdasd",
		// 	},
		// 	name: "Test",
		// 	email: "test@mailinator.com",
		// 	phoneNumber: "0340 2136686",
		// 	company: "Test",
		// 	department: "Test",
		// 	files: [],
		// },
	});

	const uploadFiles = async (userId: string, complaintId: string, files: Array<File>): Promise<Array<string>> => {
		const downloadUrls: Array<string> = [];
		for (let index = 0; index < files.length; index++) {
			const file = files[index];
			const storageRef = ref(storage, `/complaints/${userId}/${complaintId}/${index + 1}_${file.name}`);
			const snapshot = await uploadBytes(storageRef, file);
			const fileUrl = await getDownloadURL(snapshot.ref);
			downloadUrls.push(fileUrl);
		}
		return downloadUrls;
	};

	const addComplaint = async (data: ComplaintFields, userId: string, type: "add" | "update") => {
		if (data.complaintCompany && data.complaintIssueCategory) {
			const complaintId = uuid4();
			if (type === "add") {
				const payload: Omit<ComplaintDoc, "id"> = {
					name: data.name,
					email: data.email,
					phoneNumber: formatPhoneNumber(data.phoneNumber),
					company: data.company,
					department: data.department,
					complaints: [
						{
							id: complaintId,
							subject: data.complaintSubject,
							description: data.complaintDescription,
							clientId: data.complaintCompany.id,
							issueCategoryId: data.complaintIssueCategory.id,
							userId: userId,
							status: "initial",
							createdAt: Timestamp.now(),
							files: data.files ? await uploadFiles(userId, complaintId, data.files) : [],
						},
					],
				};
				await setDoc(doc(firestore, COMPLAINTS_COLLECTION, userId), payload)
					.then(() => {
						hideText();
						dispatch(complaintSubmitted());
						setStep(3);
					})
					.finally(() => {
						setLoading(false);
					});
			} else if (type === "update") {
				// Using arrayUnion so we don't have to fetch all the previous complaints just for update
				const payload = {
					complaints: arrayUnion({
						id: complaintId,
						subject: data.complaintSubject,
						description: data.complaintDescription,
						clientId: data.complaintCompany.id,
						issueCategoryId: data.complaintIssueCategory.id,
						userId: userId,
						status: "initial",
						createdAt: Timestamp.now(),
						files: data.files ? await uploadFiles(userId, complaintId, data.files) : [],
					}),
				};
				await setDoc(doc(firestore, COMPLAINTS_COLLECTION, userId), payload, { merge: true })
					.then(() => {
						hideText();
						setStep(3);
					})
					.finally(() => {
						setLoading(false);
					});
			}
		}
	};

	const onSubmit: SubmitHandler<ComplaintFields> = async (data) => {
		if (data.complaintCompany && data.complaintIssueCategory) {
			try {
				setLoading(true);
				// If an anon user exists we use that and send the data
				if (auth.currentUser && auth.currentUser.isAnonymous) {
					await addComplaint(data, auth.currentUser.uid, isComplaintSubmitted ? "update" : "add");
				}
				// else if anon user does not exist we create one and send data
				else if (auth.currentUser === null) {
					await signInAnonymously(auth).then(async (user) => {
						await addComplaint(data, user.user.uid, isComplaintSubmitted ? "update" : "add");
					});
				}
			} catch (error) {
				if (error instanceof FirebaseError && error.code === "permission-denied") {
					enqueueSnackbar("You've already submitted 10 complaints. Please wait while they get resolved", {
						variant: "error",
					});
				} else {
					genericErrorFunction({
						logMessage: "Error occurred while adding complaint: ",
						error,
						snackbarMessage: "Something went wrong.",
					});
				}
			}
		}
	};

	const renderSteps = () => {
		if (step === -1) {
			return (
				<ComplaintIntroduction
					onNextClick={() => {
						setStep(0);
					}}
				/>
			);
		} else if (step === 0) {
			return (
				<ComplaineeInformation
					control={control}
					loading={loading}
					onNextClick={async () => {
						const isComplaintCompanyClear = await trigger("complaintCompany");
						const isComplaintIssueCategoryClear = await trigger("complaintIssueCategory");
						if (isComplaintCompanyClear && isComplaintIssueCategoryClear) {
							setStep(1);
							triggerAnimation();
						}
					}}
				/>
			);
		} else if (step === 1) {
			return (
				<ComplaintInformation
					control={control}
					onNextClick={async () => {
						const isComplaintSubjectClear = await trigger("complaintSubject");
						const isComplaintDescriptionClear = await trigger("complaintDescription");
						if (isComplaintSubjectClear && isComplaintDescriptionClear) {
							// If user has already submitted a complaint before, not triggering the personal information screen. Instead we directly submit the complaint.
							if (isComplaintSubmitted) {
								handleSubmit(onSubmit)();
							}
							// If user has not submitted a complaint yet, we ask for personal information.
							else {
								setStep(2);
								triggerAnimation();
							}
						}
					}}
				/>
			);
		} else if (step === 2) {
			return (
				<PersonalInformation
					control={control}
					loading={loading}
					onSubmitClick={async () => {
						const isNameClear = await trigger("name");
						const isPhoneNumberClear = await trigger("phoneNumber");
						const isEmailClear = await trigger("email");
						const isCompanyClear = await trigger("company");
						const isDepartmentClear = await trigger("department");
						if (isNameClear && isPhoneNumberClear && isEmailClear && isCompanyClear && isDepartmentClear) {
							handleSubmit(onSubmit)();
						}
					}}
				/>
			);
		} else if (step === 3) {
			return (
				<ComplaintFinish
					onFinishClick={() => {
						navigate("/");
					}}
					onRestartClick={() => {
						hideText();
						setValue("complaintSubject", defaultValues.complaintSubject);
						setValue("complaintDescription", defaultValues.complaintDescription);
						setValue("files", defaultValues.files);
						setStep(0);
					}}
				/>
			);
		} else {
			return <></>;
		}
	};

	const triggerAnimation = () => {
		setGradientTextClass("fadeIn");
	};

	const removeAnimation = () => {
		setGradientTextClass("");
	};

	const hideText = () => {
		setGradientTextClass("hide");
	};

	const onBack = () => {
		setStep((prev) => prev - 1);
		triggerAnimation();
	};

	const renderTitle = () => {
		if (step === 0) {
			return "Complainee Information";
		} else if (step === 1) {
			return "Complaint Information";
		} else if (step === 2) {
			return "Personal Information";
		} else {
			return "";
		}
	};

	const renderDescription = () => {
		if (step === -1) {
			return "";
		} else if (step === 0) {
			return "In this section we'd like to get information about the client or client premise you belong to. \n ( Clients are any companies that are using Numla's services and have opted in to use our complaint management system )";
		} else if (step === 1) {
			return "In this section you will be providing any relevant information regarding the issue you're having";
		} else if (step === 2) {
			return "In this section we require some information about you. This is so we can provide you updates and contact you if needed";
		} else {
			return "";
		}
	};

	return (
		<Box className="unauth-background">
			<Paper elevation={20} className="unauth-paper">
				<Box
					sx={{
						height: "100%",
						display: "flex",
						position: "relative",
					}}
				>
					{step > 0 && step < 3 && (
						<IconButton
							sx={{
								position: "absolute",
								zIndex: 1,
							}}
							onClick={onBack}
							disabled={loading}
						>
							<FontAwesomeIcon icon={faArrowLeft} />
						</IconButton>
					)}
					{!isMobile && (
						<Box
							className={`complaint-gradient complaint-gradient-animation ${
								step > -1 && step < 3 && "complaint-gradient-animation-expanded"
							}`}
							onTransitionEnd={triggerAnimation}
						>
							<Box className={gradientTextClass} onAnimationEnd={removeAnimation}>
								<Typography
									mb={3}
									variant={isMediumDevice ? "h5" : "h2"}
									color={theme.palette.lightColor.main}
								>
									{renderTitle()}
								</Typography>
								<Typography
									mb={10}
									className="break-line-typography"
									variant="caption"
									color={theme.palette.lightColor.main}
								>
									{renderDescription()}
								</Typography>
							</Box>
						</Box>
					)}
					<Box className="complaint-center">
						{step > -1 && step < 3 && (
							<Box
								className="complaint-stepper-container"
								sx={isMobile ? { marginTop: "40px" } : undefined}
							>
								<Stepper activeStep={step} alternativeLabel>
									{isComplaintSubmitted
										? nextComplaintSteps.map((label, index) => (
												<Step key={label}>
													<StepLabel>{label}</StepLabel>
												</Step>
										  ))
										: firstComplaintSteps.map((label, index) => (
												<Step key={label}>
													<StepLabel>{label}</StepLabel>
												</Step>
										  ))}
								</Stepper>
							</Box>
						)}
						{renderSteps()}
					</Box>
				</Box>
			</Paper>
		</Box>
	);
}
