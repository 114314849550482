import { doc, getDoc } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CONTACT_COLLECTION } from "../constants/firebaseConstants";
import { firestore } from "../firebase/config";
import { genericErrorFunction } from "../utils/helpers";
import { Contact } from "../utils/types";

export default function useContact(id: string | null) {
	const navigate = useNavigate();
	const [contact, setContact] = useState<Contact | null>(null);
	const [loading, setLoading] = useState(false);

	const getContact = async () => {
		if (id) {
			try {
				setLoading(true);
				const contactQuery = doc(firestore, CONTACT_COLLECTION, id);

				await getDoc(contactQuery)
					.then((doc) => {
						if (doc.exists()) {
							const data = doc.data();
							const contact: Contact = {
								id: doc.id,
								name: data.name,
								email: data.email,
								subject: data.subject,
								message: data.message,
							};
							setContact(contact);
						} else {
							enqueueSnackbar("Contact does not exist", { variant: "error" });
							navigate(-1);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching contact: ", error });
			}
		}
	};

	useEffect(() => {
		getContact();
	}, [id]);

	return { contact, loading };
}
