/* eslint-disable no-useless-escape */
import {
	ADMINS_,
	CLIENTS_,
	COMPLAINTS_,
	CONTACTS_,
	DASHBOARD_,
	ISSUE_CATEGORIES_,
	SUPERVISORS_,
} from "./textConstants";

export const EMAIL_REGEX =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const UPPERCASE_CHARACTER_REGEX = /[A-Z]/;
export const LOWERCASE_CHARACTER_REGEX = /[a-z]/;
export const NUMBER_CHARACTER_REGEX = /\d/;
export const SPECIAL_CHARACTER_REGEX = /[!@#$%^&*()\-+={}[\]:;"'<>,.?\/|\\]/;

export const CNIC_REGEX = /[0-9]+-[0-9]+-\d/;

// export const ADMIN_ALLOWED_ROUTES = [
// 	`${DASHBOARD_}`,
// 	`${ADMINS_}`,
// 	`${ADMINS_}/add`,
// 	`${ADMINS_}/edit`,
// 	`${ADMINS_}/view`,
// 	`${SUPERVISORS_}`,
// 	`${SUPERVISORS_}/add`,
// 	`${SUPERVISORS_}/edit`,
// 	`${SUPERVISORS_}/view`,
// 	`${CLIENTS_}`,
// 	`${CLIENTS_}/add`,
// 	`${CLIENTS_}/edit`,
// 	`${CLIENTS_}/view`,
// 	`${ISSUE_CATEGORIES_}`,
// 	`${ISSUE_CATEGORIES_}/add`,
// 	`${ISSUE_CATEGORIES_}/edit`,
// 	`${ISSUE_CATEGORIES_}/view`,
// 	`${CONTACTS_}`,
// 	`${CONTACTS_}/view`,
// 	`${COMPLAINTS_}`,
// 	`${COMPLAINTS_}/view`,
// ];
// export const SUPERVISOR_ALLOWED_ROUTES = [`${DASHBOARD_}`, `${COMPLAINTS_}`, `${COMPLAINTS_}/view`];
