import { FirebaseError } from "@firebase/util";
import { Button, Link, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import BackdropLoader from "../../components/authedComponents/BackdropLoader";
import PasswordTextField from "../../components/PasswordTextField";
import { USERS_COLLECTION } from "../../constants/firebaseConstants";
import { EMAIL_REGEX } from "../../constants/helperConstants";
import { DASHBOARD_, FORGOT_PASSWORD, FORGOT_PASSWORD_, LOGIN, LOGIN_ } from "../../constants/textConstants";
import { auth, firestore } from "../../firebase/config";
import useIsMediumDevice from "../../hooks/useIsMediumDevice";
import useIsMobile from "../../hooks/useIsMobile";
import { setUserData } from "../../redux/user";
import { theme } from "../../theme/theme";

interface ForgotPasswordFields {
	email: string;
}

export default function ForgotPassword() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isMobile = useIsMobile();
	const isMediumDevice = useIsMediumDevice();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const { control, handleSubmit, reset } = useForm<ForgotPasswordFields>({
		mode: "onTouched",
		defaultValues: {
			email: "",
		},
	});

	const onSubmit: SubmitHandler<ForgotPasswordFields> = async (data) => {
		setLoading(true);

		sendPasswordResetEmail(auth, data.email)
			.then(() => {
				enqueueSnackbar("Reset Password Email Sent!", { variant: "success" });
				reset();
				navigate(`/${LOGIN_}`);
			})
			.catch((error: FirebaseError) => {
				console.error("forgot password error", error);
				switch (error.code) {
					case "auth/user-not-found":
						enqueueSnackbar("The corresponding user does not exist.", { variant: "error" });
						break;
					case "auth/invalid-email":
						enqueueSnackbar("The provided email address is incorrect.", { variant: "error" });
						break;
					default:
						enqueueSnackbar("Something went wrong.", { variant: "error" });
						break;
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<BackdropLoader open={loading} />
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<Box className="unauth-background">
					<Paper elevation={20} className="unauth-paper">
						<Box
							sx={{
								height: "100%",
								display: "flex",
							}}
						>
							<Box className="unauth-center">
								<Typography
									fontSize={isMobile || isMediumDevice ? 30 : 50}
									color={theme.palette.darkColor.main}
								>
									{FORGOT_PASSWORD}
								</Typography>
								<Typography
									mb={isMobile || isMediumDevice ? 0 : 5}
									fontSize={isMobile || isMediumDevice ? 13 : 16}
									color={theme.palette.darkColor.main}
								>
									Please enter your email and we will send you a reset password link.
								</Typography>
								<Controller
									name="email"
									control={control}
									rules={{
										required: "Email is required",
										pattern: {
											value: EMAIL_REGEX,
											message: "Incorrect Email",
										},
									}}
									render={({ field: { ref, ...rest }, fieldState: { error } }) => (
										<TextField
											{...rest}
											label="Email"
											required
											error={error ? true : false}
											helperText={error ? error.message : ""}
											sx={{
												width: "50%",
												...(isMobile && {
													width: "80%",
												}),
												...(isMediumDevice && {
													width: "70%",
												}),
											}}
										/>
									)}
								/>
								<Button
									sx={{
										marginTop: "40px",
										width: "20%",
										...(isMobile && {
											width: "70%",
										}),
										...(isMediumDevice && {
											width: "40%",
										}),
									}}
									variant="contained"
									type="submit"
								>
									Send Email
								</Button>
							</Box>
							{!isMobile && (
								<Box className="login-gradient-animation" sx={{ width: "50%", height: "100%" }} />
							)}
						</Box>
					</Paper>
				</Box>
			</form>
		</>
	);
}
