import { Box, Grid2, Typography } from "@mui/material";
import ComplaintButton from "./ComplaintButton";

interface ComplaintFieldsLayoutProps {
	children: React.ReactNode | React.ReactNode[];
	title: string;
	onButtonClick: () => void | Promise<void>;
	buttonText?: string;
	loading?: boolean;
}

export default function ComplaintFieldsLayout({
	children,
	title,
	onButtonClick,
	buttonText = "Next",
	loading,
}: ComplaintFieldsLayoutProps) {
	return (
		<Box className="complaint-field-container" gap={2}>
			<Grid2 container spacing={2}>
				<Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} textAlign={"start"}>
					<Typography variant="h6" fontWeight={600}>
						{title}
					</Typography>
				</Grid2>
			</Grid2>
			{children}
			<Grid2 paddingBottom={"20px"} container spacing={2}>
				<Grid2 size={{ xs: 12 }}>
					<ComplaintButton text={buttonText} loading={loading} onClick={onButtonClick} />
				</Grid2>
			</Grid2>
		</Box>
	);
}
