import { faCaretDown, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Grid2,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from "@mui/material";
import useComplaintsTrackingUnAuth from "../../../hooks/useComplaintsTrackingUnAuth";
import useIsMediumDevice from "../../../hooks/useIsMediumDevice";
import useIsMobile from "../../../hooks/useIsMobile";
import { theme } from "../../../theme/theme";
import DotsLoader from "../../authedComponents/DotsLoader";
import ImageDisplay from "../../ImageDisplay";
import TumbleWeed from "../TumbleWeed";
import useClientsUnAuth from "../../../hooks/useClientsUnAuth";
import { getClientName, getIssueCategoryName } from "../../../utils/helpers";
import useIssueCategoriesUnAuth from "../../../hooks/useIssueCategoriesUnAuth";

interface ViewScreenProps {
	phoneNumber: string;
}

const steps = [
	{ label: "Assigned to Supervisor", value: "accepted" },
	{ label: "In Progress", value: "inProgress" },
	{ label: "Completed", value: "completed" },
];

const onHoldSteps = [
	{ label: "Assigned to Supervisor", value: "accepted" },
	{ label: "On Hold", value: "onHold" },
	{ label: "Completed", value: "completed" },
];

export default function ViewScreen({ phoneNumber }: ViewScreenProps) {
	const isMobile = useIsMobile();
	const isMediumDevice = useIsMediumDevice();
	const { complaintsTracking, loading } = useComplaintsTrackingUnAuth(phoneNumber);
	const { clients, loading: clientLoading } = useClientsUnAuth();
	const { issueCategories, loading: issueCategoryLoading } = useIssueCategoriesUnAuth();

	const handleDynamicTextSizing = (value: "h2" | "h4" | "h5" | "body1") => {
		if (isMobile || isMediumDevice) {
			if (value === "h2") {
				return "h4";
			} else if (value === "h4") {
				return "h6";
			} else if (value === "h5") {
				return "body1";
			} else if (value === "body1") {
				return "body2";
			}
		} else {
			if (value === "h2") {
				return "h2";
			} else if (value === "h4") {
				return "h4";
			} else if (value === "h5") {
				return "h5";
			} else if (value === "body1") {
				return "body1";
			}
		}
	};

	const getActiveStep = (status: "initial" | "accepted" | "inProgress" | "onHold" | "completed" | "rejected") => {
		if (status === "accepted") {
			return 0;
		} else if (status === "rejected") {
			return 0;
		} else if (status === "inProgress") {
			return 1;
		} else if (status === "onHold") {
			return 1;
		} else if (status === "completed") {
			return 3;
		} else {
			return -1;
		}
	};

	const getStepColor = (
		value: string,
		status: "initial" | "accepted" | "inProgress" | "onHold" | "completed" | "rejected",
	) => {
		if (value === "onHold") {
			return {
				style: {
					color: theme.palette.warning.main,
				},
			};
		} else if (value === "completed" && status === "completed") {
			return {
				style: {
					color: theme.palette.success.main,
				},
			};
		} else if (value === "rejected" && status === "rejected") {
			return {
				style: {
					color: theme.palette.error.main,
				},
			};
		} else {
			return {};
		}
	};

	const getSteps = (status: "initial" | "accepted" | "inProgress" | "onHold" | "completed" | "rejected") => {
		if (status === "onHold") {
			return onHoldSteps;
		} else {
			return steps;
		}
	};

	const renderScreen = () => {
		if (loading || clientLoading || issueCategoryLoading) {
			return <DotsLoader color={theme.palette.secondary.main} />;
		} else {
			if (complaintsTracking.length && clients.length) {
				return (
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							width: "100%",
							height: "100%",
							padding: "20px 20px 20px 0px",
							overflow: "auto",
						}}
						gap={2}
					>
						<Typography variant={handleDynamicTextSizing("h2")}>Your Complaints</Typography>
						<Box>
							{complaintsTracking.map((complaint) => (
								<Box boxShadow={3} key={complaint.id} mt={3}>
									<Accordion defaultExpanded={complaintsTracking.length === 1}>
										<AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown} />}>
											<Typography
												maxWidth={isMobile ? "160px" : "200px"}
												mr={1}
												noWrap
												variant="h5"
											>
												{complaint.subject}
											</Typography>
										</AccordionSummary>
										<AccordionDetails>
											{complaint.status !== "rejected" ? (
												<Stepper
													activeStep={getActiveStep(complaint.status)}
													alternativeLabel={!isMobile}
													orientation={isMobile ? "vertical" : "horizontal"}
												>
													{getSteps(complaint.status).map((step) => (
														<Step key={step.value}>
															<StepLabel
																StepIconProps={getStepColor(
																	step.value,
																	complaint.status,
																)}
															>
																{step.label}
															</StepLabel>
														</Step>
													))}
												</Stepper>
											) : (
												<Box
													sx={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}
													gap={1}
												>
													<FontAwesomeIcon
														icon={faCircleXmark}
														size="2xl"
														color={theme.palette.error.main}
													/>
													<Typography variant={handleDynamicTextSizing("h4")}>
														Your Complaint was rejected
													</Typography>
												</Box>
											)}
											<Grid2
												sx={{
													padding: "10px 10px 10px 10px ",
												}}
												container
												mt={"30px"}
												mb={"10px"}
											>
												<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
													<Box
														sx={
															isMobile
																? {
																		display: "flex",
																		flexDirection: "column",
																		textAlign: "start",
																  }
																: { display: "flex", alignItems: "center" }
														}
													>
														<Typography variant="h6">Company:</Typography>
														<Typography variant="body1" ml={1}>
															{getClientName(clients, complaint.clientId)}
														</Typography>
													</Box>
												</Grid2>
												<Grid2
													mt={isMobile ? 3 : undefined}
													size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}
												>
													<Box
														sx={
															isMobile
																? {
																		display: "flex",
																		flexDirection: "column",
																		textAlign: "start",
																  }
																: { display: "flex", alignItems: "center" }
														}
													>
														<Typography variant="h6">Issue Category:</Typography>
														<Typography variant="body1" ml={1}>
															{getIssueCategoryName(
																issueCategories,
																complaint.issueCategoryId,
															)}
														</Typography>
													</Box>
												</Grid2>
											</Grid2>
											<Grid2
												sx={{
													padding: "10px 10px 10px 10px ",
												}}
												container
												mt={3}
												mb={"10px"}
											>
												<Grid2 size={{ xs: 12, sm: 12, md: 10, lg: 10, xl: 10 }}>
													<Box
														sx={
															isMobile
																? {
																		display: "flex",
																		flexDirection: "column",
																		textAlign: "start",
																  }
																: { display: "flex", alignItems: "center" }
														}
													>
														<Typography variant="h6">Description:</Typography>
														<Typography className="wrap-text" variant="body1" ml={1}>
															{complaint.description}
														</Typography>
													</Box>
												</Grid2>
											</Grid2>
											{complaint.files.length ? (
												<Grid2
													sx={{
														padding: "10px 10px 10px 10px ",
													}}
													container
													mt={"30px"}
													mb={"10px"}
												>
													<Grid2 size={{ xs: 12, sm: 12, md: 10, lg: 10, xl: 10 }}>
														<ImageDisplay
															images={complaint.files.map((url, index) => {
																return {
																	name: `File #${index + 1}`,
																	url: url,
																};
															})}
														/>
													</Grid2>
												</Grid2>
											) : (
												<></>
											)}

											{complaint.status === "onHold" && complaint.onHoldReason && (
												<Grid2
													sx={{
														padding: "10px 10px 10px 10px ",
													}}
													container
													mt={"30px"}
													mb={"10px"}
												>
													<Grid2 size={{ xs: 12, sm: 12, md: 10, lg: 10, xl: 10 }}>
														<Typography textAlign={"left"} variant="h5">
															On Hold Reason:
														</Typography>
														<Typography className="wrap-text" variant="body1">
															{complaint.onHoldReason}
														</Typography>
													</Grid2>
												</Grid2>
											)}
											{complaint.status === "rejected" && complaint.rejectedReason && (
												<Grid2
													sx={{
														padding: "10px 10px 10px 10px ",
													}}
													container
													mt={"30px"}
													mb={"10px"}
												>
													<Grid2 size={{ xs: 12, sm: 12, md: 10, lg: 10, xl: 10 }}>
														<Typography textAlign={"left"} variant="h5">
															Rejected Reason:
														</Typography>
														<Typography className="wrap-text" variant="body1">
															{complaint.rejectedReason}
														</Typography>
													</Grid2>
												</Grid2>
											)}
										</AccordionDetails>
									</Accordion>
								</Box>
							))}
						</Box>
					</Box>
				);
			} else {
				return (
					<Box className="flex flexColumn center">
						<Typography variant={handleDynamicTextSizing("h2")} color={theme.palette.darkColor.main}>
							We&apos;re sorry!
						</Typography>
						<Typography mt={4} variant={handleDynamicTextSizing("h4")} color={theme.palette.darkColor.main}>
							{"There were no complaints found against this phone number."}
						</Typography>
						<Typography
							mt={4}
							className="break-line-typography"
							variant={handleDynamicTextSizing("body1")}
							color={theme.palette.darkColor.main}
						>
							{
								"If you placed your complaint recently please wait until it is assigned to a supervisor. \n Unfortunately, Unassigned complaints cannot be tracked yet."
							}
						</Typography>
						<TumbleWeed />
					</Box>
				);
			}
		}
	};

	return <>{renderScreen()}</>;
}
