import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Typography,
} from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Dispatch } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { COMPLAINTS_COLLECTION, COMPLAINTS_TRACKING_COLLECTION } from "../../../../constants/firebaseConstants";
import { firestore } from "../../../../firebase/config";
import { genericErrorFunction, onTrackingStatusChangeToCompleted } from "../../../../utils/helpers";
import { Complaint, ComplaintDoc } from "../../../../utils/types";

interface ComplaintConfirmationModalProps {
	state: "onHold" | "rejected" | "completed" | null;
	open: boolean;
	onClose: () => void;
	complaint: Complaint | null;
	refetch: () => void;
	loading: boolean;
	setLoading: Dispatch<React.SetStateAction<boolean>>;
}

interface ComplaintConfirmationModalFields {
	reason: string;
}

export default function ComplaintConfirmationModal({
	state,
	open,
	onClose,
	complaint,
	refetch,
	loading,
	setLoading,
}: ComplaintConfirmationModalProps) {
	const { control, handleSubmit, reset } = useForm<ComplaintConfirmationModalFields>({
		mode: "onTouched",
		defaultValues: {
			reason: "",
		},
	});

	const onSubmit: SubmitHandler<ComplaintConfirmationModalFields> = async (data) => {
		if (complaint) {
			try {
				if (state === "completed") {
					await onTrackingStatusChangeToCompleted(complaint, data.reason, setLoading, () => {
						reset();
						onClose();
						refetch();
					});
				} else {
					setLoading(true);
					// Reading the complaints document
					await getDoc(doc(firestore, COMPLAINTS_COLLECTION, complaint.userId)).then(async (document) => {
						if (document.exists()) {
							// Getting all the complaints in that document
							const complaintDoc = document.data() as Omit<ComplaintDoc, "id">;
							// Updating the status of the complaint that is being updated
							const payload = complaintDoc.complaints.map((item) => {
								if (item.id === complaint.id) {
									return {
										...item,
										status: state,
										...(state === "onHold" && { onHoldReason: data.reason }),
										...(state === "rejected" && { rejectedReason: data.reason }),
									};
								} else {
									return item;
								}
							});
							// Setting the updated complaints array
							await setDoc(
								doc(firestore, COMPLAINTS_COLLECTION, complaint.userId),
								{
									complaints: payload,
								},
								{ merge: true },
							).then(async () => {
								// Added a new document in the complaint tracking collection
								const trackingPayload = {
									...complaint,
									status: state,
									phoneNumber: complaintDoc.phoneNumber,
									...(state === "onHold" && { onHoldReason: data.reason }),
									...(state === "rejected" && { rejectedReason: data.reason }),
								};
								await setDoc(doc(firestore, COMPLAINTS_TRACKING_COLLECTION, complaint.id), {
									...trackingPayload,
								})
									.then(() => {
										reset();
										onClose();
										refetch();
									})
									.finally(() => {
										setLoading(false);
									});
							});
						}
					});
				}
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while updating complaint status: ", error });
				setLoading(false);
			}
		}
	};

	const handleOnClose = () => {
		if (!loading) {
			setLoading(false);
			reset();
			onClose();
		}
	};

	const getTitle = () => {
		if (state === "onHold") {
			return "On Hold Reason";
		} else if (state === "rejected") {
			return "Rejected Reason";
		} else if (state === "completed") {
			return "Completed Info";
		} else {
			return "";
		}
	};

	const getDescription = () => {
		if (state === "onHold") {
			return "Please provide reasons on why this complaint is being put on hold.";
		} else if (state === "rejected") {
			return "Please provide reasons on why this complaint is being rejected.";
		} else if (state === "completed") {
			return "Please enter any information relevant to the complaint completion";
		} else {
			return "";
		}
	};

	return (
		<Dialog aria-modal open={open} onClose={handleOnClose}>
			<DialogTitle>{getTitle()}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<DialogContent>
					<DialogContentText>
						<Typography textAlign={"center"}>{getDescription()}</Typography>
						<Controller
							name="reason"
							control={control}
							rules={
								state !== "completed"
									? {
											required: "Reason is required",
									  }
									: undefined
							}
							render={({ field: { ref, ...rest }, fieldState: { error } }) => (
								<TextField
									{...rest}
									sx={{
										marginTop: "20px",
									}}
									disabled={loading}
									multiline
									minRows={3}
									maxRows={5}
									fullWidth
									required={state !== "completed"}
									label={state !== "completed" ? "Reason" : "Info"}
									error={error ? true : false}
									helperText={error ? error.message : ""}
								/>
							)}
						/>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						disabled={loading}
						loading={loading}
						loadingPosition="start"
						startIcon={<FontAwesomeIcon icon={faCheck} />}
						type="submit"
					>
						Submit
					</LoadingButton>
					<Button disabled={loading} startIcon={<FontAwesomeIcon icon={faXmark} />} onClick={handleOnClose}>
						Cancel
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
