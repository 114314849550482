import { Box, Grid2, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import useContact from "../../../../hooks/useContact";
import { renderHeading } from "../../../../utils/helpers";
import BackdropLoader from "../../BackdropLoader";
import BackgroundPaper from "../../BackgroundPaper";

export default function ContactView() {
	const [searchParams] = useSearchParams();
	const { contact, loading } = useContact(searchParams.get("id"));

	return (
		<>
			<BackdropLoader open={loading} />
			<BackgroundPaper>
				<Box>
					<Typography variant="h3">{renderHeading("View", "Contact")}</Typography>
				</Box>
				{contact && (
					<>
						<Grid2 container spacing={2} mt={"5px"} mb={"10px"}>
							<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Name:</Typography>
									<Typography variant="body1" ml={1}>
										{contact.name}
									</Typography>
								</Box>
							</Grid2>
							<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Email:</Typography>
									<Typography variant="body1" ml={1}>
										{contact.email}
									</Typography>
								</Box>
							</Grid2>
						</Grid2>
						<Grid2 container spacing={2}>
							<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Subject:</Typography>
									<Typography variant="body1" ml={1}>
										{contact.subject}
									</Typography>
								</Box>
							</Grid2>
							<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Message:</Typography>
									<Typography variant="body1" ml={1}>
										{contact.message}
									</Typography>
								</Box>
							</Grid2>
						</Grid2>
					</>
				)}
			</BackgroundPaper>
		</>
	);
}
