export const primaryColor = "#3119AA";
export const secondaryColor = "#343034";
export const tertiaryColor = "#EEEEFF";
export const accentColor = "#E3170A";
export const lightColor = "#EDEDED";
export const darkColor = "#05203D";
export const fabButtonColor1 = "#5C6BC0";
export const fabButtonColor2 = "#8E24AA";
export const fabHoverButtonColor1 = "#7986CB";
export const fabHoverButtonColor2 = "#D500F9";
export const fabPopupBackgroundColor = "#0B093B";
export const fabPopupHeadingColor = "#F2B300";
