import {
	Autocomplete,
	Box,
	Button,
	Grid2,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
	CNIC_REGEX,
	EMAIL_REGEX,
	LOWERCASE_CHARACTER_REGEX,
	NUMBER_CHARACTER_REGEX,
	SPECIAL_CHARACTER_REGEX,
	UPPERCASE_CHARACTER_REGEX,
} from "../../../../constants/helperConstants";
import useSupervisor from "../../../../hooks/useSupervisor";
import {
	capitalizeFirstLetter,
	formatPhoneNumber,
	renderHeading,
	SupervisorDefaultPermissions,
} from "../../../../utils/helpers";
import {
	AddSupervisorPayloadInterface,
	Client,
	EditSupervisorPayloadInterface,
	IssueCategory,
	Project,
	UserPermissionsType,
} from "../../../../utils/types";
import PasswordTextField from "../../../PasswordTextField";
import BackdropLoader from "../../BackdropLoader";
import BackgroundPaper from "../../BackgroundPaper";
import CnicComponent from "../../CnicComponent";
import useClients from "../../../../hooks/useClients";
import PhoneNumberTextField from "../../PhoneNumberTextField";
import { isValidPhoneNumber } from "libphonenumber-js/max";
import useIssueCategories from "../../../../hooks/useIssueCategories";
import PermissionSelector from "../../PermissionSelector";

interface AddSupervisorFieldsProps {
	type: "Add";
	apiCall: (payload: AddSupervisorPayloadInterface) => Promise<void>;
}
interface EditSupervisorFieldsProps {
	type: "Edit";
	apiCall: (payload: EditSupervisorPayloadInterface) => Promise<void>;
}
interface ViewSupervisorFieldsProps {
	type: "View";
	apiCall?: never;
}

type SupervisorFieldsProps = AddSupervisorFieldsProps | EditSupervisorFieldsProps | ViewSupervisorFieldsProps;

interface SupervisorFields {
	firstName: string;
	lastName: string;
	email: string;
	cnic: string;
	info: string;
	password: string;
	phoneNumber: string;
	client: Client | null;
	projects: Array<Project>;
	issueCategories: Array<IssueCategory>;
}

export default function SupervisorFields({ type, apiCall }: SupervisorFieldsProps) {
	const [searchParams] = useSearchParams();
	const { supervisor, loading } = useSupervisor(searchParams.get("id")); // Only runs in Edit and View case
	const { clients, loading: clientLoading } = useClients();
	const { issueCategories, loading: issueCategoriesLoading } = useIssueCategories();
	const [componentLoading, setComponentLoading] = useState(false);
	const [permissions, setPermissions] = useState<UserPermissionsType>(SupervisorDefaultPermissions);

	const { control, handleSubmit, reset } = useForm<SupervisorFields>({
		mode: "onTouched",
		defaultValues: {
			firstName: "",
			lastName: "",
			email: "",
			cnic: "",
			info: "",
			password: "",
			phoneNumber: "",
			client: null,
			projects: [],
			issueCategories: [],
		},
	});

	const selectedClient = useWatch({
		name: "client",
		control: control,
	});

	useEffect(() => {
		if (supervisor && clients.length) {
			reset({
				firstName: supervisor.firstName,
				lastName: supervisor.lastName,
				email: supervisor.email,
				cnic: supervisor.cnic,
				info: supervisor.info ? supervisor.info : "",
				password: "",
				phoneNumber: supervisor.phoneNumber,
				client: clients.find((item) => item.id === supervisor.clientId) || null,
				projects: supervisor.projects,
				issueCategories: issueCategories.filter((item) => supervisor.issueCategoryIds.includes(item.id)),
			});
			// DISABLE PERMISSIONS
			setPermissions(supervisor.permissions);
		}
	}, [supervisor, clients]);

	const onSubmit: SubmitHandler<SupervisorFields> = async (data) => {
		const id = searchParams.get("id");
		if (apiCall && data.client) {
			setComponentLoading(true);
			if (type === "Add") {
				const payload: AddSupervisorPayloadInterface = {
					firstName: capitalizeFirstLetter(data.firstName),
					lastName: capitalizeFirstLetter(data.lastName),
					email: data.email,
					cnic: data.cnic,
					role: "SUPERVISOR",
					info: data.info ? data.info : null,
					password: data.password,
					phoneNumber: formatPhoneNumber(data.phoneNumber),
					clientId: data.client.id,
					projects: data.projects,
					issueCategoryIds: data.issueCategories.map((item) => item.id),
					permissions: permissions,
				};

				await apiCall(payload).finally(() => {
					setComponentLoading(false);
				});
			} else if (type === "Edit" && id) {
				const payload: EditSupervisorPayloadInterface = {
					id: id,
					firstName: capitalizeFirstLetter(data.firstName),
					lastName: capitalizeFirstLetter(data.lastName),
					email: data.email,
					cnic: data.cnic,
					role: "SUPERVISOR",
					info: data.info ? data.info : null,
					phoneNumber: formatPhoneNumber(data.phoneNumber),
					clientId: data.client.id,
					projects: data.projects,
					issueCategoryIds: data.issueCategories.map((item) => item.id),
					permissions: permissions,
				};

				await apiCall(payload).finally(() => {
					setComponentLoading(false);
				});
			}
		}
	};

	return (
		<>
			<BackdropLoader open={loading || componentLoading} />
			<BackgroundPaper>
				<Box>
					<Typography fontSize={25} fontWeight={600}>
						{renderHeading(type, "Supervisor")}
					</Typography>
				</Box>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="firstName"
								control={control}
								rules={{
									required: "First Name is required",
								}}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										disabled={type === "View"}
										fullWidth
										required
										label="First Name"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="lastName"
								control={control}
								rules={{
									required: "Last Name is required",
								}}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										disabled={type === "View"}
										fullWidth
										required
										label="Last Name"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="cnic"
								control={control}
								rules={{
									required: "CNIC is required",
									pattern: {
										value: CNIC_REGEX,
										message: "Invalid CNIC",
									},
								}}
								render={({ field: { ref, ...rest }, fieldState: { error } }) => (
									<CnicComponent
										{...rest}
										disabled={type === "View"}
										fullWidth
										required
										label="CNIC"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
					</Grid2>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="email"
								control={control}
								rules={{
									required: "Email is required",
									pattern: {
										value: EMAIL_REGEX,
										message: "Incorrect Email",
									},
								}}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										disabled={type === "View" || type === "Edit"}
										fullWidth
										required
										label="Email"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="phoneNumber"
								control={control}
								rules={{
									required: "Phone Number is required",
									validate: {
										isPhoneValid: (value) => {
											if (isValidPhoneNumber(value, "PK") === false) {
												return "Phone Number is invalid";
											}
										},
									},
								}}
								render={({ field: { ref, ...rest }, fieldState: { error } }) => (
									<PhoneNumberTextField
										{...rest}
										disabled={type === "View"}
										fullWidth
										required
										label="Phone Number"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
						{type === "Add" && (
							<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
								<Controller
									name="password"
									control={control}
									rules={{
										required: "Password is required",
										minLength: {
											value: 8,
											message: "Password must be at least 8 characters",
										},
										validate: {
											mustContainOneUppercaseCharacter: (value) => {
												if (UPPERCASE_CHARACTER_REGEX.test(value) === false) {
													return "Password must contain at least one uppercase character";
												}
											},
											mustContainOneLowercaseCharacter: (value) => {
												if (LOWERCASE_CHARACTER_REGEX.test(value) === false) {
													return "Password must contain at least one lowercase character";
												}
											},
											mustContainOneNumber: (value) => {
												if (NUMBER_CHARACTER_REGEX.test(value) === false) {
													return "Password must contain at least one number";
												}
											},
											mustContainOneSpecialCharacter: (value) => {
												if (SPECIAL_CHARACTER_REGEX.test(value) === false) {
													return "Password must contain at least one special character";
												}
											},
										},
									}}
									render={({ field: { ref, ...rest }, fieldState: { error } }) => (
										<PasswordTextField
											{...rest}
											fullWidth
											required
											error={error ? true : false}
											helperText={error ? error.message : ""}
										/>
									)}
								/>
							</Grid2>
						)}
					</Grid2>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="client"
								control={control}
								rules={{
									required: "Client is required",
								}}
								render={({ field: { ref, value, onChange, ...rest }, fieldState: { error } }) => {
									return (
										<Autocomplete
											disablePortal
											disabled={clientLoading || type === "View"}
											options={clients}
											value={value}
											onChange={(e, value) => {
												onChange(value);
											}}
											isOptionEqualToValue={(option, value) => {
												return option.id === value.id;
											}}
											getOptionLabel={(value) => value.name || ""}
											renderInput={(params) => (
												<TextField
													{...params}
													{...rest}
													label="Client"
													required
													error={error ? true : false}
													helperText={error ? error.message : ""}
												/>
											)}
										/>
									);
								}}
							/>
						</Grid2>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="projects"
								control={control}
								render={({ field: { ref, value, onChange, ...rest }, fieldState: { error } }) => (
									<Autocomplete
										disablePortal
										multiple
										disabled={selectedClient === null || type === "View"}
										options={selectedClient ? selectedClient.projects : []}
										value={value}
										onChange={(e, value) => {
											onChange(value);
										}}
										isOptionEqualToValue={(option, value) => {
											return option.id === value.id;
										}}
										getOptionLabel={(value) => value.name || ""}
										renderInput={(params) => (
											<TextField
												{...params}
												{...rest}
												disabled={selectedClient === null || type === "View"}
												label="Projects"
												error={error ? true : false}
												helperText={error ? error.message : ""}
											/>
										)}
									/>
								)}
							/>
						</Grid2>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="issueCategories"
								control={control}
								rules={{
									required: "Issue Categories are required",
								}}
								render={({ field: { ref, value, onChange, ...rest }, fieldState: { error } }) => (
									<Autocomplete
										disablePortal
										multiple
										disabled={issueCategoriesLoading || type === "View"}
										options={issueCategories}
										value={value}
										onChange={(e, value) => {
											onChange(value);
										}}
										isOptionEqualToValue={(option, value) => {
											return option.id === value.id;
										}}
										getOptionLabel={(value) => value.name || ""}
										renderInput={(params) => (
											<TextField
												{...params}
												{...rest}
												disabled={issueCategoriesLoading || type === "View"}
												label="Issue Categories"
												required
												error={error ? true : false}
												helperText={error ? error.message : ""}
											/>
										)}
									/>
								)}
							/>
						</Grid2>
					</Grid2>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="info"
								control={control}
								render={({ field: { ref, ...rest }, fieldState: { error } }) => (
									<TextField
										{...rest}
										disabled={type === "View"}
										multiline
										minRows={3}
										maxRows={5}
										fullWidth
										label="Enter any relevant information"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
					</Grid2>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<PermissionSelector
								selectedPermissions={permissions}
								setSelectedPermissions={setPermissions}
								disableAdminPermissions
							/>
						</Grid2>
					</Grid2>
					{type !== "View" && (
						<Button
							variant="contained"
							type="submit"
							sx={{
								width: "10%",
								marginTop: "20px",
							}}
						>
							Save
						</Button>
					)}
				</form>
			</BackgroundPaper>
		</>
	);
}
