export const APP_NAME = "Numla Asia Web App";
export const TITLE = "Numla Asia";
export const LOGIN = "Login";
export const LOGIN_ = "login";
export const FORGOT_PASSWORD = "Forgot Password";
export const FORGOT_PASSWORD_ = "forgot-password";
export const LOGOUT = "Logout";
export const DASHBOARD = "Dashboard";
export const DASHBOARD_ = "dashboard";
export const ADMINS = "Admins";
export const ADMINS_ = "admins";
export const SUPERVISORS = "Supervisors";
export const SUPERVISORS_ = "supervisors";
export const CLIENTS = "Clients";
export const CLIENTS_ = "clients";
export const ISSUE_CATEGORIES = "Issue Categories";
export const ISSUE_CATEGORIES_ = "issue-categories";
export const CONTACTS = "Contacts";
export const CONTACTS_ = "contacts";
export const COMPLAINT = "Complaint";
export const COMPLAINT_ = "complaint";
export const COMPLAINTS = "Complaints";
export const COMPLAINTS_ = "complaints";
export const INCOMING_COMPLAINTS = "Incoming Complaints";
export const ACCEPTED_COMPLAINTS = "Accepted Complaints";
export const IN_PROGRESS_COMPLAINTS = "In Progress Complaints";
export const ON_HOLD_COMPLAINTS = "On Hold Complaints";
export const COMPLETED_COMPLAINTS = "Completed Complaints";
export const REJECTED_COMPLAINTS = "Rejected Complaints";
export const INVENTORY = "Inventory";
export const INVENTORY_ = "inventory";
