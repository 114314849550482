import { Box, Button, Chip, Stack, styled } from "@mui/material";
import { errorHandlingOnFileSelection } from "../utils/helpers";

interface FileUploadButtonProps {
	value: Array<File>;
	onChange: (...event: any[]) => void;
	label?: string;
}

const CustomChip = styled(Chip)(({ theme }) => ({
	padding: theme.spacing(1),
	height: "100%",
	display: "flex",
	flexDirection: "row",
	maxWidth: "200px",
	"& .MuiChip-label": { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
}));

export default function FileUploadButton({ value, onChange, label = "Upload File" }: FileUploadButtonProps) {
	const handleFilesChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		files: Array<File>,
		onChange: (...event: any[]) => void,
	) => {
		e.preventDefault();

		const allowedFiles = errorHandlingOnFileSelection(e.target.files, files);

		if (allowedFiles.length) {
			onChange([...value, ...allowedFiles]);
		}
	};

	const handleFileDelete = (file: File) => {
		const filteredFiles = value.filter((item) => item.name !== file.name);
		onChange(filteredFiles);
	};

	return (
		<Box gap={1} className="file-upload-container">
			<Button className="file-upload-button" variant="text" component="label">
				{label}
				<input
					type="file"
					hidden
					multiple
					accept=".jpg,.png,.jpeg"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleFilesChange(e, value, onChange);
					}}
					onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
						// This is to let user select the same files again.
						// If we do not do this the onChange does not fire when user selects the same files
						e.currentTarget.value = "";
					}}
				/>
			</Button>
			{value.length ? (
				<Stack mt={1} direction="row" spacing={1}>
					{value.map((file, index) => (
						<CustomChip
							key={index}
							label={file.name}
							onDelete={() => {
								handleFileDelete(file);
							}}
						/>
					))}
				</Stack>
			) : (
				<></>
			)}
		</Box>
	);
}
