import { Box } from "@mui/material";
import AdminTable from "../../../components/authedComponents/crudComponents/Admin/AdminTable";

export default function Admins() {
	return (
		<Box
			sx={{
				height: "100%",
			}}
		>
			<AdminTable />
		</Box>
	);
}
