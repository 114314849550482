export default function DotsLoader({ color }: { color: string }) {
	return (
		<div className='scaling-dots'>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
		</div>
	);
}
