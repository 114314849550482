import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import useIsMobile from "../../../hooks/useIsMobile";

interface ComplaintButtonProps extends LoadingButtonProps {
	text: string;
	onClick: () => void | Promise<void>;
}

export default function ComplaintButton({ text, loading, onClick, ...props }: ComplaintButtonProps) {
	const isMobile = useIsMobile();
	return (
		<LoadingButton
			{...props}
			loading={loading}
			disabled={loading}
			onClick={onClick}
			variant="contained"
			sx={{
				width: isMobile ? "65%" : "25%",
				height: "40px",
				marginTop: "30px",
				...(isMobile && { padding: "20px 0px 20px 0px" }),
			}}
		>
			{text}
		</LoadingButton>
	);
}
