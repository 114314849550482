import {
	faAddressCard,
	faBoxesStacked,
	faFileContract,
	faHandshake,
	faSquarePollHorizontal,
	faUser,
	faUsers,
	faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Button, Menu, MenuItem } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { signOut } from "firebase/auth";
import { useSnackbar } from "notistack";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import { primaryColor, secondaryColor } from "../constants/colorConstants";
import {
	ADMINS,
	ADMINS_,
	CLIENTS,
	CLIENTS_,
	COMPLAINTS,
	COMPLAINTS_,
	CONTACTS,
	CONTACTS_,
	DASHBOARD,
	DASHBOARD_,
	INVENTORY,
	INVENTORY_,
	ISSUE_CATEGORIES,
	ISSUE_CATEGORIES_,
	LOGOUT,
	SUPERVISORS,
	SUPERVISORS_,
} from "../constants/textConstants";
import { auth } from "../firebase/config";
import { toggleSideBar } from "../redux/misc";
import { RootState } from "../redux/store";
import { theme } from "../theme/theme";
import { getInitials } from "../utils/helpers";
import usePermissions from "../hooks/usePermissions";

const drawerWidth = 240;

interface Props {
	window?: () => Window;
	children: React.ReactNode;
}

export default function AuthedLayout(props: Props) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { allowedRoutes } = usePermissions();
	const { sideBarOpenStatus } = useSelector((state: RootState) => state.misc);
	const { firstName, lastName } = useSelector((state: RootState) => state.user);
	const { window } = props;
	const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
	const openMenu = Boolean(menuAnchor);
	const { enqueueSnackbar } = useSnackbar();

	const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchor(event.currentTarget);
	};

	const handleMenuClose = () => {
		setMenuAnchor(null);
	};

	const container = window !== undefined ? () => window().document.body : undefined;

	const handleSideBarToggle = () => {
		dispatch(toggleSideBar());
	};

	const allSideBarListing = [
		{
			name: DASHBOARD,
			route: `/${DASHBOARD_}`,
			Icon: () => <FontAwesomeIcon color={primaryColor} icon={faSquarePollHorizontal} />,
			break: false,
			hide: true,
		},
		{
			name: ADMINS,
			route: `/${ADMINS_}`,
			Icon: () => <FontAwesomeIcon color={primaryColor} icon={faUser} />,
			break: false,
			hide: false,
		},
		{
			name: SUPERVISORS,
			route: `/${SUPERVISORS_}`,
			Icon: () => <FontAwesomeIcon color={primaryColor} icon={faUsers} />,
			break: false,
			hide: false,
		},
		{
			name: CLIENTS,
			route: `/${CLIENTS_}`,
			Icon: () => <FontAwesomeIcon color={primaryColor} icon={faHandshake} />,
			break: true,
			hide: false,
		},
		{
			name: ISSUE_CATEGORIES,
			route: `/${ISSUE_CATEGORIES_}`,
			Icon: () => <FontAwesomeIcon color={primaryColor} icon={faWrench} />,
			break: false,
			hide: false,
		},
		{
			name: CONTACTS,
			route: `/${CONTACTS_}`,
			Icon: () => <FontAwesomeIcon color={primaryColor} icon={faAddressCard} />,
			break: true,
			hide: false,
		},
		{
			name: COMPLAINTS,
			route: `/${COMPLAINTS_}`,
			Icon: () => <FontAwesomeIcon color={primaryColor} icon={faFileContract} />,
			break: false,
			hide: false,
		},
		{
			name: INVENTORY,
			route: `/${INVENTORY_}`,
			Icon: () => <FontAwesomeIcon color={primaryColor} icon={faBoxesStacked} />,
			break: true,
			hide: false,
		},
	];

	const getRoleSpecificSideBarListing = () => {
		const listing = allSideBarListing.filter((item) => item.hide === false);
		return listing.filter((listItem) => allowedRoutes.includes(listItem.route.substring(1)));
		// DISABLE PERMISSIONS
		// return listing;
	};

	const drawer = (
		<Box>
			<Toolbar>
				{/* <LogoIcon /> */}
				<img
					src={Logo}
					style={{
						width: "184px",
						height: "46px",
					}}
				/>
				{/* <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
					{TITLE}
				</h1> */}
			</Toolbar>
			<Divider />
			<List sx={{ mt: 2 }}>
				{getRoleSpecificSideBarListing().map((item, index) => (
					<React.Fragment key={index}>
						{item.break && <Divider variant="middle" />}
						<ListItem disablePadding>
							<ListItemButton
								onClick={() => {
									if (item.route) {
										navigate(item.route);
										handleSideBarToggle();
									}
								}}
							>
								<ListItemIcon>{<item.Icon />}</ListItemIcon>
								<ListItemText
									primaryTypographyProps={{
										fontWeight: 600,
									}}
									primary={item.name}
								/>
							</ListItemButton>
						</ListItem>
					</React.Fragment>
				))}
			</List>
		</Box>
	);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />

			{/* Header */}
			<AppBar
				position="fixed"
				sx={{
					width: sideBarOpenStatus ? { sm: `calc(100% - ${drawerWidth}px)` } : "100%",
					transition: "width 0.2s ease-in-out",
				}}
			>
				<Toolbar sx={{ justifyContent: "space-between", backgroundColor: secondaryColor }}>
					<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<IconButton
							color="inherit"
							aria-label="Open Side bar"
							edge="start"
							onClick={handleSideBarToggle}
						>
							<MenuIcon />
						</IconButton>
						<Typography
							variant="h6"
							noWrap
							component="div"
							className="font20 extraBold"
							sx={{ textTransform: "capitalize" }}
							color={theme.palette.lightColor.main}
						>
							{`Hi ${firstName} ${lastName}!`}
						</Typography>
					</Box>
					<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						<Button onClick={handleAvatarClick}>
							<Avatar sx={{ bgcolor: primaryColor }}>{getInitials(`${firstName} ${lastName}`)}</Avatar>
						</Button>
						<Menu
							id="basic-menu"
							anchorEl={menuAnchor}
							open={openMenu}
							onClose={handleMenuClose}
							MenuListProps={{
								"aria-labelledby": "basic-button",
							}}
						>
							<MenuItem
								onClick={async () => {
									// Signing out user
									await signOut(auth);
									enqueueSnackbar("Logged out successfully.", { variant: "success" });
								}}
							>
								{LOGOUT}
							</MenuItem>
						</Menu>
					</Box>
				</Toolbar>
			</AppBar>

			{/* Children */}
			<Box
				component="main"
				position="fixed"
				sx={{
					flexShrink: 0,
					left: "auto",
					right: 0,
					p: 3,
					width: sideBarOpenStatus ? { sm: `calc(100% - ${drawerWidth}px)` } : "100%",
					transition: "width 0.2s ease-in-out",
					height: "100vh",
				}}
			>
				<Toolbar />
				{props.children}
			</Box>

			{/* SideBar */}
			<Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="Side bar">
				{/* Mobile Sidebar */}
				<Drawer
					container={container}
					variant="temporary"
					open={sideBarOpenStatus}
					onClose={handleSideBarToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
					}}
				>
					{drawer}
				</Drawer>
				{/* Desktop Sidebar */}
				<Drawer
					variant="temporary"
					open={sideBarOpenStatus}
					onClose={handleSideBarToggle}
					sx={{
						display: { xs: "none", sm: "block" },
						"& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
					}}
				>
					{drawer}
				</Drawer>
			</Box>
		</Box>
	);
}
