import { type MRT_ColumnDef as MRTColumnDef } from "material-react-table";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { SUPERVISORS, SUPERVISORS_ } from "../../../../constants/textConstants";
import { deleteUser } from "../../../../firebase/config";
import useSupervisors from "../../../../hooks/useSupervisors";
import { genericErrorFunction, getClientName, truncate } from "../../../../utils/helpers";
import { Client, Supervisor } from "../../../../utils/types";
import ConfirmationModal from "../../ConfirmationModal";
import Table from "../../Table";
import usePermissions from "../../../../hooks/usePermissions";
import useClients from "../../../../hooks/useClients";

export default function SupervisorTable() {
	const navigate = useNavigate();
	const { getPermission } = usePermissions();
	const { supervisors, loading, refetch } = useSupervisors();
	const { clients, loading: clientsLoading } = useClients();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [selectedSupervisor, setSelectedSupervisor] = useState<Supervisor | null>(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const deleteSupervisor = async () => {
		if (selectedSupervisor) {
			try {
				setDeleteLoading(true);
				await deleteUser({ id: selectedSupervisor.id })
					.then((response) => {
						if (response.data.status === "success") {
							enqueueSnackbar("Supervisor Deleted!", { variant: "success" });
						}
					})
					.finally(() => {
						setDeleteLoading(false);
						setOpenDeleteModal(false);
						setSelectedSupervisor(null);
						refetch(true);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while deleting supervisor: ", error });
			}
		}
	};

	const supervisorColumns: Array<MRTColumnDef<Supervisor>> = [
		{
			accessorKey: "firstName",
			header: "First Name",
			size: 200,
		},
		{
			accessorKey: "lastName",
			header: "Last Name",
			size: 200,
		},
		{
			accessorKey: "email",
			header: "Email",
			size: 200,
		},
		{
			accessorKey: "cnic",
			header: "CNIC",
			size: 200,
		},
		{
			accessorKey: "clientId",
			header: "Client",
			Cell: (row) => <span>{getClientName(clients, row.row.original.clientId)}</span>,
			size: 200,
		},
	];

	return (
		<>
			<Table
				columns={supervisorColumns}
				data={supervisors}
				title={SUPERVISORS}
				isLoading={loading || clientsLoading}
				addButtonOnClick={
					getPermission(SUPERVISORS_, "supervisor_add")
						? () => {
								navigate(`/${SUPERVISORS_}/add`);
						  }
						: undefined
				}
				editButtonOnClick={
					getPermission(SUPERVISORS_, "supervisor_edit")
						? (row) => {
								navigate({
									pathname: `/${SUPERVISORS_}/edit`,
									search: createSearchParams({
										id: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
				deleteButtonOnClick={
					getPermission(SUPERVISORS_, "supervisor_delete")
						? (row) => {
								setOpenDeleteModal(true);
								setSelectedSupervisor(row.original);
						  }
						: undefined
				}
				onRowClick={
					getPermission(SUPERVISORS_, "supervisor_view")
						? (event, row) => {
								navigate({
									pathname: `/${SUPERVISORS_}/view`,
									search: createSearchParams({
										id: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
			/>
			<ConfirmationModal
				open={openDeleteModal}
				onClose={() => {
					setOpenDeleteModal(false);
					setSelectedSupervisor(null);
				}}
				title="Delete Supervisor"
				description="Are you sure you want to delete the following supervisor. This action is irreversible."
				apiCall={deleteSupervisor}
				loading={deleteLoading}
			/>
		</>
	);
}
