import { Box, Paper } from "@mui/material";
import useIsMobile from "../../hooks/useIsMobile";
import { useState } from "react";
import ViewInitialScreen from "../../components/unAuthedComponents/Complaints/ViewInitialScreen";
import ViewScreen from "../../components/unAuthedComponents/Complaints/ViewScreen";
import { useForm, useWatch } from "react-hook-form";

export interface InitialScreenFields {
	phoneNumber: string;
}

export default function ListComplaints() {
	const isMobile = useIsMobile();
	const [screen, setScreen] = useState<"initial" | "view">("initial");

	const { control, trigger } = useForm<InitialScreenFields>({
		mode: "onTouched",
		defaultValues: {
			phoneNumber: "",
		},
	});

	const phoneNumber = useWatch({
		name: "phoneNumber",
		control: control,
	});

	const renderScreen = () => {
		if (screen === "initial") {
			return <ViewInitialScreen control={control} onViewClick={onViewClick} />;
		} else if (screen === "view") {
			return <ViewScreen phoneNumber={phoneNumber} />;
		} else {
			return <></>;
		}
	};

	const onViewClick = async () => {
		const isPhoneNumberClear = await trigger("phoneNumber");
		if (isPhoneNumberClear) {
			setScreen("view");
		}
	};

	return (
		<Box className="unauth-background">
			<Paper elevation={20} className="unauth-paper">
				<Box
					sx={{
						height: "100%",
						display: "flex",
						position: "relative",
					}}
				>
					{!isMobile && <Box className={"complaint-gradient complaint-gradient-animation"}></Box>}
					<Box className="complaint-center">{renderScreen()}</Box>
				</Box>
			</Paper>
		</Box>
	);
}
