import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { Admin, ResponseType, Supervisor } from "../utils/types";

const firebaseConfig = {
	apiKey: "AIzaSyCDwIeX29cABLkzQiMEsN4vxJBh9MrMSvc",
	authDomain: "numla-asia.firebaseapp.com",
	projectId: "numla-asia",
	storageBucket: "numla-asia.appspot.com",
	messagingSenderId: "409305522192",
	appId: "1:409305522192:web:83ecb74e84aee14b14aa95",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const addUser = httpsCallable<Omit<Admin, "id"> | Omit<Supervisor, "id">, ResponseType>(functions, "addUser");
export const deleteUser = httpsCallable<{ id: string }, ResponseType>(functions, "deleteUser");

if (window.location.hostname === "localhost") {
	connectFirestoreEmulator(firestore, "127.0.0.1", 8080);
	connectAuthEmulator(auth, "http://127.0.0.1:9099", { disableWarnings: true });
	connectFunctionsEmulator(functions, "127.0.0.1", 5001);
	connectStorageEmulator(storage, "127.0.0.1", 9199);
} else if (window.location.hostname === "192.168.100.53") {
	connectFirestoreEmulator(firestore, "192.168.100.53", 8080);
	connectAuthEmulator(auth, "http://192.168.100.53:9099", { disableWarnings: true });
	connectFunctionsEmulator(functions, "192.168.100.53", 5001);
	connectStorageEmulator(storage, "192.168.100.53", 9199);
}
