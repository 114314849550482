import { doc, getDoc } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../firebase/config";
import { addMissingSectionsToPreSelectedPermissions, genericErrorFunction } from "../utils/helpers";
import { Admin } from "../utils/types";
import { USERS_COLLECTION } from "../constants/firebaseConstants";

export default function useAdmin(id: string | null) {
	const navigate = useNavigate();
	const [admin, setAdmin] = useState<Admin | null>(null);
	const [loading, setLoading] = useState(false);

	const getAdmin = async () => {
		if (id) {
			try {
				setLoading(true);
				const adminQuery = doc(firestore, USERS_COLLECTION, id);

				await getDoc(adminQuery)
					.then((doc) => {
						if (doc.exists()) {
							const data = doc.data();
							const admin: Admin = {
								id: doc.id,
								firstName: data.firstName,
								lastName: data.lastName,
								email: data.email,
								cnic: data.cnic,
								role: data.role,
								info: data.info ? data.info : null,
								permissions: addMissingSectionsToPreSelectedPermissions(data.permissions),
							};
							setAdmin(admin);
						} else {
							enqueueSnackbar("Admin does not exist", { variant: "error" });
							navigate(-1);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching admin: ", error });
			}
		}
	};

	useEffect(() => {
		getAdmin();
	}, [id]);

	return { admin, loading };
}
