import { IconButton } from "@mui/material";
import { useSnackbar, SnackbarKey } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

export default function SnackbarCloseButton({ snackbarKey }: { snackbarKey: SnackbarKey }) {
	const { closeSnackbar } = useSnackbar();

	return (
		<IconButton onClick={() => closeSnackbar(snackbarKey)}>
			<CloseIcon sx={{ color: "#FFFFFF" }} />
		</IconButton>
	);
}
