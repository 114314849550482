export default function SquareLoader({ color }: { color: string }) {
	return (
		<div className='flip-to-square'>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
			<div style={{ backgroundColor: color }}></div>
		</div>
	);
}
