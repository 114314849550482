import { createTheme } from "@mui/material/styles";
import { darkColor, lightColor, primaryColor, secondaryColor, tertiaryColor } from "../constants/colorConstants";

declare module "@mui/material/styles/createPalette" {
	interface Palette {
		tertiary: Palette["primary"];
		lightColor: Palette["primary"];
		darkColor: Palette["primary"];
	}
	interface PaletteOptions {
		tertiary: PaletteOptions["primary"];
		lightColor: PaletteOptions["primary"];
		darkColor: PaletteOptions["primary"];
	}
}

const { palette } = createTheme();
export const theme = createTheme({
	// light, dark and contrastText are calculated automatically
	palette: {
		primary: {
			main: primaryColor,
		},
		secondary: {
			main: secondaryColor,
		},
		tertiary: palette.augmentColor({
			color: {
				main: tertiaryColor,
			},
		}),
		lightColor: palette.augmentColor({
			color: {
				main: lightColor,
			},
		}),
		darkColor: palette.augmentColor({
			color: {
				main: darkColor,
			},
		}),
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	typography: {
		fontFamily: ["Space Grotesk", "Raleway", "sans-serif"].join(","),
	},
	components: {
		MuiTextField: {
			styleOverrides: {
				root: {
					"& .MuiOutlinedInput-root": {
						background: "#FFFFFF",
						"&:not(.Mui-disabled):hover fieldset": {
							borderColor: primaryColor,
						},
					},
					"& .MuiInputLabel-outlined": {
						color: darkColor,
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: 15,
					"&:hover fieldset": {
						borderColor: "yellow",
					},
				},
				input: {
					"&:-webkit-autofill": {
						WebkitBoxShadow: "0 0 0 100px #FFFFFF inset",
						WebkitTextFillColor: darkColor,
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 15,
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					color: darkColor,
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					color: lightColor,
					backgroundColor: darkColor,
					borderRadius: "0px 0px 5px 5px",
				},
			},
		},
		MuiDialogContentText: {
			styleOverrides: {
				root: {
					paddingTop: "15px",
					color: darkColor,
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				root: {
					"& .MuiDialog-container": {
						"& .MuiPaper-root": {
							width: "100%",
							maxWidth: "300px",
						},
					},
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					backgroundColor: lightColor,
				},
			},
		},
	},
});
