import { Box } from "@mui/material";
import IssueCategoryTable from "../../../components/authedComponents/crudComponents/IssueCategory/IssueCategoryTable";

export default function IssueCategories() {
	return (
		<Box
			sx={{
				height: "100%",
			}}
		>
			<IssueCategoryTable />
		</Box>
	);
}
