import { Box, Divider, Grid2, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import BackdropLoader from "../../../components/authedComponents/BackdropLoader";
import BackgroundPaper from "../../../components/authedComponents/BackgroundPaper";
import useInventoryItem from "../../../hooks/useInventoryItem";
import { theme } from "../../../theme/theme";
import { convertTimestampToDate, genericErrorFunction } from "../../../utils/helpers";
import dayjs, { Dayjs } from "dayjs";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import CustomDatePicker from "../../../components/authedComponents/CustomDatePicker";
import { LoadingButton } from "@mui/lab";
import * as React from "react";
import useClient from "../../../hooks/useClient";

interface ViewItemForm {
	fromDate: Dayjs | null;
	toDate: Dayjs | null;
}

export default function ViewInventory() {
	const [searchParams] = useSearchParams();
	const { inventoryItem, getInventoryItem, loading } = useInventoryItem(searchParams.get("id"));
	const { client, loading: clientLoading } = useClient(inventoryItem ? inventoryItem.clientId : "");

	const { control, handleSubmit } = useForm<ViewItemForm>({
		mode: "onTouched",
		defaultValues: {
			fromDate: dayjs().subtract(7, "day"),
			toDate: dayjs(),
		},
	});

	const fromDate = useWatch({
		control: control,
		name: "fromDate",
	});

	const toDate = useWatch({
		control: control,
		name: "toDate",
	});

	const onSubmit: SubmitHandler<ViewItemForm> = async (data) => {
		if (data.fromDate !== null && data.toDate !== null) {
			try {
				getInventoryItem(data.fromDate, data.toDate);
			} catch (error) {
				genericErrorFunction({
					logMessage: "Error occurred while fetching logs for view: ",
					error,
					snackbarMessage: "Could not fetch logs!",
				});
			}
		}
	};

	return (
		<>
			<BackdropLoader open={loading || clientLoading} />
			<BackgroundPaper>
				<Box>
					<Typography variant="h3">Item Information:</Typography>
				</Box>
				{inventoryItem && client && (
					<>
						<Grid2 container spacing={2} mt={"5px"} mb={"10px"}>
							<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Name:</Typography>
									<Typography variant="body1" ml={1}>
										{inventoryItem.name}
									</Typography>
								</Box>
							</Grid2>
							<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Quantity:</Typography>
									<Typography variant="body1" ml={1}>
										{inventoryItem.quantity}
									</Typography>
								</Box>
							</Grid2>
							<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
								<Box className="align-items-center">
									<Typography variant="h6">Client:</Typography>
									<Typography variant="body1" ml={1}>
										{client.name}
									</Typography>
								</Box>
							</Grid2>
						</Grid2>
						{inventoryItem.description && (
							<Grid2 container spacing={2}>
								<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
									<Box className="align-items-center">
										<Typography variant="h6">Description:</Typography>
										<Typography className="wrap-text" variant="body1" ml={1}>
											{inventoryItem.description}
										</Typography>
									</Box>
								</Grid2>
							</Grid2>
						)}

						<Grid2 container mt={3} spacing={2}>
							<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
								<Box>
									<Typography variant="h4" mb={2}>
										Logs:
									</Typography>
									<form onSubmit={handleSubmit(onSubmit)} noValidate>
										<Grid2 container spacing={2} mb={2}>
											<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
												<Controller
													name="fromDate"
													control={control}
													rules={{
														required: "From date is required",
														validate: {
															isAfterToDate: (value) => {
																if (dayjs(value).isAfter(toDate)) {
																	return "From Date cannot be after to date";
																}
															},
														},
													}}
													render={({ field: { ref, ...rest }, fieldState: { error } }) => (
														<CustomDatePicker
															{...rest}
															label="From Date"
															fullWidth
															required
															disableFuture
															maxDate={toDate}
															error={error ? true : false}
															helperText={error ? error.message : ""}
														/>
													)}
												/>
											</Grid2>
											<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
												<Controller
													name="toDate"
													control={control}
													rules={{
														required: "To date is required",
														validate: {
															isBeforeFromDate: (value) => {
																if (dayjs(value).isBefore(fromDate)) {
																	return "To date cannot be before from date";
																}
															},
															isAfterTodaysDate: (value) => {
																if (dayjs(value).isAfter(dayjs())) {
																	return "To date cannot be after todays date";
																}
															},
														},
													}}
													render={({ field: { ref, ...rest }, fieldState: { error } }) => (
														<CustomDatePicker
															{...rest}
															label="To Date"
															fullWidth
															required
															disableFuture
															minDate={fromDate}
															error={error ? true : false}
															helperText={error ? error.message : ""}
														/>
													)}
												/>
											</Grid2>
											<Grid2 size={{ xs: 12, sm: 12, md: 3, lg: 3, xl: 3 }}>
												<Box width={"100%"} height={"100%"} className="align-items-center">
													<LoadingButton loading={loading} type="submit" variant="contained">
														Filter
													</LoadingButton>
												</Box>
											</Grid2>
										</Grid2>
									</form>
									{inventoryItem.logs.length ? (
										<List sx={{ backgroundColor: "#d6d6d6", borderRadius: "20px" }}>
											{inventoryItem.logs.map((log, index) => (
												<React.Fragment key={index}>
													{index !== 0 && <Divider component="li" />}
													<ListItem alignItems="flex-start">
														<ListItemText
															primary={
																<Box
																	sx={{
																		display: "flex",
																		flexDirection: "column",
																		marginBottom: "10px",
																	}}
																>
																	<Typography variant="h6">
																		{log.createdByName}
																	</Typography>
																	<Typography
																		variant="subtitle2"
																		ml={1}
																		color={theme.palette.secondary.light}
																	>
																		{convertTimestampToDate(log.createdAt, true)}
																	</Typography>
																</Box>
															}
															secondary={
																<>
																	<Box>
																		<Typography
																			component="span"
																			variant="body1"
																			fontWeight={500}
																		>
																			From:
																		</Typography>
																		<Typography
																			component="span"
																			variant="body1"
																			fontWeight={400}
																			ml={1}
																		>
																			{log.quantityUpdatedFrom}
																		</Typography>
																		<Typography
																			component="span"
																			variant="body1"
																			fontWeight={500}
																			ml={3}
																		>
																			To:
																		</Typography>
																		<Typography
																			component="span"
																			variant="body1"
																			fontWeight={400}
																			ml={1}
																		>
																			{log.quantityUpdatedTo}
																		</Typography>
																	</Box>
																	{log.updateNote && (
																		<Box
																			sx={{
																				display: "flex",
																				flexDirection: "column",
																				marginTop: "10px",
																			}}
																		>
																			<Typography component="span" variant="h6">
																				Note:
																			</Typography>
																			<Typography
																				component="span"
																				ml={1}
																				variant="body1"
																				className="wrap-text"
																			>
																				{log.updateNote}
																			</Typography>
																		</Box>
																	)}
																</>
															}
														/>
													</ListItem>
												</React.Fragment>
											))}
										</List>
									) : (
										<List sx={{ backgroundColor: "#d6d6d6", borderRadius: "20px" }}>
											<ListItem alignItems="flex-start">
												<ListItemText primary={"No logs"} />
											</ListItem>
										</List>
									)}
								</Box>
							</Grid2>
						</Grid2>
					</>
				)}
			</BackgroundPaper>
		</>
	);
}
