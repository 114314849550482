import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton, Typography } from "@mui/material";
import {
	MaterialReactTable,
	MRT_TableOptions as MRTableOptions,
	MRT_Row as MRTRow,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	useMaterialReactTable,
	type MRT_RowData as MRTRowData,
} from "material-react-table";
import { useMemo } from "react";
import { theme } from "../../theme/theme";

export interface TableProps<TData extends MRTRowData> extends Omit<MRTableOptions<TData>, "state"> {
	title?: string;
	isLoading?: boolean;
	addButtonOnClick?: () => void;
	editButtonOnClick?: (row: MRTRow<TData>) => void;
	deleteButtonOnClick?: (row: MRTRow<TData>) => void;
	onRowClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: MRTRow<TData>) => void;
	customActionButtonOne?: (row: MRTRow<TData>) => JSX.Element;
	customActionButtonTwo?: (row: MRTRow<TData>) => JSX.Element;
	customActionButtonThree?: (row: MRTRow<TData>) => JSX.Element;
	customToolbarButton?: () => JSX.Element;
	containerHeight?: string;
}

export default function Table<T extends MRTRowData>({
	columns,
	data,
	title = "Table",
	isLoading = false,
	addButtonOnClick,
	editButtonOnClick,
	deleteButtonOnClick,
	onRowClick,
	customActionButtonOne,
	customActionButtonTwo,
	customActionButtonThree,
	customToolbarButton,
	containerHeight = "100vh",
	...rest
}: TableProps<T>) {
	const memoizedColumns = useMemo(() => columns, [columns]);
	const memoizedData = useMemo(() => data, [data]);

	const table = useMaterialReactTable({
		...rest,
		columns: memoizedColumns,
		data: memoizedData,
		enableDensityToggle: false,
		enableFullScreenToggle: false,
		enableHiding: false,
		enableStickyHeader: true,
		enableStickyFooter: true,
		state: {
			showSkeletons: isLoading,
		},
		muiTableContainerProps: {
			sx: {
				height: {
					xs: `calc(${containerHeight} - 310px)`,
					sm: `calc(${containerHeight} - 250px)`,
					md: `calc(${containerHeight} - 250px)`,
					lg: `calc(${containerHeight} - 250px)`,
					xl: `calc(${containerHeight} - 250px)`,
				},
			},
		},
		mrtTheme: () => ({
			baseBackgroundColor: theme.palette.lightColor.main,
		}),
		initialState: {
			showGlobalFilter: true,
		},
		renderTopToolbarCustomActions: () => (
			<Box sx={{ display: "flex", gap: "1rem", p: "4px" }}>
				<Typography fontSize={30} fontWeight={600} marginLeft={"2%"}>
					{title}
				</Typography>
			</Box>
		),
		renderToolbarInternalActions: ({ table }) => (
			<Box>
				{addButtonOnClick && (
					<IconButton onClick={addButtonOnClick}>
						<FontAwesomeIcon icon={faPlus} />
					</IconButton>
				)}
				{customToolbarButton ? customToolbarButton() : <></>}
				<MRTToggleFiltersButton table={table} />
			</Box>
		),
		muiTableBodyRowProps: ({ row }) => ({
			onClick: (event) => {
				if (onRowClick && isLoading === false) {
					onRowClick(event, row);
				}
			},
			...(onRowClick &&
				isLoading === false && {
					sx: {
						cursor: "pointer",
					},
				}),
		}),
		enableRowActions:
			Boolean(editButtonOnClick) ||
			Boolean(deleteButtonOnClick) ||
			Boolean(customActionButtonOne) ||
			Boolean(customActionButtonTwo) ||
			Boolean(customActionButtonThree),
		positionActionsColumn: "last",
		renderRowActions: ({ row }) => (
			<Box>
				{editButtonOnClick && (
					<IconButton
						onClick={(e) => {
							e.stopPropagation();
							editButtonOnClick(row);
						}}
					>
						<FontAwesomeIcon size={"xs"} icon={faPen} />
					</IconButton>
				)}
				{deleteButtonOnClick && (
					<IconButton
						onClick={(e) => {
							e.stopPropagation();
							deleteButtonOnClick(row);
						}}
					>
						<FontAwesomeIcon size={"xs"} icon={faTrash} />
					</IconButton>
				)}
				{customActionButtonOne ? customActionButtonOne(row) : <></>}
				{customActionButtonTwo ? customActionButtonTwo(row) : <></>}
				{customActionButtonThree ? customActionButtonThree(row) : <></>}
			</Box>
		),
	});

	return <MaterialReactTable table={table} />;
}
