import { doc, getDoc } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COMPLAINTS_ARCHIVED_COLLECTION, COMPLAINTS_COLLECTION } from "../constants/firebaseConstants";
import { firestore } from "../firebase/config";
import { genericErrorFunction } from "../utils/helpers";
import { Complaint, ComplaintDoc, SingleComplaintDoc } from "../utils/types";

export default function useComplaint(userId: string | null, complaintId: string | null, isCompleted?: boolean) {
	const navigate = useNavigate();
	const [complaint, setComplaint] = useState<SingleComplaintDoc | null>(null);
	const [loading, setLoading] = useState(true);

	const getComplaint = async () => {
		if (userId && complaintId) {
			try {
				setLoading(true);
				let complaintQuery = doc(firestore, COMPLAINTS_COLLECTION, userId);

				if (isCompleted) {
					complaintQuery = doc(firestore, COMPLAINTS_ARCHIVED_COLLECTION, userId);
				}

				await getDoc(complaintQuery)
					.then((doc) => {
						if (doc.exists()) {
							const data = doc.data() as Omit<ComplaintDoc, "id">;
							const filteredComplaint = data.complaints.find(
								(item: Complaint) => item.id === complaintId,
							);
							if (filteredComplaint) {
								const complaint: SingleComplaintDoc = {
									id: doc.id,
									name: data.name,
									email: data.email,
									phoneNumber: data.phoneNumber,
									company: data.company,
									department: data.department,
									complaint: filteredComplaint,
								};
								setComplaint(complaint);
							} else {
								enqueueSnackbar("Complaint does not exist", { variant: "error" });
								navigate(-1);
							}
						} else {
							enqueueSnackbar("Complaint does not exist", { variant: "error" });
							navigate(-1);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching complaint: ", error });
			}
		}
	};

	useEffect(() => {
		getComplaint();
	}, [userId, complaintId]);

	return { complaint, loading };
}
