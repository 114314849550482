import { Box, Typography } from "@mui/material";
import useIsMediumDevice from "../../../hooks/useIsMediumDevice";
import useIsMobile from "../../../hooks/useIsMobile";
import { theme } from "../../../theme/theme";
import ComplaintButton from "./ComplaintButton";

interface ComplaintIntroductionProps {
	onNextClick: () => void;
}

export default function ComplaintIntroduction({ onNextClick }: ComplaintIntroductionProps) {
	const isMobile = useIsMobile();
	const isMediumDevice = useIsMediumDevice();

	const handleDynamicTextSizing = (value: "h2" | "h5" | "body2") => {
		if (isMobile || isMediumDevice) {
			if (value === "h2") {
				return "h5";
			} else if (value === "h5") {
				return "body1";
			} else if (value === "body2") {
				return "caption";
			}
		} else {
			if (value === "h2") {
				return "h2";
			} else if (value === "h5") {
				return "h5";
			} else if (value === "body2") {
				return "body2";
			}
		}
	};

	return (
		<Box className="flex flexColumn center">
			<Typography variant={handleDynamicTextSizing("h2")} color={theme.palette.darkColor.main}>
				Welcome To The Complaint Center!
			</Typography>
			<Typography
				mt={4}
				className="break-line-typography"
				variant={handleDynamicTextSizing("h5")}
				color={theme.palette.darkColor.main}
			>
				{"In the next few steps, we'll gather the information \n needed to address your issue."}
			</Typography>
			<Typography mt={4} variant={handleDynamicTextSizing("body2")} color={theme.palette.darkColor.main}>
				Click &quot;Next&quot; to begin.
			</Typography>
			<ComplaintButton text="Next" onClick={onNextClick} />
		</Box>
	);
}
