import { Box, Typography } from "@mui/material";
import useIsMediumDevice from "../../../hooks/useIsMediumDevice";
import useIsMobile from "../../../hooks/useIsMobile";
import { theme } from "../../../theme/theme";
import ComplaintButton from "./ComplaintButton";
import { Link } from "react-router-dom";

interface ComplaintFinishProps {
	onFinishClick: () => void;
	onRestartClick: () => void;
}

export default function ComplaintFinish({ onFinishClick, onRestartClick }: ComplaintFinishProps) {
	const isMobile = useIsMobile();
	const isMediumDevice = useIsMediumDevice();

	const handleDynamicTextSizing = (value: "h2" | "h5" | "body2") => {
		if (isMobile || isMediumDevice) {
			if (value === "h2") {
				return "h5";
			} else if (value === "h5") {
				return "body1";
			} else if (value === "body2") {
				return "caption";
			}
		} else {
			if (value === "h2") {
				return "h2";
			} else if (value === "h5") {
				return "h5";
			} else if (value === "body2") {
				return "body2";
			}
		}
	};

	return (
		<Box className="flex flexColumn center">
			<Typography variant={handleDynamicTextSizing("h2")} color={theme.palette.darkColor.main}>
				Thank You For Lodging a Complaint!
			</Typography>
			<Typography
				mt={4}
				className="break-line-typography"
				variant={handleDynamicTextSizing("h5")}
				color={theme.palette.darkColor.main}
			>
				{
					"Our supervisors will get to resolving your issue as soon as possible. \n You can track your complaint progress using the "
				}
				<Link to={"/complaint/list"}>&quot;Track Your Complaint&quot;</Link> {" feature"}
			</Typography>
			<Typography
				mt={4}
				className="break-line-typography"
				variant={handleDynamicTextSizing("body2")}
				color={theme.palette.darkColor.main}
			>
				{
					'If you want to submit another complaint please press "Lodge Another Complaint" \n or press "Finish" to go back to the home page'
				}
			</Typography>
			<ComplaintButton text="Lodge Another Complaint" onClick={onRestartClick} />
			<ComplaintButton text="Finish" onClick={onFinishClick} />
		</Box>
	);
}
