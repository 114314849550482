import { Link } from "react-scroll";
import styled from "styled-components";
// Assets
import { TITLE } from "../../../constants/textConstants";
import { useState } from "react";
import CreditsModal from "../CreditsModal";
import LogoIcon from "../../../assets/img/logoIcon.png";

export default function Contact() {
	const [openCredits, setOpenCredits] = useState(false);
	const getCurrentYear = () => {
		return new Date().getFullYear();
	};

	return (
		<>
			<CreditsModal
				open={openCredits}
				onClose={() => {
					setOpenCredits(false);
				}}
			/>
			<Wrapper id="footer">
				<div className="darkBg">
					<div className="container">
						<InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
							<Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
								<img
									src={LogoIcon}
									style={{
										width: "40px",
										height: "40px",
									}}
								/>
								<h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
									{TITLE + " Pvt Ltd."}
								</h1>
							</Link>
							<StyleP className="whiteColor font13">
								© {getCurrentYear()} - <span className="font13">{TITLE}</span> All Right Reserved
							</StyleP>

							<Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
								Back To Top
							</Link>

							<Link
								to="#"
								onClick={() => {
									setOpenCredits(true);
								}}
								className="whiteColor animate pointer font13"
							>
								Credits
							</Link>
						</InnerWrapper>
					</div>
				</div>
			</Wrapper>
		</>
	);
}

const Wrapper = styled.div`
	width: 100%;
`;
const InnerWrapper = styled.div`
	@media (max-width: 550px) {
		flex-direction: column;
	}
`;
const StyleP = styled.p`
	@media (max-width: 550px) {
		margin: 20px 0;
	}
`;
