import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { SyntheticEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import AcceptedComplaintTable from "../../../components/authedComponents/crudComponents/Complaint/AcceptedComplaintTable";
import CompletedComplaintTable from "../../../components/authedComponents/crudComponents/Complaint/CompletedComplaintTable";
import InitialComplaintTable from "../../../components/authedComponents/crudComponents/Complaint/InitialComplaintTable";
import InProgressComplaintTable from "../../../components/authedComponents/crudComponents/Complaint/InProgressComplaintTable";
import OnHoldComplaintTable from "../../../components/authedComponents/crudComponents/Complaint/OnHoldComplaintTable";
import { changeComplaintTab } from "../../../redux/misc";
import { RootState } from "../../../redux/store";
import RejectedComplaintTable from "../../../components/authedComponents/crudComponents/Complaint/RejectedComplaintTable";
import usePermissions from "../../../hooks/usePermissions";
import { COMPLAINTS_ } from "../../../constants/textConstants";

export default function Complaints() {
	const dispatch = useDispatch();
	const { getPermission } = usePermissions();
	const { complaintsTab } = useSelector((state: RootState) => state.misc);

	const handleChange = (
		event: SyntheticEvent,
		newValue: "initial" | "accepted" | "inProgress" | "onHold" | "completed" | "rejected",
	) => {
		dispatch(changeComplaintTab(newValue));
	};

	return (
		<Box
			sx={{
				height: "100%",
			}}
		>
			<TabContext value={complaintsTab}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList onChange={handleChange} aria-label="lab API tabs example">
						{getPermission(COMPLAINTS_, "list_incoming") ? <Tab label="Incoming" value="initial" /> : <></>}
						{getPermission(COMPLAINTS_, "list_accepted") ? (
							<Tab label="Accepted" value="accepted" />
						) : (
							<></>
						)}
						{getPermission(COMPLAINTS_, "list_inprogress") ? (
							<Tab label="In Progress" value="inProgress" />
						) : (
							<></>
						)}
						{getPermission(COMPLAINTS_, "list_on_hold") ? <Tab label="On Hold" value="onHold" /> : <></>}
						{getPermission(COMPLAINTS_, "list_completed") ? (
							<Tab label="Completed" value="completed" />
						) : (
							<></>
						)}
						{getPermission(COMPLAINTS_, "list_rejected") ? (
							<Tab label="Rejected" value="rejected" />
						) : (
							<></>
						)}
					</TabList>
				</Box>
				{getPermission(COMPLAINTS_, "list_incoming") ? (
					<TabPanel sx={{ height: "100%" }} value="initial">
						<InitialComplaintTable />
					</TabPanel>
				) : (
					<></>
				)}
				{getPermission(COMPLAINTS_, "list_accepted") ? (
					<TabPanel sx={{ height: "100%" }} value="accepted">
						<AcceptedComplaintTable />
					</TabPanel>
				) : (
					<></>
				)}
				{getPermission(COMPLAINTS_, "list_inprogress") ? (
					<TabPanel sx={{ height: "100%" }} value="inProgress">
						<InProgressComplaintTable />
					</TabPanel>
				) : (
					<></>
				)}
				{getPermission(COMPLAINTS_, "list_on_hold") ? (
					<TabPanel sx={{ height: "100%" }} value="onHold">
						<OnHoldComplaintTable />
					</TabPanel>
				) : (
					<></>
				)}
				{getPermission(COMPLAINTS_, "list_completed") ? (
					<TabPanel sx={{ height: "100%" }} value="completed">
						<CompletedComplaintTable />
					</TabPanel>
				) : (
					<></>
				)}
				{getPermission(COMPLAINTS_, "list_rejected") ? (
					<TabPanel sx={{ height: "100%" }} value="rejected">
						<RejectedComplaintTable />
					</TabPanel>
				) : (
					<></>
				)}
			</TabContext>
		</Box>
	);
}
