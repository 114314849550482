import { FirebaseError } from "@firebase/util";
import { Button, Link, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import BackdropLoader from "../../components/authedComponents/BackdropLoader";
import PasswordTextField from "../../components/PasswordTextField";
import { USERS_COLLECTION } from "../../constants/firebaseConstants";
import { EMAIL_REGEX } from "../../constants/helperConstants";
import { DASHBOARD_, FORGOT_PASSWORD_, LOGIN } from "../../constants/textConstants";
import { auth, firestore } from "../../firebase/config";
import useIsMediumDevice from "../../hooks/useIsMediumDevice";
import useIsMobile from "../../hooks/useIsMobile";
import { setUserData } from "../../redux/user";
import { theme } from "../../theme/theme";
import { getInitialPage } from "../../utils/helpers";

interface LoginFields {
	email: string;
	password: string;
}

export default function Login() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isMobile = useIsMobile();
	const isMediumDevice = useIsMediumDevice();
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const { control, handleSubmit } = useForm<LoginFields>({
		mode: "onTouched",
		defaultValues: {
			email: "",
			password: "",
		},
	});

	const onSubmit: SubmitHandler<LoginFields> = async (data) => {
		setLoading(true);

		// Logging in
		await signInWithEmailAndPassword(auth, data.email, data.password)
			.then(async (response) => {
				// Getting user document
				const document = await getDoc(doc(firestore, USERS_COLLECTION, response.user.uid));

				// If document exists
				if (document.exists()) {
					// Creating a payload and saving data in redux
					const userData = document.data();

					const reduxPayload = {
						id: response.user.uid,
						firstName: userData.firstName,
						lastName: userData.lastName,
						email: userData.email,
						cnic: userData.cnic,
						role: userData.role,
						info: userData.info,
						issueCategoryIds:
							userData.issueCategoryIds && userData.issueCategoryIds.length
								? userData.issueCategoryIds
								: null,
						client: userData.clientId ? userData.clientId : null,
						permissions: userData.permissions && userData.permissions.length ? userData.permissions : [],
					};

					dispatch(setUserData(reduxPayload));
					enqueueSnackbar("Logged in successfully", { variant: "success" });
					navigate(getInitialPage(userData.role));
				}
				// If document does not exist
				else {
					// Singing out user
					await signOut(auth);
					navigate("/");
					enqueueSnackbar("Something went wrong.", { variant: "error" });
				}
			})
			.catch((error: FirebaseError) => {
				console.error("login error", error);
				switch (error.code) {
					case "auth/user-not-found":
						enqueueSnackbar("The corresponding user does not exist.", { variant: "error" });
						break;
					case "auth/invalid-email":
						enqueueSnackbar("The provided email address is incorrect.", { variant: "error" });
						break;
					case "auth/wrong-password":
						enqueueSnackbar("The entered password is incorrect.", { variant: "error" });
						break;
					default:
						enqueueSnackbar("Something went wrong.", { variant: "error" });
						break;
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleForgotPassword = () => {
		navigate(`/${FORGOT_PASSWORD_}`);
	};

	return (
		<>
			<BackdropLoader open={loading} />
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<Box className="unauth-background">
					<Paper elevation={20} className="unauth-paper">
						<Box
							sx={{
								height: "100%",
								display: "flex",
							}}
						>
							<Box className="unauth-center">
								<Typography
									fontSize={isMobile || isMediumDevice ? 30 : 50}
									color={theme.palette.darkColor.main}
								>
									Welcome Back!
								</Typography>
								<Typography
									mb={isMobile || isMediumDevice ? 0 : 5}
									fontSize={isMobile || isMediumDevice ? 13 : 16}
									color={theme.palette.darkColor.main}
								>
									Please enter your details to login.
								</Typography>
								<Controller
									name="email"
									control={control}
									rules={{
										required: "Email is required",
										pattern: {
											value: EMAIL_REGEX,
											message: "Incorrect Email",
										},
									}}
									render={({ field: { ref, ...rest }, fieldState: { error } }) => (
										<TextField
											{...rest}
											label="Email"
											required
											error={error ? true : false}
											helperText={error ? error.message : ""}
											sx={{
												marginTop: "20px",
												width: "50%",
												...(isMobile && {
													width: "80%",
												}),
												...(isMediumDevice && {
													width: "70%",
												}),
											}}
										/>
									)}
								/>
								<Controller
									name="password"
									control={control}
									rules={{
										required: "Password is required",
									}}
									render={({ field: { ref, ...rest }, fieldState: { error } }) => (
										<PasswordTextField
											{...rest}
											required
											error={error ? true : false}
											helperText={error ? error.message : ""}
											sx={{
												marginTop: "20px",
												width: "50%",
												...(isMobile && {
													width: "80%",
												}),
												...(isMediumDevice && {
													width: "70%",
												}),
											}}
										/>
									)}
								/>
								<Button
									sx={{
										marginTop: "40px",
										width: "20%",
										...(isMobile && {
											width: "70%",
										}),
										...(isMediumDevice && {
											width: "40%",
										}),
									}}
									variant="contained"
									type="submit"
								>
									{LOGIN}
								</Button>
								<Link sx={{ marginTop: "40px", cursor: "pointer" }} onClick={handleForgotPassword}>
									Forgot your password?
								</Link>
							</Box>
							{!isMobile && (
								<Box className="login-gradient-animation" sx={{ width: "50%", height: "100%" }} />
							)}
						</Box>
					</Paper>
				</Box>
			</form>
		</>
	);
}
