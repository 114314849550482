import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { ADMINS_, DASHBOARD_ } from "../../constants/textConstants";
import { allFeaturePermissions } from "../../utils/helpers";
import { PermissionIds, UserPermissionsType } from "../../utils/types";

interface PermissionSelectorProps {
	selectedPermissions: UserPermissionsType;
	setSelectedPermissions: Dispatch<SetStateAction<UserPermissionsType>>;
	disableAdminPermissions?: boolean;
}

export default function PermissionSelector({
	selectedPermissions,
	setSelectedPermissions,
	disableAdminPermissions = false,
}: PermissionSelectorProps) {
	const getChecked = (sectionId: string, permissionId: PermissionIds) => {
		const section = selectedPermissions.find((item) => item.id === sectionId);
		if (section) {
			const permission = section.permissions.find((item) => item.id === permissionId);
			if (permission) {
				return permission.isAllowed;
			}
		}
		return false;
	};

	const onCheckBoxChange = (sectionId: string, permissionId: PermissionIds, checked: boolean) => {
		const updatedPermissions = selectedPermissions.map((item) => {
			// First mapping through all the sections to find the section in which we need to perform changes
			if (item.id === sectionId) {
				return {
					...item,
					// When the relevant section is found, we map through all the permissions to find the permission who's status we need to change. Once it is found we change the "isAllowed" property to the new "checked" status
					permissions: item.permissions.map((innerItem) => {
						if (innerItem.id === permissionId) {
							return {
								...innerItem,
								isAllowed: checked,
							};
						} else {
							return innerItem;
						}
					}),
				};
			}
			// Every other section we return as is
			else {
				return item;
			}
		});
		setSelectedPermissions(updatedPermissions);
	};

	const getDisabled = (sectionId: string) => {
		if ((disableAdminPermissions && sectionId === ADMINS_) || sectionId === DASHBOARD_) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			<Box mb={1}>
				<Typography fontSize={25} fontWeight={600}>
					Permissions
				</Typography>
			</Box>
			{allFeaturePermissions.map((section) => (
				<Box boxShadow={3} key={section.id} mt={2}>
					<Accordion>
						<AccordionSummary expandIcon={<FontAwesomeIcon icon={faCaretDown} />}>
							{section.sectionName}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup
								sx={{
									display: "flex",
									flexDirection: "row",
								}}
							>
								{section.permissions.map((permission) => (
									<FormControlLabel
										key={permission.id}
										label={permission.featureName}
										control={
											<Checkbox
												disabled={getDisabled(section.id)}
												checked={getChecked(section.id, permission.id)}
												onChange={(e) => {
													onCheckBoxChange(section.id, permission.id, e.target.checked);
												}}
											/>
										}
									/>
								))}
							</FormGroup>
						</AccordionDetails>
					</Accordion>
				</Box>
			))}
		</>
	);
}
