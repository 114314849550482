import { and, collection, documentId, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { USERS_COLLECTION } from "../constants/firebaseConstants";
import { firestore } from "../firebase/config";
import { addMissingSectionsToPreSelectedPermissions, genericErrorFunction } from "../utils/helpers";
import { Admin } from "../utils/types";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export default function useAdmins() {
	const [admins, setAdmins] = useState<Array<Admin>>([]);
	const [loading, setLoading] = useState(true);
	const [shouldFetch, refetch] = useState(true);
	const { id } = useSelector((state: RootState) => state.user);

	const getAdmins = async () => {
		if (shouldFetch) {
			setAdmins([]);
			setLoading(true);
			try {
				const adminsQuery = query(
					collection(firestore, USERS_COLLECTION),
					where("role", "==", "ADMIN"),
					where(documentId(), "!=", id),
				);

				await getDocs(adminsQuery)
					.then((snapshot) => {
						snapshot.forEach((doc) => {
							const data = doc.data();
							const admin: Admin = {
								id: doc.id,
								firstName: data.firstName,
								lastName: data.lastName,
								email: data.email,
								cnic: data.cnic,
								role: data.role,
								info: data.info ? data.info : null,
								permissions: addMissingSectionsToPreSelectedPermissions(data.permissions),
							};
							setAdmins((prev) => [...prev, admin]);
						});
					})
					.finally(() => {
						setLoading(false);
						refetch(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching admins: ", error });
			}
		}
	};

	useEffect(() => {
		getAdmins();
	}, [shouldFetch]);

	return { admins, loading, refetch };
}
