import { Box, Button, Grid2, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import useIssueCategory from "../../../../hooks/useIssueCategory";
import { capitalizeFirstLetter, renderHeading } from "../../../../utils/helpers";
import { AddIssueCategoryPayloadInterface, EditIssueCategoryPayloadInterface } from "../../../../utils/types";
import BackdropLoader from "../../BackdropLoader";
import BackgroundPaper from "../../BackgroundPaper";

interface AddIssueCategoryFieldsProps {
	type: "Add";
	apiCall: (payload: AddIssueCategoryPayloadInterface) => Promise<void>;
}
interface EditIssueCategoryFieldsProps {
	type: "Edit";
	apiCall: (payload: EditIssueCategoryPayloadInterface) => Promise<void>;
}
interface ViewIssueCategoryFieldsProps {
	type: "View";
	apiCall?: never;
}

type IssueCategoryFieldsProps =
	| AddIssueCategoryFieldsProps
	| EditIssueCategoryFieldsProps
	| ViewIssueCategoryFieldsProps;

interface IssueCategoryFields {
	name: string;
	description: string;
}

export default function IssueCategoryFields({ type, apiCall }: IssueCategoryFieldsProps) {
	const [searchParams] = useSearchParams();
	const { issueCategory, loading } = useIssueCategory(searchParams.get("id")); // Only runs in Edit and View case
	const [componentLoading, setComponentLoading] = useState(false);

	const { control, handleSubmit, reset } = useForm<IssueCategoryFields>({
		mode: "onTouched",
		defaultValues: {
			name: "",
			description: "",
		},
	});

	useEffect(() => {
		if (issueCategory) {
			reset({
				name: issueCategory.name,
				description: issueCategory.description,
			});
		}
	}, [issueCategory]);

	const onSubmit: SubmitHandler<IssueCategoryFields> = async (data) => {
		const id = searchParams.get("id");
		if (apiCall) {
			setComponentLoading(true);
			if (type === "Add") {
				const payload: AddIssueCategoryPayloadInterface = {
					name: capitalizeFirstLetter(data.name),
					description: data.description,
				};

				await apiCall(payload).finally(() => {
					setComponentLoading(false);
				});
			} else if (type === "Edit" && id) {
				const payload: EditIssueCategoryPayloadInterface = {
					id: id,
					name: capitalizeFirstLetter(data.name),
					description: data.description,
				};

				await apiCall(payload).finally(() => {
					setComponentLoading(false);
				});
			}
		}
	};

	return (
		<>
			<BackdropLoader open={loading || componentLoading} />
			<BackgroundPaper>
				<Box>
					<Typography fontSize={25} fontWeight={600}>
						{renderHeading(type, "Issue Category")}
					</Typography>
				</Box>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<Grid2 container spacing={2} mt={"10px"}>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="name"
								control={control}
								rules={{
									required: "Name is required",
								}}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										disabled={type === "View"}
										fullWidth
										required
										label="Name"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
						<Grid2 size={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}>
							<Controller
								name="description"
								control={control}
								render={({ field, fieldState: { error } }) => (
									<TextField
										{...field}
										disabled={type === "View"}
										fullWidth
										multiline
										minRows={3}
										maxRows={5}
										label="Description"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						</Grid2>
					</Grid2>
					{type !== "View" && (
						<Button
							variant="contained"
							type="submit"
							sx={{
								width: "10%",
								marginTop: "20px",
							}}
						>
							Save
						</Button>
					)}
				</form>
			</BackgroundPaper>
		</>
	);
}
