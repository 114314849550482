import { doc, getDoc } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ISSUE_CATEGORIES_COLLECTION } from "../constants/firebaseConstants";
import { firestore } from "../firebase/config";
import { genericErrorFunction } from "../utils/helpers";
import { IssueCategory } from "../utils/types";

export default function useIssueCategory(id: string | null) {
	const navigate = useNavigate();
	const [issueCategory, setIssueCategory] = useState<IssueCategory | null>(null);
	const [loading, setLoading] = useState(false);

	const getIssueCategory = async () => {
		if (id) {
			try {
				setLoading(true);
				const issueCategoryQuery = doc(firestore, ISSUE_CATEGORIES_COLLECTION, id);

				await getDoc(issueCategoryQuery)
					.then((doc) => {
						if (doc.exists()) {
							const data = doc.data();
							const issueCategory: IssueCategory = {
								id: doc.id,
								name: data.name,
								description: data.description,
							};
							setIssueCategory(issueCategory);
						} else {
							enqueueSnackbar("Issue Category does not exist", { variant: "error" });
							navigate(-1);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching issue category: ", error });
			}
		}
	};

	useEffect(() => {
		getIssueCategory();
	}, [id]);

	return { issueCategory, loading };
}
