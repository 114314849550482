import { Box } from "@mui/material";
import InventoryTable from "../../../components/authedComponents/crudComponents/Inventory/InventoryTable";

export default function Inventory() {
	return (
		<Box
			sx={{
				height: "100%",
			}}
		>
			<InventoryTable />
		</Box>
	);
}
