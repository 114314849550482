export default function TumbleWeed() {
	return (
		<div className="tumbleweed-empty-icon-container">
			<div className="tumbleweed-animation-container">
				<div className="tumbleweed-bounce"></div>
				<div className="tumbleweed-pebble1"></div>
				<div className="tumbleweed-pebble2"></div>
				<div className="tumbleweed-pebble3"></div>
			</div>
		</div>
	);
}
