import { Box } from "@mui/material";
import { theme } from "../../theme/theme";

export default function BackgroundPaper({ children }: { children: React.ReactNode | React.ReactNode[] }) {
	return (
		<Box
			sx={{
				height: "92%",
				backgroundColor: theme.palette.lightColor.main,
				padding: "20px 20px 20px 20px",
				overflow: "auto",
			}}
		>
			{children}
		</Box>
	);
}
