import { doc, setDoc } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ClientFields from "../../../components/authedComponents/crudComponents/Client/ClientFields";
import { CLIENTS_COLLECTION } from "../../../constants/firebaseConstants";
import { firestore } from "../../../firebase/config";
import { genericErrorFunction } from "../../../utils/helpers";
import { EditClientPayloadInterface } from "../../../utils/types";

export default function EditClient() {
	const navigate = useNavigate();

	const editClient = async (payload: EditClientPayloadInterface) => {
		try {
			const { id, ...rest } = payload;
			await setDoc(doc(firestore, CLIENTS_COLLECTION, id), rest)
				.then(() => {
					enqueueSnackbar("Client Edited!", { variant: "success" });
				})
				.finally(() => {
					navigate(-1);
				});
		} catch (error) {
			genericErrorFunction({ logMessage: "Error occurred while editing client: ", error });
		}
	};

	return <ClientFields type="Edit" apiCall={editClient} />;
}
