import { faGhost } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NotFound() {
	return (
		<main className="not-found-main">
			<h1 className="not-found-h1">
				4
				<span>
					<FontAwesomeIcon icon={faGhost} />
				</span>
				4
			</h1>
			<h2 className="not-found-h2">Error: 404 page not found</h2>
			<p className="not-found-p">Sorry, the page you&apos;re looking for was not found</p>
		</main>
	);
}
