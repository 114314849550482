import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
import ProjectBox from "../Elements/ProjectBox";
// Assets
import AddImage2 from "../../../assets/img/add/add2.jpg";
import ProjectImg1 from "../../../assets/img/projects/1.jpg";
import ProjectImg2 from "../../../assets/img/projects/2.jpg";
import ProjectImg3 from "../../../assets/img/projects/3.jpg";
import ProjectImg4 from "../../../assets/img/projects/4.jpg";
import ProjectImg5 from "../../../assets/img/projects/5.jpg";
import ProjectImg6 from "../../../assets/img/projects/6.jpg";
import { scrollToElement } from "../../../utils/helpers";

export default function Projects() {
	return (
		<Wrapper id="projects">
			<div className="whiteBg">
				<div className="container">
					<HeaderInfo>
						<h1 className="font40 extraBold">Our Services</h1>
						<p className="font15">
							Unlock a year of seamless workforce solutions with our Annual Contract-Based Manpower
							Services at Numla Asia Pvt Ltd. We offer a strategic partnership tailored to your long-term
							staffing needs. Experience the ease of consistent, reliable manpower, ensuring your business
							thrives with a skilled and committed workforce throughout the year. Elevate your operations
							and productivity by entrusting your staffing requirements to us. Welcome to a year of
							unparalleled manpower support with Numla Asia Pvt Ltd.
							<br />
						</p>
					</HeaderInfo>
					<div className="row textCenter">
						<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<ProjectBox
								img={ProjectImg1}
								title="Employee Outsourcing"
								text="Our outsourcing solutions are designed to streamline HR processes, minimize administrative burdens, and optimize workforce management"
							/>
						</div>
						<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<ProjectBox
								img={ProjectImg2}
								title="Recruitment"
								text="Our dedicated team of recruitment experts employs innovative sourcing strategies and rigorous screening processes to identify and attract the best candidates for your organization."
							/>
						</div>
						<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<ProjectBox
								img={ProjectImg3}
								title="Janitorial  Services"
								text="Our dedicated team of janitorial experts ensures pristine cleanliness and hygiene standards in your workplace, creating a conducive environment for productivity and well-being."
							/>
						</div>
					</div>
					<div className="row textCenter">
						<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<ProjectBox
								img={ProjectImg4}
								title="Solid Waste Management"
								text="Reliable garbage removal and waste
management services, tailoring solutions to meet your needs on a yearly contract basis. Trust us to keep your space clean and sustainable, all year round."
							/>
						</div>
						<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<ProjectBox
								img={ProjectImg5}
								title="Training Program"
								text="Our training initiatives are meticulously designed to enhance the skills, knowledge, and capabilities of outsourced personnel, ensuring they are well equipped to meet and exceed client expectations."
							/>
						</div>
						<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<ProjectBox
								img={ProjectImg6}
								title="Fumigation Services"
								text="We offer professional fumigation services tailored to office buildings and corporate spaces, ensuring a pest-free environment for all our clients. Our safe, effective methods help maintain a healthy workplace with reliable, scheduled treatments."
							/>
						</div>
					</div>
					{/* <div className="row flexCenter">
						<div style={{ margin: "50px 0", width: "200px" }}>
							<FullButton title="Load More"  />
						</div>
					</div> */}
				</div>
			</div>
			<div className="lightBg">
				<div className="container">
					<Advertising className="flexSpaceCenter">
						<AddLeft>
							<AddLeftInner>
								<ImgWrapper className="flexCenter">
									<img className="radius8" src={AddImage2} alt="add" />
								</ImgWrapper>
							</AddLeftInner>
						</AddLeft>
						<AddRight>
							<h4 className="font40 extraBold">Numla Health Care</h4>
							<p style={{ marginTop: "10px" }} className="font15">
								We proudly offer free homeopathy services to all employees working at our clients&apos;
								sites, reflecting our commitment to their overall well-being. Our experienced homeopathy
								specialist provides personalized consultations and treatments, addressing both physical
								and mental health concerns through natural, holistic remedies. This approach not only
								helps alleviate ailments but also supports long-term wellness, creating a healthier and
								more balanced workforce.
							</p>
							<p style={{ marginTop: "10px" }} className="font15">
								Additionally, we extend these services to our clients&apos; personal employees, offering
								them access to customized homeopathy care. By focusing on holistic health, we aim to
								enhance the overall quality of life and well-being, fostering a more positive and
								productive work environment for everyone.
							</p>
							<ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
								{/* <div style={{ width: "190px" }}>
									<FullButton title="Get Started"  />
								</div> */}
								<div style={{ width: "190px", marginLeft: "15px" }}>
									<FullButton
										title="Contact Us"
										border
										action={() => {
											scrollToElement("contact");
										}}
									/>
								</div>
							</ButtonsRow>
						</AddRight>
					</Advertising>
				</div>
			</div>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	width: 100%;
`;
const HeaderInfo = styled.div`
	@media (max-width: 860px) {
		text-align: center;
	}
`;
const Advertising = styled.div`
	padding: 100px 0;
	margin: 100px 0;
	position: relative;
	@media (max-width: 1160px) {
		padding: 60px 0 40px 0;
	}
	@media (max-width: 860px) {
		flex-direction: column;
		padding: 0 0 30px 0;
		margin: 80px 0 0px 0;
	}
`;
const ButtonsRow = styled.div`
	@media (max-width: 860px) {
		justify-content: space-between;
	}
`;
const AddLeft = styled.div`
	position: relative;
	width: 50%;
	p {
		max-width: 475px;
	}
	@media (max-width: 860px) {
		width: 80%;
		order: 2;
		text-align: center;
		h2 {
			line-height: 3rem;
			margin: 15px 0;
		}
		p {
			margin: 0 auto;
		}
	}
`;
const AddRight = styled.div`
	width: 50%;
	@media (max-width: 860px) {
		width: 80%;
		order: 2;
	}
`;
const AddLeftInner = styled.div`
	width: 100%;
	position: absolute;
	top: -300px;
	left: 0;
	@media (max-width: 1190px) {
		top: -250px;
	}
	@media (max-width: 920px) {
		top: -200px;
	}
	@media (max-width: 860px) {
		order: 1;
		position: relative;
		top: -60px;
		left: 0;
	}
`;
const ImgWrapper = styled.div`
	width: 100%;
	padding: 0 15%;
	img {
		width: 100%;
		height: auto;
	}
	@media (max-width: 400px) {
		padding: 0;
	}
`;
