import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface ConfirmationModalProps {
	open: boolean;
	onClose: () => void;
	title: string;
	description: string;
	apiCall: () => Promise<void>;
	loading?: boolean;
}

export default function ConfirmationModal({
	open,
	onClose,
	title,
	description,
	apiCall,
	loading = false,
}: ConfirmationModalProps) {
	const handleOnClose = () => {
		if (!loading) {
			onClose();
		}
	};

	return (
		<Dialog aria-modal open={open} onClose={handleOnClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{description}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					disabled={loading}
					loading={loading}
					loadingPosition="start"
					startIcon={<FontAwesomeIcon icon={faCheck} />}
					onClick={apiCall}
				>
					Yes
				</LoadingButton>
				<Button disabled={loading} startIcon={<FontAwesomeIcon icon={faXmark} />} onClick={handleOnClose}>
					No
				</Button>
			</DialogActions>
		</Dialog>
	);
}
