import { ImageList, ImageListItem } from "@mui/material";

interface ImageDisplayProps {
	images: Array<{
		name: string;
		url: string;
	}>;
}

export default function ImageDisplay({ images }: ImageDisplayProps) {
	return (
		<ImageList cols={3} rowHeight={164}>
			{images.map((image) => (
				<ImageListItem key={image.url}>
					<img
						className="pointer"
						srcSet={image.url}
						src={image.url}
						alt={image.name}
						loading="lazy"
						onClick={() => {
							window.open(image.url);
						}}
					/>
				</ImageListItem>
			))}
		</ImageList>
	);
}
