import { useSelector } from "react-redux";
import {
	ADMINS_,
	CLIENTS_,
	COMPLAINTS_,
	CONTACTS_,
	DASHBOARD_,
	INVENTORY_,
	ISSUE_CATEGORIES_,
	SUPERVISORS_,
} from "../constants/textConstants";
import { RootState } from "../redux/store";
import { useEffect, useState } from "react";
import { PermissionIds } from "../utils/types";

const routeMapping: Array<{ url: string; permission: PermissionIds }> = [
	{
		url: `${DASHBOARD_}`,
		permission: "dashboard_list",
	},
	{
		url: `${ADMINS_}`,
		permission: "admin_list",
	},
	{
		url: `${ADMINS_}/add`,
		permission: "admin_add",
	},
	{
		url: `${ADMINS_}/edit`,
		permission: "admin_edit",
	},
	{
		url: `${ADMINS_}/view`,
		permission: "admin_view",
	},
	{
		url: `${SUPERVISORS_}`,
		permission: "supervisor_list",
	},
	{
		url: `${SUPERVISORS_}/add`,
		permission: "supervisor_add",
	},
	{
		url: `${SUPERVISORS_}/edit`,
		permission: "supervisor_edit",
	},
	{
		url: `${SUPERVISORS_}/view`,
		permission: "supervisor_view",
	},
	{
		url: `${CLIENTS_}`,
		permission: "client_list",
	},
	{
		url: `${CLIENTS_}/add`,
		permission: "client_add",
	},
	{
		url: `${CLIENTS_}/edit`,
		permission: "client_edit",
	},
	{
		url: `${CLIENTS_}/view`,
		permission: "client_view",
	},
	{
		url: `${ISSUE_CATEGORIES_}`,
		permission: "issue_category_list",
	},
	{
		url: `${ISSUE_CATEGORIES_}/add`,
		permission: "issue_category_add",
	},
	{
		url: `${ISSUE_CATEGORIES_}/edit`,
		permission: "issue_category_edit",
	},
	{
		url: `${ISSUE_CATEGORIES_}/view`,
		permission: "issue_category_view",
	},
	{
		url: `${CONTACTS_}`,
		permission: "contact_list",
	},
	{
		url: `${CONTACTS_}/view`,
		permission: "contact_view",
	},
	{
		url: `${COMPLAINTS_}`,
		permission: "complaint_list",
	},
	{
		url: `${COMPLAINTS_}/view`,
		permission: "complaint_view",
	},
	{
		url: `${INVENTORY_}`,
		permission: "inventory_list",
	},
	{
		url: `${INVENTORY_}/view`,
		permission: "inventory_view",
	},
];

export default function usePermissions() {
	const { permissions } = useSelector((state: RootState) => state.user);
	const [allowedRoutes, setAllowedRoutes] = useState<Array<string>>([]);

	const getPermission = (sectionId: string, permissionId: PermissionIds) => {
		const sectionInfo = permissions.find((item) => item.id === sectionId);
		if (sectionInfo) {
			const permissionInfo = sectionInfo.permissions.find((item) => item.id === permissionId);
			if (permissionInfo) {
				return permissionInfo.isAllowed;
			}
		}
		return false;
		// DISABLE PERMISSIONS
		// return true;
	};

	const getAllowedRoutes = () => {
		const fetchedRoutes: Array<string> = [];
		permissions.forEach((section) => {
			section.permissions.forEach((permission) => {
				const foundMapping = routeMapping.find(
					(item) => item.permission === permission.id && permission.isAllowed,
				);
				if (foundMapping) {
					fetchedRoutes.push(foundMapping.url);
				}
			});
		});
		setAllowedRoutes(fetchedRoutes);
	};

	useEffect(() => {
		if (permissions.length) {
			getAllowedRoutes();
		}
	}, []);

	return { allowedRoutes, getPermission };
}
