import { Backdrop } from "@mui/material";
import DotsLoader from "./DotsLoader";
import SquareLoader from "./SquareLoader";

interface BackdropLoaderProps {
	open: boolean;
	loader?: "square" | "dots";
}

export default function BackdropLoader({ open, loader = "dots" }: BackdropLoaderProps) {
	return (
		<Backdrop sx={{ zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1 }} open={open}>
			{loader === "square" && <SquareLoader color="#FFFFFF" />}
			{loader === "dots" && <DotsLoader color="#FFFFFF" />}
		</Backdrop>
	);
}
