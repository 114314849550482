import { Box, Typography } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js/max";
import { Control, Controller } from "react-hook-form";
import useIsMediumDevice from "../../../hooks/useIsMediumDevice";
import useIsMobile from "../../../hooks/useIsMobile";
import { InitialScreenFields } from "../../../pages/unAuthedPages/ListComplaints";
import { theme } from "../../../theme/theme";
import PhoneNumberTextField from "../../authedComponents/PhoneNumberTextField";
import ComplaintButton from "./ComplaintButton";

interface ViewInitialScreenProps {
	control: Control<InitialScreenFields, any>;
	onViewClick: () => Promise<void>;
}

export default function ViewInitialScreen({ control, onViewClick }: ViewInitialScreenProps) {
	const isMobile = useIsMobile();
	const isMediumDevice = useIsMediumDevice();

	const handleDynamicTextSizing = (value: "h2" | "h5") => {
		if (isMobile || isMediumDevice) {
			if (value === "h2") {
				return "h5";
			} else if (value === "h5") {
				return "body1";
			}
		} else {
			if (value === "h2") {
				return "h2";
			} else if (value === "h5") {
				return "h5";
			}
		}
	};

	return (
		<Box className="flex flexColumn center">
			<Typography variant={handleDynamicTextSizing("h2")} color={theme.palette.darkColor.main}>
				Welcome To The Complaint Center!
			</Typography>
			<Typography
				mt={4}
				className="break-line-typography"
				variant={handleDynamicTextSizing("h5")}
				color={theme.palette.darkColor.main}
			>
				{"Please enter your phone number to track your complaints."}
			</Typography>
			<form style={{ width: isMobile ? "100%" : "40%" }} noValidate>
				<Controller
					name="phoneNumber"
					control={control}
					rules={{
						required: "Phone Number is required",
						validate: {
							isPhoneValid: (value) => {
								if (isValidPhoneNumber(value, "PK") === false) {
									return "Phone Number is invalid";
								}
							},
						},
					}}
					render={({ field: { ref, ...rest }, fieldState: { error } }) => (
						<PhoneNumberTextField
							{...rest}
							sx={{ marginTop: 4 }}
							fullWidth
							required
							label="Phone Number"
							error={error ? true : false}
							helperText={error ? error.message : ""}
						/>
					)}
				/>
			</form>
			<ComplaintButton text="View Complaints" onClick={onViewClick} />
		</Box>
	);
}
