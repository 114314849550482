import { IconButton, InputAdornment, StandardTextFieldProps, TextField } from "@mui/material";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type PasswordTextfieldProps = StandardTextFieldProps;

export default function PasswordTextField({ ...props }: PasswordTextfieldProps) {
	const [showPassword, setShowPassword] = useState(false);

	const toggleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	return (
		<TextField
			{...props}
			label="Password"
			type={showPassword ? "text" : "password"}
			autoComplete={"on"}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<IconButton aria-label="toggle password visibility" onClick={toggleShowPassword}>
							{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);
}
