import { MobileDatePickerProps } from "@mui/lab";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";

interface CustomDatePickerProps extends MobileDatePickerProps<Dayjs> {
	fullWidth?: boolean;
	required?: boolean;
	error?: boolean;
	helperText?: string;
}

export default function CustomDatePicker({
	fullWidth,
	required,
	error,
	helperText,
	...pickerProps
}: CustomDatePickerProps) {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<MobileDatePicker
				{...pickerProps}
				views={["year", "month", "day"]}
				format="DD/MM/YYYY"
				slotProps={{
					textField: {
						fullWidth: fullWidth,
						required: required,
						error: error,
						helperText: helperText,
					},
				}}
			/>
		</LocalizationProvider>
	);
}
