import styled from "styled-components";
import { theme } from "../../../theme/theme";

interface FullButtonProps {
	title: string;
	action?: () => void;
	border?: boolean;
}

interface WrapperProps {
	border?: boolean;
}

export default function FullButton({ title, action, border }: FullButtonProps) {
	return (
		<Wrapper className='animate pointer radius8' onClick={action ? () => action() : undefined} border={border}>
			{title}
		</Wrapper>
	);
}

const Wrapper = styled.button<WrapperProps>`
	border: 1px solid ${(props) => (props.border ? "#707070" : theme.palette.primary.main)};
	background-color: ${(props) => (props.border ? "transparent" : theme.palette.primary.main)};
	width: 100%;
	padding: 15px;
	outline: none;
	color: ${(props) => (props.border ? "#707070" : "#fff")};
	:hover {
		background-color: ${(props) => (props.border ? "transparent" : theme.palette.primary.dark)};
		border: 1px solid ${theme.palette.primary.dark};
		color: ${(props) => (props.border ? theme.palette.primary.main : "#fff")};
	}
`;
