import { type MRT_ColumnDef as MRTColumnDef } from "material-react-table";
import { createSearchParams, useNavigate } from "react-router-dom";
import { COMPLAINTS_, REJECTED_COMPLAINTS } from "../../../../constants/textConstants";
import useComplaints from "../../../../hooks/useComplaints";
import { getClientName, getIssueCategoryName, truncate } from "../../../../utils/helpers";
import { Complaint } from "../../../../utils/types";
import Table from "../../Table";
import usePermissions from "../../../../hooks/usePermissions";
import useClients from "../../../../hooks/useClients";
import useIssueCategories from "../../../../hooks/useIssueCategories";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export default function RejectedComplaintTable() {
	const navigate = useNavigate();
	const { role } = useSelector((state: RootState) => state.user);
	const { getPermission } = usePermissions();
	const { complaints, loading } = useComplaints("rejected");
	const { clients, loading: clientsLoading } = useClients();
	const { issueCategories, loading: issueCategoryLoading } = useIssueCategories();

	const complaintColumnsForAdmin: Array<MRTColumnDef<Complaint>> = [
		{
			accessorKey: "subject",
			header: "Subject",
			size: 150,
		},
		{
			accessorKey: "description",
			header: "Description",
			Cell: (row) => <span>{truncate(row.row.original.description, 30)}</span>,
			size: 150,
		},
		{
			accessorKey: "clientId",
			header: "Client",
			Cell: (row) => <span>{getClientName(clients, row.row.original.clientId)}</span>,
			size: 150,
		},
		{
			accessorKey: "issueCategoryId",
			header: "Issue Category",
			Cell: (row) => <span>{getIssueCategoryName(issueCategories, row.row.original.issueCategoryId)}</span>,
			size: 150,
		},
	];

	const complaintColumnsForSupervisor: Array<MRTColumnDef<Complaint>> = [
		{
			accessorKey: "subject",
			header: "Subject",
			size: 150,
		},
		{
			accessorKey: "description",
			header: "Description",
			Cell: (row) => <span>{truncate(row.row.original.description, 30)}</span>,
			size: 150,
		},
		{
			accessorKey: "issueCategoryId",
			header: "Issue Category",
			Cell: (row) => <span>{getIssueCategoryName(issueCategories, row.row.original.issueCategoryId)}</span>,
			size: 150,
		},
	];

	return (
		<Table
			columns={role === "ADMIN" ? complaintColumnsForAdmin : complaintColumnsForSupervisor}
			data={complaints}
			title={REJECTED_COMPLAINTS}
			isLoading={loading || clientsLoading || issueCategoryLoading}
			containerHeight="88vh"
			onRowClick={
				getPermission(COMPLAINTS_, "view_rejected")
					? (event, row) => {
							navigate({
								pathname: `/${COMPLAINTS_}/view`,
								search: createSearchParams({
									userId: row.original.userId,
									complaintId: row.original.id,
								}).toString(),
							});
					  }
					: undefined
			}
		/>
	);
}
