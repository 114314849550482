import styled from "styled-components";
// Assets
import SECP from "../../../assets/img/secp-logo.png";
import FBR from "../../../assets/img/fbr-logo.png";
import SBR from "../../../assets/img/srb-logo.png";
import EOBI from "../../../assets/img/eobi_logo.png";

interface ServiceBoxProps {
	icon: string;
	title: string;
	subtitle: string;
}

export default function ServiceBox({ icon, title, subtitle }: ServiceBoxProps) {
	let getIcon;

	switch (icon) {
		case "fbr":
			getIcon = FBR;
			break;
		case "sbr":
			getIcon = SBR;
			break;
		case "secp":
			getIcon = SECP;
			break;
		case "eobi":
			getIcon = EOBI;
			break;
		default:
			getIcon = SECP;
			break;
	}

	return (
		<Wrapper className="flex flexColumn">
			<IconStyle>
				<img src={getIcon} />
			</IconStyle>
			<TitleStyle className="font20 extraBold">{title}</TitleStyle>
			<SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	width: 100%;
`;
const IconStyle = styled.div`
	@media (min-width: 860px) {
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@media (max-width: 860px) {
		margin: 0 auto;
	}
`;
const TitleStyle = styled.h2`
	text-align: center;
	width: 100%;
	max-width: 300px;
	margin: 0 auto;
	padding: 40px 0;
	@media (max-width: 860px) {
		padding: 20px 0;
	}
`;
const SubtitleStyle = styled.p`
	width: 100%;
	max-width: 300px;
	margin: 0 auto;
`;
