import { enqueueSnackbar } from "notistack";
import AdminFields from "../../../components/authedComponents/crudComponents/Admin/AdminFields";
import { addUser } from "../../../firebase/config";
import { genericErrorFunction } from "../../../utils/helpers";
import { AddAdminPayloadInterface } from "../../../utils/types";
import { useNavigate } from "react-router-dom";

export default function AddAdmin() {
	const navigate = useNavigate();
	const addAdmin = async (payload: AddAdminPayloadInterface) => {
		try {
			await addUser(payload).then((response) => {
				if (response.data.status === "success") {
					enqueueSnackbar("Admin Created!", { variant: "success" });
					navigate(-1);
				}
			});
		} catch (error) {
			genericErrorFunction({ logMessage: "Error occurred while adding admin: ", error });
		}
	};

	return <AdminFields type="Add" apiCall={addAdmin} />;
}
