import { addDoc, collection } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import IssueCategoryFields from "../../../components/authedComponents/crudComponents/IssueCategory/IssueCategoryFields";
import { ISSUE_CATEGORIES_COLLECTION } from "../../../constants/firebaseConstants";
import { firestore } from "../../../firebase/config";
import { genericErrorFunction } from "../../../utils/helpers";
import { AddIssueCategoryPayloadInterface } from "../../../utils/types";

export default function AddIssueCategories() {
	const navigate = useNavigate();
	const addIssueCategory = async (payload: AddIssueCategoryPayloadInterface) => {
		try {
			await addDoc(collection(firestore, ISSUE_CATEGORIES_COLLECTION), payload)
				.then(() => {
					enqueueSnackbar("Issue Category Created!", { variant: "success" });
				})
				.finally(() => {
					navigate(-1);
				});
		} catch (error) {
			genericErrorFunction({ logMessage: "Error occurred while adding issue category: ", error });
		}
	};

	return <IssueCategoryFields type="Add" apiCall={addIssueCategory} />;
}
