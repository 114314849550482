import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
	Autocomplete,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from "@mui/material";
import { addDoc, collection, doc, serverTimestamp, setDoc, Timestamp } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { INVENTORY_COLLECTION } from "../../../../constants/firebaseConstants";
import { INVENTORY } from "../../../../constants/textConstants";
import { firestore } from "../../../../firebase/config";
import {
	addInventoryLog,
	capitalizeFirstLetter,
	genericErrorFunction,
	getClientBasedOnRole,
} from "../../../../utils/helpers";
import { AddInventoryPayload, Client, EditInventoryPayload, Inventory } from "../../../../utils/types";
import NumberTextField from "../../NumberTextField";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import useClients from "../../../../hooks/useClients";

interface InventoryFieldsModalProps {
	state: "add" | "edit";
	inventoryItem: Inventory | null;
	open: boolean;
	onClose: () => void;
	refetch: () => void;
}

interface InventoryFieldsForm {
	name: string;
	quantity: number | null;
	client: Client | null;
	description: string;
	note: string;
}

export default function InventoryFieldsModal({
	state,
	inventoryItem,
	open,
	onClose,
	refetch,
}: InventoryFieldsModalProps) {
	const {
		id: currentUserId,
		role: loggedInUserRole,
		clientId: loggedInUserClient,
		firstName: loggedInUserFirstName,
		lastName: loggedInUserLastName,
	} = useSelector((state: RootState) => state.user);
	const { clients, loading: clientLoading } = useClients();
	const [loading, setLoading] = useState(false);

	const { control, handleSubmit, reset } = useForm<InventoryFieldsForm>({
		mode: "onTouched",
		defaultValues: {
			name: "",
			quantity: null,
			client: null,
			description: "",
			note: "",
		},
	});

	const liveQuantity = useWatch({
		name: "quantity",
		control: control,
	});

	const handleOnClose = () => {
		if (!loading) {
			reset({
				name: "",
				quantity: null,
				client: null,
				description: "",
				note: "",
			});
			onClose();
		}
	};

	useEffect(() => {
		if (inventoryItem && state === "edit") {
			reset({
				name: inventoryItem.name,
				quantity: inventoryItem.quantity,
				client: clients.find((item) => item.id === inventoryItem.clientId) || null,
				description: inventoryItem.description,
			});
		}
	}, [inventoryItem]);

	const addInventory = async (payload: AddInventoryPayload) => {
		try {
			setLoading(true);
			await addDoc(collection(firestore, INVENTORY_COLLECTION), payload)
				.then(async (response) => {
					await addInventoryLog(
						{
							inventoryItemId: response.id,
							quantityUpdatedFrom: 0,
							quantityUpdatedTo: payload.quantity,
							updateNote: "Item Created",
							createdAt: serverTimestamp() as Timestamp,
							createdBy: currentUserId,
							createdByName: loggedInUserFirstName + " " + loggedInUserLastName,
						},
						undefined,
						"Inventory Item Created!",
					);
				})
				.finally(() => {
					setLoading(false);
					reset({
						name: "",
						quantity: null,
						client: null,
						description: "",
						note: "",
					});
					refetch();
					onClose();
				});
		} catch (error) {
			genericErrorFunction({ logMessage: "Error occurred while adding inventory: ", error });
		}
	};

	const editInventory = async (payload: EditInventoryPayload, note: string) => {
		if (inventoryItem) {
			try {
				setLoading(true);
				const { id, ...rest } = payload;
				await setDoc(doc(firestore, INVENTORY_COLLECTION, id), rest, { merge: true })
					.then(async () => {
						// Only creating logs if quantity is different
						if (inventoryItem.quantity !== payload.quantity) {
							await addInventoryLog(
								{
									inventoryItemId: inventoryItem.id,
									quantityUpdatedFrom: inventoryItem.quantity,
									quantityUpdatedTo: payload.quantity,
									updateNote: note,
									createdAt: serverTimestamp() as Timestamp,
									createdBy: currentUserId,
									createdByName: loggedInUserFirstName + " " + loggedInUserLastName,
								},
								undefined,
								"Inventory Item Edited!",
							);
						} else {
							enqueueSnackbar("Inventory Item Edited!", { variant: "success" });
						}
					})
					.finally(() => {
						setLoading(false);
						reset({
							name: "",
							quantity: null,
							client: null,
							description: "",
							note: "",
						});
						refetch();
						onClose();
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while editing inventory: ", error });
			}
		}
	};

	const onSubmit: SubmitHandler<InventoryFieldsForm> = async (data) => {
		setLoading(true);
		if (state === "add") {
			const payload = {
				name: data.name,
				quantity: Number(data.quantity) ? Number(data.quantity) : 0,
				clientId: getClientBasedOnRole(loggedInUserRole, loggedInUserClient, data.client, clients).id,
				description: data.description ? data.description : "",
				createdAt: serverTimestamp() as Timestamp,
			};
			await addInventory(payload);
		} else if (state === "edit" && inventoryItem) {
			const payload = {
				id: inventoryItem.id,
				name: data.name,
				quantity: Number(data.quantity) ? Number(data.quantity) : 0,
				clientId: getClientBasedOnRole(loggedInUserRole, loggedInUserClient, data.client, clients).id,
				description: data.description ? data.description : "",
			};
			await editInventory(payload, data.note ? data.note : "");
		}
	};

	return (
		<Dialog aria-modal open={open} onClose={handleOnClose}>
			<DialogTitle>{capitalizeFirstLetter(state) + " " + INVENTORY + " " + "Item"}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<DialogContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							marginTop: "20px",
						}}
					>
						<Controller
							name="name"
							control={control}
							rules={{
								required: "Item is required",
							}}
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									fullWidth
									required
									label="Item"
									error={error ? true : false}
									helperText={error ? error.message : ""}
								/>
							)}
						/>
						<Controller
							name="quantity"
							control={control}
							rules={{
								required: "Quantity is required",
								validate: {
									isGreaterThanEqualToZero: (value) => {
										if (Number(value) < 0) {
											return "Value cannot be a negative number";
										}
									},
								},
							}}
							render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
								<NumberTextField
									type="number"
									{...rest}
									value={value ? value : ""}
									fullWidth
									required
									label="Quantity"
									error={error ? true : false}
									helperText={error ? error.message : ""}
								/>
							)}
						/>
						{inventoryItem && inventoryItem.quantity !== Number(liveQuantity) ? (
							<Controller
								name="note"
								control={control}
								render={({ field: { ref, ...rest }, fieldState: { error } }) => (
									<TextField
										{...rest}
										multiline
										minRows={3}
										maxRows={5}
										fullWidth
										label="Note"
										placeholder="Enter any note relevant to the item quantity update"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						) : (
							<></>
						)}
						{loggedInUserRole === "ADMIN" && loggedInUserClient === null && (
							<Controller
								name="client"
								control={control}
								rules={
									loggedInUserRole === "ADMIN" && loggedInUserClient === null
										? {
												required: "Client is required",
										  }
										: {}
								}
								render={({ field: { ref, value, onChange, ...rest }, fieldState: { error } }) => {
									return (
										<Autocomplete
											disablePortal
											disabled={clientLoading}
											options={clients}
											value={value}
											onChange={(e, value) => {
												onChange(value);
											}}
											isOptionEqualToValue={(option, value) => {
												return option.id === value.id;
											}}
											getOptionLabel={(value) => value.name || ""}
											renderInput={(params) => (
												<TextField
													{...params}
													{...rest}
													label="Client"
													required
													error={error ? true : false}
													helperText={error ? error.message : ""}
												/>
											)}
										/>
									);
								}}
							/>
						)}
						<Controller
							name="description"
							control={control}
							render={({ field: { ref, ...rest }, fieldState: { error } }) => (
								<TextField
									{...rest}
									multiline
									minRows={3}
									maxRows={5}
									fullWidth
									label="Description"
									placeholder="Enter any description or notes"
									error={error ? true : false}
									helperText={error ? error.message : ""}
								/>
							)}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						disabled={loading}
						loading={loading}
						loadingPosition="start"
						startIcon={<FontAwesomeIcon icon={faCheck} />}
						type="submit"
					>
						Yes
					</LoadingButton>
					<Button disabled={loading} startIcon={<FontAwesomeIcon icon={faXmark} />} onClick={handleOnClose}>
						No
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
