import { Dayjs } from "dayjs";
import { doc, getDoc } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { INVENTORY_COLLECTION } from "../constants/firebaseConstants";
import { firestore } from "../firebase/config";
import { genericErrorFunction, getItemLogs } from "../utils/helpers";
import { InventoryItem } from "../utils/types";

export default function useInventoryItem(itemId: string | null) {
	const navigate = useNavigate();
	const [inventoryItem, setInventoryItem] = useState<InventoryItem | null>(null);
	const [loading, setLoading] = useState(true);

	const getInventoryItem = async (fromDate?: Dayjs, toDate?: Dayjs) => {
		if (itemId) {
			try {
				setLoading(true);
				const inventoryQuery = doc(firestore, INVENTORY_COLLECTION, itemId);

				await getDoc(inventoryQuery)
					.then(async (doc) => {
						if (doc.exists()) {
							const data = doc.data() as Omit<InventoryItem, "id">;
							const logs = await getItemLogs(itemId, fromDate, toDate);
							const item: InventoryItem = {
								id: doc.id,
								name: data.name,
								quantity: data.quantity,
								clientId: data.clientId,
								description: data.description,
								createdAt: data.createdAt,
								logs: logs,
							};
							setInventoryItem(item);
						} else {
							enqueueSnackbar("Item does not exist", { variant: "error" });
							navigate(-1);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching inventory item: ", error });
			}
		}
	};

	useEffect(() => {
		getInventoryItem();
	}, [itemId]);

	return { inventoryItem, getInventoryItem, loading };
}
