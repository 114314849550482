import { doc, getDoc } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../firebase/config";
import { addMissingSectionsToPreSelectedPermissions, genericErrorFunction } from "../utils/helpers";
import { Supervisor } from "../utils/types";
import { USERS_COLLECTION } from "../constants/firebaseConstants";

export default function useSupervisor(id: string | null) {
	const navigate = useNavigate();
	const [supervisor, setSupervisor] = useState<Supervisor | null>(null);
	const [loading, setLoading] = useState(false);

	const getSupervisor = async () => {
		if (id) {
			try {
				setLoading(true);
				const supervisorQuery = doc(firestore, USERS_COLLECTION, id);

				await getDoc(supervisorQuery)
					.then((doc) => {
						if (doc.exists()) {
							const data = doc.data();
							const supervisor: Supervisor = {
								id: doc.id,
								firstName: data.firstName,
								lastName: data.lastName,
								email: data.email,
								cnic: data.cnic,
								role: data.role,
								info: data.info ? data.info : null,
								phoneNumber: data.phoneNumber,
								clientId: data.clientId,
								projects: data.projects,
								issueCategoryIds: data.issueCategoryIds,
								permissions: addMissingSectionsToPreSelectedPermissions(data.permissions),
							};
							setSupervisor(supervisor);
						} else {
							enqueueSnackbar("Supervisor does not exist", { variant: "error" });
							navigate(-1);
						}
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching supervisor: ", error });
			}
		}
	};

	useEffect(() => {
		getSupervisor();
	}, [id]);

	return { supervisor, loading };
}
