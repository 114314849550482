import { deleteDoc, doc } from "firebase/firestore";
import { type MRT_ColumnDef as MRTColumnDef } from "material-react-table";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ISSUE_CATEGORIES_COLLECTION } from "../../../../constants/firebaseConstants";
import { ISSUE_CATEGORIES, ISSUE_CATEGORIES_ } from "../../../../constants/textConstants";
import { firestore } from "../../../../firebase/config";
import useIssueCategories from "../../../../hooks/useIssueCategories";
import { genericErrorFunction, truncate } from "../../../../utils/helpers";
import { IssueCategory } from "../../../../utils/types";
import ConfirmationModal from "../../ConfirmationModal";
import Table from "../../Table";
import usePermissions from "../../../../hooks/usePermissions";

export default function IssueCategoryTable() {
	const navigate = useNavigate();
	const { getPermission } = usePermissions();
	const { issueCategories, loading, refetch } = useIssueCategories();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [selectedIssueCategory, setSelectedIssueCategory] = useState<IssueCategory | null>(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const deleteClient = async () => {
		if (selectedIssueCategory) {
			try {
				setDeleteLoading(true);
				await deleteDoc(doc(firestore, ISSUE_CATEGORIES_COLLECTION, selectedIssueCategory.id))
					.then(() => {
						enqueueSnackbar("Issue category Deleted!", { variant: "success" });
					})
					.finally(() => {
						setDeleteLoading(false);
						setOpenDeleteModal(false);
						setSelectedIssueCategory(null);
						refetch(true);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while deleting issue category: ", error });
			}
		}
	};

	const issueCategoryColumns: Array<MRTColumnDef<IssueCategory>> = [
		{
			accessorKey: "name",
			header: "Name",
			size: 150,
		},
		{
			accessorKey: "description",
			header: "Description",
			Cell: (row) => <span>{truncate(row.row.original.description, 70)}</span>,
			size: 150,
		},
	];

	return (
		<>
			<Table
				columns={issueCategoryColumns}
				data={issueCategories}
				title={ISSUE_CATEGORIES}
				isLoading={loading}
				addButtonOnClick={
					getPermission(ISSUE_CATEGORIES_, "issue_category_add")
						? () => {
								navigate(`/${ISSUE_CATEGORIES_}/add`);
						  }
						: undefined
				}
				editButtonOnClick={
					getPermission(ISSUE_CATEGORIES_, "issue_category_edit")
						? (row) => {
								navigate({
									pathname: `/${ISSUE_CATEGORIES_}/edit`,
									search: createSearchParams({
										id: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
				deleteButtonOnClick={
					getPermission(ISSUE_CATEGORIES_, "issue_category_delete")
						? (row) => {
								setOpenDeleteModal(true);
								setSelectedIssueCategory(row.original);
						  }
						: undefined
				}
				onRowClick={
					getPermission(ISSUE_CATEGORIES_, "issue_category_view")
						? (event, row) => {
								navigate({
									pathname: `/${ISSUE_CATEGORIES_}/view`,
									search: createSearchParams({
										id: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
			/>
			<ConfirmationModal
				open={openDeleteModal}
				onClose={() => {
					setOpenDeleteModal(false);
					setSelectedIssueCategory(null);
				}}
				title="Delete Issue Category"
				description="Are you sure you want to delete the following issue category. This action is irreversible."
				apiCall={deleteClient}
				loading={deleteLoading}
			/>
		</>
	);
}
