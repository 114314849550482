import { StandardTextFieldProps, TextField } from "@mui/material";
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js/max";

interface PhoneNumberTextFieldProps extends Omit<StandardTextFieldProps, "value"> {
	value: string;
}

export default function PhoneNumberTextField({ value, ...rest }: PhoneNumberTextFieldProps) {
	const formattedValue = new AsYouType("PK").input(value);
	const isPhoneNumberValid = isValidPhoneNumber(value, "PK");

	const checkIfEnteredKeyShouldBeAllowed = (e: React.KeyboardEvent<HTMLDivElement>) => {
		// If phone number is invalid then just allow number keys and a few others
		if (!isPhoneNumberValid) {
			if (
				Number(e.key) === 0 ||
				Number(e.key) === 1 ||
				Number(e.key) === 2 ||
				Number(e.key) === 3 ||
				Number(e.key) === 4 ||
				Number(e.key) === 5 ||
				Number(e.key) === 6 ||
				Number(e.key) === 7 ||
				Number(e.key) === 8 ||
				Number(e.key) === 9 ||
				e.key === "+" ||
				e.key === "Backspace" ||
				e.key === "Delete" ||
				e.key === "ArrowLeft" ||
				e.key === "ArrowRight" ||
				e.key === "Tab" ||
				(e.ctrlKey && e.key === "a") ||
				(e.ctrlKey && e.key === "c") ||
				(e.ctrlKey && e.key === "v")
			) {
				return;
			}
		}
		// If phone number is valid then only allow user to move around the field
		else if (
			isPhoneNumberValid &&
			(e.key === "Backspace" ||
				e.key === "Delete" ||
				e.key === "ArrowLeft" ||
				e.key === "ArrowRight" ||
				e.key === "Tab" ||
				(e.ctrlKey && e.key === "a") ||
				(e.ctrlKey && e.key === "c") ||
				(e.ctrlKey && e.key === "v"))
		) {
			return;
		}
		// otherwise just prevent any key presses
		e.preventDefault();
	};

	return (
		<TextField
			{...rest}
			onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
				checkIfEnteredKeyShouldBeAllowed(e);
			}}
			value={formattedValue}
		/>
	);
}
