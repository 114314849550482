// Sections
import { Box } from "@mui/material";
import ComplaintFAB from "../../components/unAuthedComponents/Complaints/ComplaintFAB";
import TopNavbar from "../../components/unAuthedComponents/Nav/TopNavbar";
import Contact from "../../components/unAuthedComponents/Sections/Contact";
import Footer from "../../components/unAuthedComponents/Sections/Footer";
import Header from "../../components/unAuthedComponents/Sections/Header";
import Projects from "../../components/unAuthedComponents/Sections/Projects";
import Services from "../../components/unAuthedComponents/Sections/Services";

export default function Landing() {
	return (
		<>
			<Box>
				<ComplaintFAB />
				<TopNavbar />
				<Header />
				<Services />
				<Projects />
				<Contact />
			</Box>
			<Footer />
		</>
	);
}
