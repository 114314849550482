import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { doc, serverTimestamp, setDoc, Timestamp } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { INVENTORY_COLLECTION } from "../../../../constants/firebaseConstants";
import { firestore } from "../../../../firebase/config";
import { RootState } from "../../../../redux/store";
import { addInventoryLog, genericErrorFunction } from "../../../../utils/helpers";
import { Inventory, InventoryLog } from "../../../../utils/types";
import NumberTextField from "../../NumberTextField";

interface InventoryIncrementModalProps {
	state: "add" | "subtract";
	inventoryItem: Inventory | null;
	open: boolean;
	onClose: () => void;
	refetch: () => void;
}

interface InventoryIncrementForm {
	quantity: number | null;
	note: string;
}

export default function InventoryChangeModal({
	state,
	inventoryItem,
	open,
	onClose,
	refetch,
}: InventoryIncrementModalProps) {
	const { id: currentUserId, firstName, lastName } = useSelector((state: RootState) => state.user);
	const [loading, setLoading] = useState(false);

	const { control, handleSubmit, reset } = useForm<InventoryIncrementForm>({
		mode: "onTouched",
		defaultValues: {
			quantity: null,
			note: "",
		},
	});

	const handleOnClose = () => {
		if (!loading) {
			reset({
				quantity: null,
				note: "",
			});
			onClose();
		}
	};

	const getQuantityToUpdate = (oldAmount: number, newAmount: number) => {
		if (state === "add") {
			return oldAmount + newAmount;
		} else if (state === "subtract") {
			return oldAmount - newAmount;
		} else {
			return 0;
		}
	};

	const onSubmit: SubmitHandler<InventoryIncrementForm> = async (data) => {
		if (inventoryItem) {
			try {
				setLoading(true);
				await setDoc(
					doc(firestore, INVENTORY_COLLECTION, inventoryItem.id),
					{ quantity: getQuantityToUpdate(inventoryItem.quantity, Number(data.quantity)) },
					{ merge: true },
				)
					.then(async () => {
						const payload: Omit<InventoryLog, "id"> = {
							inventoryItemId: inventoryItem.id,
							quantityUpdatedFrom: inventoryItem.quantity,
							quantityUpdatedTo: getQuantityToUpdate(inventoryItem.quantity, Number(data.quantity)),
							updateNote: data.note ? data.note : "",
							createdAt: serverTimestamp() as Timestamp,
							createdBy: currentUserId,
							createdByName: firstName + " " + lastName,
						};

						await addInventoryLog(payload, undefined, "Item Quantity Updated!");
					})
					.finally(() => {
						setLoading(false);
						reset({
							quantity: null,
							note: "",
						});
						refetch();
						onClose();
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while incrementing inventory: ", error });
			}
		}
	};

	const getTitle = () => {
		if (state === "add") {
			return "Increment Quantity";
		} else if (state === "subtract") {
			return "Issue Quantity";
		} else {
			return "";
		}
	};

	const getLabel = () => {
		if (state === "add") {
			return "Quantity to add";
		} else if (state === "subtract") {
			return "Quantity to issue";
		} else {
			return "";
		}
	};

	return (
		<Dialog aria-modal open={open} onClose={handleOnClose}>
			<DialogTitle>{getTitle()}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<DialogContent>
					{inventoryItem && (
						<Box
							mb={2}
							sx={{
								display: "flex",
								gap: 1,
							}}
						>
							<Typography>Current Quantity: </Typography>
							<Typography>{inventoryItem.quantity}</Typography>
						</Box>
					)}
					<Controller
						name="quantity"
						control={control}
						rules={{
							required: `${getLabel()} is required`,
							validate:
								state === "subtract"
									? {
											isGreaterThanZero: (value) => {
												if (Number(value) <= 0) {
													return `${getLabel()} must be greater than 0`;
												}
											},
											isLessThanEqualToOldValue: (value) => {
												if (inventoryItem && Number(value) > inventoryItem.quantity) {
													return `${getLabel()} cannot be greater than current quantity`;
												}
											},
									  }
									: {
											isGreaterThanZero: (value) => {
												if (Number(value) <= 0) {
													return `${getLabel()} must be greater than 0`;
												}
											},
									  },
						}}
						render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
							<NumberTextField
								type="number"
								{...rest}
								value={value ? value : ""}
								fullWidth
								required
								label={getLabel()}
								error={error ? true : false}
								helperText={error ? error.message : ""}
							/>
						)}
					/>
					<Box mt={2}>
						<Controller
							name="note"
							control={control}
							render={({ field: { ref, ...rest }, fieldState: { error } }) => (
								<TextField
									{...rest}
									multiline
									minRows={3}
									maxRows={5}
									fullWidth
									label="Note"
									placeholder="Enter any note relevant to the item quantity update"
									error={error ? true : false}
									helperText={error ? error.message : ""}
								/>
							)}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						disabled={loading}
						loading={loading}
						loadingPosition="start"
						startIcon={<FontAwesomeIcon icon={faCheck} />}
						type="submit"
					>
						Yes
					</LoadingButton>
					<Button disabled={loading} startIcon={<FontAwesomeIcon icon={faXmark} />} onClick={handleOnClose}>
						No
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
