import { signInAnonymously } from "firebase/auth";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { isValidPhoneNumber } from "libphonenumber-js/max";
import { useEffect, useState } from "react";
import { COMPLAINTS_TRACKING_COLLECTION } from "../constants/firebaseConstants";
import { auth, firestore } from "../firebase/config";
import { formatPhoneNumber, genericErrorFunction } from "../utils/helpers";
import { ComplaintTracking } from "../utils/types";

export default function useComplaintsTrackingUnAuth(phoneNumber: string) {
	const [complaintsTracking, setComplaintsTracking] = useState<Array<ComplaintTracking>>([]);
	const [loading, setLoading] = useState(true);

	const getData = async () => {
		const complaintsQuery = query(
			collection(firestore, COMPLAINTS_TRACKING_COLLECTION),
			where("phoneNumber", "==", formatPhoneNumber(phoneNumber)),
			orderBy("createdAt", "desc"),
		);

		await getDocs(complaintsQuery)
			.then((snapshot) => {
				snapshot.forEach((doc) => {
					const complaintsTracking = doc.data() as ComplaintTracking;
					setComplaintsTracking((prev) => [...prev, complaintsTracking]);
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const getComplaintsTracking = async () => {
		if (phoneNumber && isValidPhoneNumber(phoneNumber, "PK")) {
			setComplaintsTracking([]);
			setLoading(true);
			try {
				// If an anon user exists we use that
				if (auth.currentUser && auth.currentUser.isAnonymous) {
					await getData();
				}
				// else if anon user does not exist we create one
				else if (auth.currentUser === null) {
					await signInAnonymously(auth).then(async (user) => {
						await getData();
					});
				}
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching complaintsTracking: ", error });
			}
		}
	};

	useEffect(() => {
		getComplaintsTracking();
	}, [phoneNumber]);

	return { complaintsTracking, loading };
}
