import { createSlice } from "@reduxjs/toolkit";
import { Client, IssueCategory, User } from "../utils/types";

interface userInterface extends User {
	issueCategories: Array<string> | null;
	clientId: string | null;
}

const initialState: userInterface = {
	id: "",
	firstName: "",
	lastName: "",
	email: "",
	cnic: "",
	role: null,
	info: null,
	issueCategories: null,
	clientId: null,
	permissions: [],
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUserData: (state, action) => {
			state.id = action.payload.id;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.email = action.payload.email;
			state.cnic = action.payload.cnic;
			state.role = action.payload.role;
			state.info = action.payload.info;
			state.issueCategories = action.payload.issueCategories;
			state.clientId = action.payload.client;
			state.permissions = action.payload.permissions;
		},
		resetUser: (state) => {
			return initialState;
		},
	},
});

export const { setUserData, resetUser } = userSlice.actions;

export default userSlice.reducer;
