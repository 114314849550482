import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
// Assets
import AddImage1 from "../../../assets/img/add/1.jpg";
import AddImage3 from "../../../assets/img/add/3.jpg";
import AddImage4 from "../../../assets/img/add/4.jpg";
import { scrollToElement } from "../../../utils/helpers";

export default function Services() {
	return (
		<Wrapper id="services">
			<div className="lightBg" style={{ padding: "50px 0" }}>
				<div className="container">
					<ClientSlider />
				</div>
			</div>
			<div className="whiteBg" style={{ padding: "60px 0" }}>
				<div className="container">
					<HeaderInfo>
						<h1 className="font40 extraBold">Registration and Certification</h1>
						<p className="font13">
							<br />
						</p>
					</HeaderInfo>
					<ServiceBoxRow className="flex">
						<ServiceBoxWrapper>
							<ServiceBox icon="fbr" title="FBR (Federal Board Of Revenue)" subtitle="" />
						</ServiceBoxWrapper>
						<ServiceBoxWrapper>
							<ServiceBox icon="sbr" title="SRB (Sindh Revenue Board)" subtitle="" />
						</ServiceBoxWrapper>
						<ServiceBoxWrapper>
							<ServiceBox icon="secp" title="SECP (Security and Exchange Commission)" subtitle="" />
						</ServiceBoxWrapper>
						<ServiceBoxWrapper>
							<ServiceBox
								icon="eobi"
								title="EOBI (Employees' Old-Age Benefits Institution)"
								subtitle=""
							/>
						</ServiceBoxWrapper>
					</ServiceBoxRow>
				</div>
				<div className="lightBg">
					<div className="container">
						<Advertising className="flexSpaceCenter">
							<AddLeft>
								{/* <h4 className="font15 semiBold">A few words about our Team</h4> */}
								<h2 className="font40 extraBold">Our Team</h2>
								<p className="font15">
									At Numla Asia, we pride ourselves on having a dynamic, highly skilled, and dedicated
									team of HR professionals. Our team brings together extensive expertise in
									contractual staffing, payroll processing, and maintenance, ensuring that we meet the
									diverse needs of our clients across industries. Each member is committed to
									delivering personalized, efficient, and reliable solutions that align with our core
									values of integrity, efficiency, and excellence. We work collaboratively to ensure
									seamless service delivery, making Numla Asia Pvt Ltd a trusted partner for all HR
									and operational needs.
								</p>
								<ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
									{/* <div style={{ width: "190px" }}>
                    <FullButton title="Get Started" action={() => alert("clicked")} />
                  </div> */}
									<div style={{ width: "190px", marginLeft: "15px" }}>
										<FullButton
											title="Contact Us"
											border
											action={() => {
												scrollToElement("contact");
											}}
										/>
									</div>
								</ButtonsRow>
							</AddLeft>
							<AddRight>
								<AddRightInner>
									<div className="flexNullCenter">
										<AddImgWrapp1 className="flexCenter">
											<img src={AddImage1} alt="office" />
										</AddImgWrapp1>
									</div>
									<div className="flexNullCenter">
										<AddImgWrapp4>
											<img src={AddImage4} alt="office" />
										</AddImgWrapp4>
										<AddImgWrapp3>
											<img src={AddImage3} alt="office" />
										</AddImgWrapp3>
									</div>
								</AddRightInner>
							</AddRight>
						</Advertising>
					</div>
				</div>
			</div>
		</Wrapper>
	);
}

const Wrapper = styled.section`
	width: 100%;
`;
const ServiceBoxRow = styled.div`
	@media (max-width: 860px) {
		flex-direction: column;
	}
`;
const ServiceBoxWrapper = styled.div`
	width: 20%;
	margin-right: 5%;
	padding: 80px 0;
	@media (max-width: 860px) {
		width: 100%;
		text-align: center;
		padding: 40px 0;
	}
`;
const HeaderInfo = styled.div`
	@media (max-width: 860px) {
		text-align: center;
	}
`;
const Advertising = styled.div`
	margin: 80px 0;
	padding: 100px 0;
	position: relative;
	@media (max-width: 1160px) {
		padding: 100px 0 40px 0;
	}
	@media (max-width: 860px) {
		flex-direction: column;
		padding: 0 0 30px 0;
		margin: 80px 0 0px 0;
	}
`;
const ButtonsRow = styled.div`
	@media (max-width: 860px) {
		justify-content: space-between;
	}
`;
const AddLeft = styled.div`
	width: 50%;
	p {
		max-width: 475px;
	}
	@media (max-width: 860px) {
		width: 80%;
		order: 2;
		text-align: center;
		h2 {
			line-height: 3rem;
			margin: 15px 0;
		}
		p {
			margin: 0 auto;
		}
	}
`;
const AddRight = styled.div`
	width: 50%;
	position: absolute;
	top: -70px;
	right: 0;
	@media (max-width: 860px) {
		width: 80%;
		position: relative;
		order: 1;
		top: -40px;
	}
`;
const AddRightInner = styled.div`
	width: 100%;
`;
const AddImgWrapp1 = styled.div`
	width: 100%;
	margin: 60px 6% 10px 6%;
	img {
		width: 100%;
		height: auto;
		border-radius: 1rem;
		box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
		-moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
	}
`;
const AddImgWrapp3 = styled.div`
	width: 70%;
	// margin-left: 40%;
	img {
		width: 100%;
		height: auto;
		border-radius: 1rem;
		box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
		-moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
	}
`;
const AddImgWrapp4 = styled.div`
	width: 30%;
	margin: 0 5%auto;
	img {
		width: 100%;
		height: auto;
		border-radius: 1rem;
		box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
		-webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
		-moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
	}
`;
