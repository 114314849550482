import { Box } from "@mui/material";
import SupervisorTable from "../../../components/authedComponents/crudComponents/Supervisor/SupervisorTable";

export default function Supervisors() {
	return (
		<Box
			sx={{
				height: "100%",
			}}
		>
			<SupervisorTable />
		</Box>
	);
}
