import { collection, getDocs, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { CLIENTS_COLLECTION } from "../constants/firebaseConstants";
import { firestore } from "../firebase/config";
import { genericErrorFunction } from "../utils/helpers";
import { Client } from "../utils/types";

export default function useClients() {
	const [clients, setClients] = useState<Array<Client>>([]);
	const [loading, setLoading] = useState(true);
	const [shouldFetch, refetch] = useState(true);

	const getClients = async () => {
		if (shouldFetch) {
			setClients([]);
			setLoading(true);
			try {
				const clientsQuery = query(collection(firestore, CLIENTS_COLLECTION));

				await getDocs(clientsQuery)
					.then((snapshot) => {
						snapshot.forEach((doc) => {
							const data = doc.data();
							const client: Client = {
								id: doc.id,
								name: data.name,
								description: data.description,
								projects: data.projects,
							};
							setClients((prev) => [...prev, client]);
						});
					})
					.finally(() => {
						setLoading(false);
						refetch(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching clients: ", error });
			}
		}
	};

	useEffect(() => {
		getClients();
	}, [shouldFetch]);

	return { clients, loading, refetch };
}
