import { collection, getDocs, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { USERS_COLLECTION } from "../constants/firebaseConstants";
import { firestore } from "../firebase/config";
import { addMissingSectionsToPreSelectedPermissions, genericErrorFunction } from "../utils/helpers";
import { Supervisor } from "../utils/types";

export default function useSupervisors() {
	const [supervisors, setSupervisors] = useState<Array<Supervisor>>([]);
	const [loading, setLoading] = useState(true);
	const [shouldFetch, refetch] = useState(true);

	const getSupervisors = async () => {
		if (shouldFetch) {
			setSupervisors([]);
			setLoading(true);
			try {
				const supervisorsQuery = query(
					collection(firestore, USERS_COLLECTION),
					where("role", "==", "SUPERVISOR"),
				);

				await getDocs(supervisorsQuery)
					.then((snapshot) => {
						snapshot.forEach((doc) => {
							const data = doc.data();
							const supervisor: Supervisor = {
								id: doc.id,
								firstName: data.firstName,
								lastName: data.lastName,
								email: data.email,
								cnic: data.cnic,
								role: data.role,
								info: data.info ? data.info : null,
								phoneNumber: data.phoneNumber,
								clientId: data.clientId,
								projects: data.projects,
								issueCategoryIds: data.issueCategoryIds,
								permissions: addMissingSectionsToPreSelectedPermissions(data.permissions),
							};
							setSupervisors((prev) => [...prev, supervisor]);
						});
					})
					.finally(() => {
						setLoading(false);
						refetch(false);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching supervisors: ", error });
			}
		}
	};

	useEffect(() => {
		getSupervisors();
	}, [shouldFetch]);

	return { supervisors, loading, refetch };
}
