import { Autocomplete, Grid2, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import useClientsUnAuth from "../../../hooks/useClientsUnAuth";
import useIssueCategoriesUnAuth from "../../../hooks/useIssueCategoriesUnAuth";
import { ComplaintFields } from "../../../pages/unAuthedPages/AddComplaint";
import ComplaintFieldsLayout from "./ComplaintFieldsLayout";

interface ComplaineeInformationProps {
	control: Control<ComplaintFields, any>;
	onNextClick: () => Promise<void>;
	loading: boolean;
}

export default function ComplaineeInformation({ control, onNextClick, loading }: ComplaineeInformationProps) {
	const { clients, loading: clientLoading } = useClientsUnAuth();
	const { issueCategories, loading: IssueCategoryLoading } = useIssueCategoriesUnAuth();

	return (
		<ComplaintFieldsLayout title="Provide Details of The Complainee." onButtonClick={onNextClick} loading={loading}>
			<Grid2 container spacing={2}>
				<Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
					<Controller
						name="complaintCompany"
						control={control}
						rules={{
							required: "Company is required",
						}}
						render={({ field: { ref, value, onChange, ...rest }, fieldState: { error } }) => (
							<Autocomplete
								disablePortal
								disabled={clientLoading || loading}
								options={clients}
								value={value}
								onChange={(e, value) => {
									onChange(value);
								}}
								isOptionEqualToValue={(option, value) => {
									return option.id === value.id;
								}}
								getOptionLabel={(value) => value.name || ""}
								renderInput={(params) => (
									<TextField
										{...params}
										{...rest}
										required
										label="Company"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						)}
					/>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={2}>
				<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
					<Controller
						name="complaintIssueCategory"
						control={control}
						rules={{
							required: "Issue Category is required",
						}}
						render={({ field: { ref, value, onChange, ...rest }, fieldState: { error } }) => (
							<Autocomplete
								disablePortal
								disabled={IssueCategoryLoading || loading}
								options={issueCategories}
								value={value}
								onChange={(e, value) => {
									onChange(value);
								}}
								isOptionEqualToValue={(option, value) => {
									return option.id === value.id;
								}}
								getOptionLabel={(value) => value.name || ""}
								renderInput={(params) => (
									<TextField
										{...params}
										{...rest}
										required
										label="Issue Category"
										error={error ? true : false}
										helperText={error ? error.message : ""}
									/>
								)}
							/>
						)}
					/>
				</Grid2>
			</Grid2>
		</ComplaintFieldsLayout>
	);
}
