import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from "@mui/material";
import TumbleWeed from "./TumbleWeed";

interface CreditsModalProps {
	open: boolean;
	onClose: () => void;
}

export default function CreditsModal({ open, onClose }: CreditsModalProps) {
	return (
		<Dialog aria-modal open={open} onClose={onClose}>
			<DialogTitle>Credits</DialogTitle>
			<DialogContent>
				<DialogContentText>Credits to all the assets we&apos;ve used</DialogContentText>
				<DialogContentText>
					<Typography variant="h6">Tumbleweed icon:</Typography>
					<TumbleWeed />
					<a href="https://www.flaticon.com/free-icons/cactus" title="cactus icons">
						Cactus icons created by Freepik - Flaticon
					</a>
				</DialogContentText>
				<DialogContentText>
					<a href="https://www.freepik.com/" title="images">
						Imaged Provided by Freepik
					</a>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}
