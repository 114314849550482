import { useNavigate } from "react-router-dom";
import { addUser } from "../../../firebase/config";
import { enqueueSnackbar } from "notistack";
import { genericErrorFunction } from "../../../utils/helpers";
import SupervisorFields from "../../../components/authedComponents/crudComponents/Supervisor/SupervisorFields";
import { AddSupervisorPayloadInterface } from "../../../utils/types";

export default function AddSupervisor() {
	const navigate = useNavigate();
	const addAdmin = async (payload: AddSupervisorPayloadInterface) => {
		try {
			await addUser(payload).then((response) => {
				if (response.data.status === "success") {
					enqueueSnackbar("Supervisor Created!", { variant: "success" });
					navigate(-1);
				}
			});
		} catch (error) {
			genericErrorFunction({ logMessage: "Error occurred while adding supervisor: ", error });
		}
	};

	return <SupervisorFields type="Add" apiCall={addAdmin} />;
}
