import { useNavigate } from "react-router-dom";
import ClientFields from "../../../components/authedComponents/crudComponents/Client/ClientFields";
import { genericErrorFunction } from "../../../utils/helpers";
import { AddClientPayloadInterface } from "../../../utils/types";
import { firestore } from "../../../firebase/config";
import { CLIENTS_COLLECTION } from "../../../constants/firebaseConstants";
import { addDoc, collection } from "firebase/firestore";
import { enqueueSnackbar } from "notistack";

export default function AddClient() {
	const navigate = useNavigate();
	const addClient = async (payload: AddClientPayloadInterface) => {
		try {
			await addDoc(collection(firestore, CLIENTS_COLLECTION), payload)
				.then(() => {
					enqueueSnackbar("Client Created!", { variant: "success" });
				})
				.finally(() => {
					navigate(-1);
				});
		} catch (error) {
			genericErrorFunction({ logMessage: "Error occurred while adding client: ", error });
		}
	};

	return <ClientFields type="Add" apiCall={addClient} />;
}
