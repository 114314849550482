import { collection, getDocs, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { CLIENTS_COLLECTION } from "../constants/firebaseConstants";
import { auth, firestore } from "../firebase/config";
import { genericErrorFunction } from "../utils/helpers";
import { Client } from "../utils/types";
import { signInAnonymously } from "firebase/auth";

export default function useClientsUnAuth() {
	const [clients, setClients] = useState<Array<Client>>([]);
	const [loading, setLoading] = useState(true);
	const [shouldFetch, refetch] = useState(true);

	const getData = async () => {
		const clientsQuery = query(collection(firestore, CLIENTS_COLLECTION));

		await getDocs(clientsQuery)
			.then((snapshot) => {
				snapshot.forEach((doc) => {
					const data = doc.data();
					const client: Client = {
						id: doc.id,
						name: data.name,
						description: data.description,
						projects: data.projects,
					};
					setClients((prev) => [...prev, client]);
				});
			})
			.finally(() => {
				setLoading(false);
				refetch(false);
			});
	};

	const getClients = async () => {
		if (shouldFetch) {
			setClients([]);
			setLoading(true);
			try {
				// If an anon user exists we use that
				if (auth.currentUser && auth.currentUser.isAnonymous) {
					await getData();
				}
				// else if anon user does not exist we create one
				else if (auth.currentUser === null) {
					await signInAnonymously(auth).then(async (user) => {
						await getData();
					});
				}
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while fetching clients: ", error });
			}
		}
	};

	useEffect(() => {
		getClients();
	}, [shouldFetch]);

	return { clients, loading, refetch };
}
