import React from "react";
import { Link } from "react-scroll";
import styled from "styled-components";
// Assets
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/img/logo.png";
import CloseIcon from "../../../assets/svg/CloseIcon";
import { LOGIN_, TITLE } from "../../../constants/textConstants";

interface SidebarProps {
	sidebarOpen: boolean;
	toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

interface WrapperProps {
	sidebarOpen: boolean;
}

export default function Sidebar({ sidebarOpen, toggleSidebar }: SidebarProps) {
	return (
		<Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
			<SidebarHeader className="flexSpaceCenter">
				<div className="flexNullCenter">
					{/* <LogoIcon /> */}
					<img
						src={Logo}
						style={{
							width: "184px",
							height: "46px",
						}}
					/>
					{/* <h1 className="whiteColor font20" style={{ marginLeft: "15px" }}>
						{TITLE}
					</h1> */}
				</div>
				<CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
					<CloseIcon />
				</CloseBtn>
			</SidebarHeader>

			<UlStyle className="flexNullCenter flexColumn">
				<li className="semiBold font15 pointer">
					<Link
						onClick={() => toggleSidebar(!sidebarOpen)}
						activeClass="active"
						className="whiteColor"
						style={{ padding: "10px 15px" }}
						to="home"
						spy={true}
						smooth={true}
						offset={-60}
					>
						Home
					</Link>
				</li>
				<li className="semiBold font15 pointer">
					<Link
						onClick={() => toggleSidebar(!sidebarOpen)}
						activeClass="active"
						className="whiteColor"
						style={{ padding: "10px 15px" }}
						to="services"
						spy={true}
						smooth={true}
						offset={-60}
					>
						Clients
					</Link>
				</li>
				<li className="semiBold font15 pointer">
					<Link
						onClick={() => toggleSidebar(!sidebarOpen)}
						activeClass="active"
						className="whiteColor"
						style={{ padding: "10px 15px" }}
						to="projects"
						spy={true}
						smooth={true}
						offset={-60}
					>
						Services
					</Link>
				</li>
				<li className="semiBold font15 pointer">
					<Link
						onClick={() => toggleSidebar(!sidebarOpen)}
						activeClass="active"
						className="whiteColor"
						style={{ padding: "10px 15px" }}
						to="contact"
						spy={true}
						smooth={true}
						offset={-60}
					>
						Contact
					</Link>
				</li>
			</UlStyle>
			<UlStyle className="flexSpaceCenter flexColumn">
				<li className="semiBold font15 pointer">
					<NavLink to={LOGIN_} style={{ padding: "10px 30px 10px 28px" }} className="whiteColor">
						Log in
					</NavLink>
				</li>
			</UlStyle>
		</Wrapper>
	);
}

const Wrapper = styled.nav<WrapperProps>`
	width: 400px;
	height: 100vh;
	position: fixed;
	top: 0;
	padding: 0 30px;
	right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
	z-index: 9999;
	@media (max-width: 400px) {
		width: 100%;
	}
`;
const SidebarHeader = styled.div`
	padding: 20px 0;
`;
const CloseBtn = styled.button`
	border: 0px;
	outline: none;
	background-color: transparent;
	padding: 10px;
`;
const UlStyle = styled.ul`
	padding: 40px;
	li {
		margin: 20px 0;
	}
`;
