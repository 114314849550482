import Slider, { Settings } from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../../assets/img/clients/logo01.png";
import ClientLogo02 from "../../../assets/img/clients/logo02.png";
import ClientLogo03 from "../../../assets/img/clients/logo03.png";
import ClientLogo04 from "../../../assets/img/clients/logo04.png";
import ClientLogo05 from "../../../assets/img/clients/logo05.png";
import ClientLogo06 from "../../../assets/img/clients/logo06.png";

export default function ClientSlider() {
	const settings: Settings = {
		autoplay: true,
		infinite: true,
		// adaptiveHeight: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};
	return (
		<div>
			<Slider className="slick-custom" {...settings}>
				<LogoWrapper className="flexCenter">
					<ImgStyle src={ClientLogo01} alt="client logo" />
				</LogoWrapper>
				<LogoWrapper className="flexCenter">
					<ImgStyle src={ClientLogo02} alt="client logo" />
				</LogoWrapper>
				<LogoWrapper className="flexCenter">
					<ImgStyle src={ClientLogo03} alt="client logo" />
				</LogoWrapper>
				<LogoWrapper className="flexCenter">
					<ImgStyle src={ClientLogo04} alt="client logo" />
				</LogoWrapper>
				<LogoWrapper className="flexCenter">
					<ImgStyle src={ClientLogo05} alt="client logo" />
				</LogoWrapper>
				<LogoWrapper className="flexCenter">
					<ImgStyle src={ClientLogo06} alt="client logo" />
				</LogoWrapper>
			</Slider>
		</div>
	);
}

const LogoWrapper = styled.div`
	display: flex !important;
	align-items: center !important;
	width: 100%;
	cursor: pointer;
	:focus-visible {
		outline: none;
		border: 0px;
	}
`;
const ImgStyle = styled.img`
	width: 100%;
	height: 100%;
	padding: 10%;
`;
