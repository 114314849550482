import { type MRT_ColumnDef as MRTColumnDef } from "material-react-table";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ADMINS, ADMINS_ } from "../../../../constants/textConstants";
import useAdmins from "../../../../hooks/useAdmins";
import { Admin } from "../../../../utils/types";
import ConfirmationModal from "../../ConfirmationModal";
import Table from "../../Table";
import { deleteUser } from "../../../../firebase/config";
import { enqueueSnackbar } from "notistack";
import { genericErrorFunction } from "../../../../utils/helpers";
import usePermissions from "../../../../hooks/usePermissions";

export default function AdminTable() {
	const navigate = useNavigate();
	const { getPermission } = usePermissions();
	const { admins, loading, refetch } = useAdmins();
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [selectedAdmin, setSelectedAdmin] = useState<Admin | null>(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	const deleteAdmin = async () => {
		if (selectedAdmin) {
			try {
				setDeleteLoading(true);
				await deleteUser({ id: selectedAdmin.id })
					.then((response) => {
						if (response.data.status === "success") {
							enqueueSnackbar("Admin Deleted!", { variant: "success" });
						}
					})
					.finally(() => {
						setDeleteLoading(false);
						setOpenDeleteModal(false);
						setSelectedAdmin(null);
						refetch(true);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while deleting admin: ", error });
			}
		}
	};

	const adminColumns: Array<MRTColumnDef<Admin>> = [
		{
			accessorKey: "firstName",
			header: "First Name",
			size: 150,
		},
		{
			accessorKey: "lastName",
			header: "Last Name",
			size: 150,
		},
		{
			accessorKey: "email",
			header: "Email",
			size: 200,
		},
		{
			accessorKey: "cnic",
			header: "CNIC",
			size: 150,
		},
	];

	return (
		<>
			<Table
				columns={adminColumns}
				data={admins}
				title={ADMINS}
				isLoading={loading}
				addButtonOnClick={
					getPermission(ADMINS_, "admin_add")
						? () => {
								navigate(`/${ADMINS_}/add`);
						  }
						: undefined
				}
				editButtonOnClick={
					getPermission(ADMINS_, "admin_edit")
						? (row) => {
								navigate({
									pathname: `/${ADMINS_}/edit`,
									search: createSearchParams({
										id: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
				deleteButtonOnClick={
					getPermission(ADMINS_, "admin_delete")
						? (row) => {
								setOpenDeleteModal(true);
								setSelectedAdmin(row.original);
						  }
						: undefined
				}
				onRowClick={
					getPermission(ADMINS_, "admin_view")
						? (event, row) => {
								navigate({
									pathname: `/${ADMINS_}/view`,
									search: createSearchParams({
										id: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
			/>
			<ConfirmationModal
				open={openDeleteModal}
				onClose={() => {
					setOpenDeleteModal(false);
					setSelectedAdmin(null);
				}}
				title="Delete Admin"
				description="Are you sure you want to delete the following admin. This action is irreversible."
				apiCall={deleteAdmin}
				loading={deleteLoading}
			/>
		</>
	);
}
