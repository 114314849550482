import { faFileExcel, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import { deleteDoc, doc, writeBatch } from "firebase/firestore";
import { type MRT_ColumnDef as MRTColumnDef } from "material-react-table";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { INVENTORY_COLLECTION, INVENTORY_LOGS_COLLECTION } from "../../../../constants/firebaseConstants";
import { INVENTORY, INVENTORY_ } from "../../../../constants/textConstants";
import { firestore } from "../../../../firebase/config";
import useClients from "../../../../hooks/useClients";
import useInventory from "../../../../hooks/useInventory";
import usePermissions from "../../../../hooks/usePermissions";
import { RootState } from "../../../../redux/store";
import { genericErrorFunction, getClientName, getItemLogs } from "../../../../utils/helpers";
import { Inventory } from "../../../../utils/types";
import ConfirmationModal from "../../ConfirmationModal";
import Table from "../../Table";
import InventoryChangeModal from "./InventoryChangeModal";
import InventoryExcelExportModal from "./InventoryExcelExportModal";
import InventoryFieldsModal from "./InventoryFieldsModal";

export default function InventoryTable() {
	const navigate = useNavigate();
	const { role } = useSelector((state: RootState) => state.user);
	const { getPermission } = usePermissions();
	const { inventory, loading, refetch } = useInventory();
	const { clients, loading: clientsLoading } = useClients();
	const [modalState, setModalState] = useState<"add" | "edit">("add");
	const [openFieldsModal, setOpenFieldsModal] = useState(false);
	const [openIncrementModal, setOpenIncrementModal] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openExportModal, setOpenExportModal] = useState(false);
	const [selectedInventoryItem, setSelectedInventoryItem] = useState<Inventory | null>(null);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [inventoryChangeModalState, setInventoryChangeModalState] = useState<"add" | "subtract">("add");

	const deleteClient = async () => {
		if (selectedInventoryItem) {
			try {
				setDeleteLoading(true);
				await deleteDoc(doc(firestore, INVENTORY_COLLECTION, selectedInventoryItem.id))
					.then(async () => {
						const logs = await getItemLogs(selectedInventoryItem.id);
						const batch = writeBatch(firestore);

						logs.forEach((item) => {
							const logRef = doc(firestore, INVENTORY_LOGS_COLLECTION, item.id);
							batch.delete(logRef);
						});

						await batch.commit().then(() => {
							enqueueSnackbar("Item Deleted!", { variant: "success" });
						});
					})
					.finally(() => {
						setDeleteLoading(false);
						setOpenDeleteModal(false);
						setSelectedInventoryItem(null);
						refetch(true);
					});
			} catch (error) {
				genericErrorFunction({ logMessage: "Error occurred while deleting inventory item: ", error });
			}
		}
	};

	const inventoryColumnsForSupervisor: Array<MRTColumnDef<Inventory>> = [
		{
			accessorKey: "name",
			header: "Item",
			size: 150,
		},
		{
			accessorKey: "quantity",
			header: "Quantity",
			size: 150,
		},
	];

	const inventoryColumnsForAdmin: Array<MRTColumnDef<Inventory>> = [
		{
			accessorKey: "name",
			header: "Item",
			size: 150,
		},
		{
			accessorKey: "quantity",
			header: "Quantity",
			size: 150,
		},
		{
			accessorKey: "clientId",
			header: "Client",
			Cell: (row) => <span>{getClientName(clients, row.row.original.clientId)}</span>,
			size: 150,
		},
	];

	return (
		<>
			<Table
				columns={role === "ADMIN" ? inventoryColumnsForAdmin : inventoryColumnsForSupervisor}
				data={inventory}
				title={INVENTORY}
				isLoading={loading || clientsLoading}
				addButtonOnClick={
					getPermission(INVENTORY_, "inventory_add")
						? () => {
								setModalState("add");
								setSelectedInventoryItem(null);
								setOpenFieldsModal(true);
						  }
						: undefined
				}
				editButtonOnClick={
					getPermission(INVENTORY_, "inventory_edit")
						? (row) => {
								setModalState("edit");
								setSelectedInventoryItem(row.original);
								setOpenFieldsModal(true);
						  }
						: undefined
				}
				deleteButtonOnClick={
					getPermission(INVENTORY_, "inventory_delete")
						? (row) => {
								setOpenDeleteModal(true);
								setSelectedInventoryItem(row.original);
						  }
						: undefined
				}
				onRowClick={
					getPermission(INVENTORY_, "inventory_view")
						? (event, row) => {
								navigate({
									pathname: `/${INVENTORY_}/view`,
									search: createSearchParams({
										id: row.original.id,
									}).toString(),
								});
						  }
						: undefined
				}
				customActionButtonOne={
					getPermission(INVENTORY_, "inventory_increment")
						? (row) => (
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										setSelectedInventoryItem(row.original);
										setInventoryChangeModalState("add");
										setOpenIncrementModal(true);
									}}
								>
									<FontAwesomeIcon size={"xs"} icon={faPlus} />
								</IconButton>
						  )
						: undefined
				}
				customActionButtonTwo={
					getPermission(INVENTORY_, "inventory_decrement")
						? (row) => (
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										setSelectedInventoryItem(row.original);
										setInventoryChangeModalState("subtract");
										setOpenIncrementModal(true);
									}}
								>
									<FontAwesomeIcon size={"xs"} icon={faMinus} />
								</IconButton>
						  )
						: undefined
				}
				customToolbarButton={
					getPermission(INVENTORY_, "inventory_export")
						? () => (
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										setOpenExportModal(true);
									}}
								>
									<FontAwesomeIcon icon={faFileExcel} />
								</IconButton>
						  )
						: undefined
				}
			/>
			<InventoryFieldsModal
				state={modalState}
				inventoryItem={selectedInventoryItem}
				open={openFieldsModal}
				onClose={() => {
					setOpenFieldsModal(false);
					setSelectedInventoryItem(null);
				}}
				refetch={() => {
					refetch(true);
				}}
			/>
			<InventoryChangeModal
				state={inventoryChangeModalState}
				inventoryItem={selectedInventoryItem}
				open={openIncrementModal}
				onClose={() => {
					setOpenIncrementModal(false);
					setSelectedInventoryItem(null);
				}}
				refetch={() => {
					refetch(true);
				}}
			/>
			<InventoryExcelExportModal
				open={openExportModal}
				onClose={() => {
					setOpenExportModal(false);
				}}
			/>
			<ConfirmationModal
				open={openDeleteModal}
				onClose={() => {
					setOpenDeleteModal(false);
					setSelectedInventoryItem(null);
				}}
				title="Delete Item"
				description="Are you sure you want to delete the following inventory item. All its logs will also be deleted. This action is irreversible."
				apiCall={deleteClient}
				loading={deleteLoading}
			/>
		</>
	);
}
