import { Button, Grid2, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { ComplaintFields } from "../../../pages/unAuthedPages/AddComplaint";
import ComplaintFieldsLayout from "./ComplaintFieldsLayout";
import FileUploadButton from "../../FileUploadButton";

interface ComplaintInformationProps {
	control: Control<ComplaintFields, any>;
	onNextClick: () => Promise<void>;
}

export default function ComplaintInformation({ control, onNextClick }: ComplaintInformationProps) {
	return (
		<ComplaintFieldsLayout title="Enter Details of Your Complaint." onButtonClick={onNextClick}>
			<Grid2 container spacing={2}>
				<Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
					<Controller
						name="complaintSubject"
						control={control}
						rules={{
							required: "Complaint Subject is required",
						}}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								fullWidth
								required
								label="Complaint Subject"
								placeholder="Briefly Describe the Issue"
								error={error ? true : false}
								helperText={error ? error.message : ""}
							/>
						)}
					/>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={2}>
				<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
					<Controller
						name="complaintDescription"
						control={control}
						rules={{
							required: "Complaint Description is required",
						}}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								multiline
								minRows={5}
								maxRows={7}
								fullWidth
								required
								label="Complaint Description"
								placeholder="Provide Detailed Information"
								error={error ? true : false}
								helperText={error ? error.message : ""}
							/>
						)}
					/>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={2}>
				<Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 8, xl: 8 }}>
					<Controller
						name="files"
						control={control}
						render={({ field: { value, onChange, ...rest } }) => (
							<FileUploadButton value={value} onChange={onChange} />
						)}
					/>
				</Grid2>
			</Grid2>
		</ComplaintFieldsLayout>
	);
}
