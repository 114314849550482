import { Box } from "@mui/material";
import ContactTable from "../../../components/authedComponents/crudComponents/Contact/ContactTable";

export default function Contacts() {
	return (
		<Box
			sx={{
				height: "100%",
			}}
		>
			<ContactTable />
		</Box>
	);
}
