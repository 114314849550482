import { Grid2, TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { EMAIL_REGEX } from "../../../constants/helperConstants";
import { ComplaintFields } from "../../../pages/unAuthedPages/AddComplaint";
import PhoneNumberTextField from "../../authedComponents/PhoneNumberTextField";
import ComplaintFieldsLayout from "./ComplaintFieldsLayout";
import { isValidPhoneNumber } from "libphonenumber-js/max";

interface PersonalInformationProps {
	control: Control<ComplaintFields, any>;
	onSubmitClick: () => Promise<void>;
	loading: boolean;
}

export default function PersonalInformation({ control, onSubmitClick, loading }: PersonalInformationProps) {
	return (
		<ComplaintFieldsLayout
			title="Provide your contact information."
			buttonText="Submit"
			onButtonClick={onSubmitClick}
			loading={loading}
		>
			<Grid2 container spacing={2}>
				<Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
					<Controller
						name="name"
						control={control}
						rules={{
							required: "Full Name is required",
						}}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								disabled={loading}
								fullWidth
								required
								label="Full Name"
								error={error ? true : false}
								helperText={error ? error.message : ""}
							/>
						)}
					/>
				</Grid2>
				<Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
					<Controller
						name="phoneNumber"
						control={control}
						rules={{
							required: "Phone Number is required",
							validate: {
								isPhoneValid: (value) => {
									if (isValidPhoneNumber(value, "PK") === false) {
										return "Phone Number is invalid";
									}
								},
							},
						}}
						render={({ field: { ref, ...rest }, fieldState: { error } }) => (
							<PhoneNumberTextField
								{...rest}
								disabled={loading}
								fullWidth
								required
								label="Phone Number"
								error={error ? true : false}
								helperText={error ? error.message : ""}
							/>
						)}
					/>
				</Grid2>
				<Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
					<Controller
						name="email"
						control={control}
						rules={{
							pattern: {
								value: EMAIL_REGEX,
								message: "Incorrect Email",
							},
						}}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								disabled={loading}
								fullWidth
								label="Email"
								error={error ? true : false}
								helperText={error ? error.message : ""}
							/>
						)}
					/>
				</Grid2>
			</Grid2>
			<Grid2 container spacing={2}>
				<Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
					<Controller
						name="company"
						control={control}
						rules={{
							required: "Your Company is required",
						}}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								disabled={loading}
								fullWidth
								required
								label="Your Company"
								error={error ? true : false}
								helperText={error ? error.message : ""}
							/>
						)}
					/>
				</Grid2>
				<Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}>
					<Controller
						name="department"
						control={control}
						rules={{
							required: "Your Department is required",
						}}
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								disabled={loading}
								fullWidth
								required
								label="Your Department"
								error={error ? true : false}
								helperText={error ? error.message : ""}
							/>
						)}
					/>
				</Grid2>
			</Grid2>
		</ComplaintFieldsLayout>
	);
}
