import { StandardTextFieldProps, TextField, TextFieldProps } from "@mui/material";
import { useRef } from "react";

interface NumberTextfieldProps extends StandardTextFieldProps {
	type: "number" | "float";
}

export default function NumberTextField({ type, ...rest }: NumberTextfieldProps) {
	const textfieldRef = useRef<TextFieldProps>(null);

	const checkIfEnteredKeyShouldBeAllowed = (e: React.KeyboardEvent<HTMLDivElement>, type: "number" | "float") => {
		if (type === "number") {
			if (
				Number(e.key) === 0 ||
				Number(e.key) === 1 ||
				Number(e.key) === 2 ||
				Number(e.key) === 3 ||
				Number(e.key) === 4 ||
				Number(e.key) === 5 ||
				Number(e.key) === 6 ||
				Number(e.key) === 7 ||
				Number(e.key) === 8 ||
				Number(e.key) === 9 ||
				e.key === "Backspace" ||
				e.key === "Delete" ||
				e.key === "ArrowLeft" ||
				e.key === "ArrowRight" ||
				e.key === "Tab" ||
				(e.ctrlKey && e.key === "a") ||
				(e.ctrlKey && e.key === "c") ||
				(e.ctrlKey && e.key === "v")
			) {
				return;
			} else {
				e.preventDefault();
			}
		} else if (type === "float") {
			if (
				Number(e.key) === 0 ||
				Number(e.key) === 1 ||
				Number(e.key) === 2 ||
				Number(e.key) === 3 ||
				Number(e.key) === 4 ||
				Number(e.key) === 5 ||
				Number(e.key) === 6 ||
				Number(e.key) === 7 ||
				Number(e.key) === 8 ||
				Number(e.key) === 9 ||
				e.key === "Backspace" ||
				e.key === "Delete" ||
				e.key === "ArrowLeft" ||
				e.key === "ArrowRight" ||
				e.key === "." ||
				e.key === "Tab" ||
				(e.ctrlKey && e.key === "a") ||
				(e.ctrlKey && e.key === "c") ||
				(e.ctrlKey && e.key === "v")
			) {
				if (e.key === "." && textfieldRef.current && String(textfieldRef.current.value).includes(".")) {
					e.preventDefault();
				} else {
					return;
				}
			} else {
				e.preventDefault();
			}
		}
	};

	return (
		<TextField
			{...rest}
			type="number"
			inputRef={textfieldRef}
			inputProps={
				type === "float"
					? {
							step: 0.01,
					  }
					: undefined
			}
			onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
			onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
				checkIfEnteredKeyShouldBeAllowed(e, type);
			}}
		/>
	);
}
