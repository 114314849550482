import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { INVENTORY_COLLECTION } from "../../../../constants/firebaseConstants";
import { firestore } from "../../../../firebase/config";
import useClients from "../../../../hooks/useClients";
import { RootState } from "../../../../redux/store";
import {
	exportToExcel,
	formatInventoryToExportData,
	genericErrorFunction,
	getClientBasedOnRole,
	getItemLogs,
} from "../../../../utils/helpers";
import { Client, Inventory, InventoryItem } from "../../../../utils/types";
import CustomDatePicker from "../../CustomDatePicker";

interface InventoryExcelExportModalProps {
	open: boolean;
	onClose: () => void;
}

interface InventoryExcelExportForm {
	client: Client | null;
	fromDate: Dayjs | null;
	toDate: Dayjs | null;
}

export default function InventoryExcelExportModal({ open, onClose }: InventoryExcelExportModalProps) {
	const { role: loggedInUserRole, clientId: loggedInUserClient } = useSelector((state: RootState) => state.user);
	const { clients, loading: clientLoading } = useClients();
	const [loading, setLoading] = useState(false);

	const { control, handleSubmit, reset } = useForm<InventoryExcelExportForm>({
		mode: "onTouched",
		defaultValues: {
			client: null,
			fromDate: dayjs().subtract(7, "day"),
			toDate: dayjs(),
		},
	});

	const fromDate = useWatch({
		control: control,
		name: "fromDate",
	});

	const toDate = useWatch({
		control: control,
		name: "toDate",
	});

	const handleOnClose = () => {
		if (!loading) {
			reset();
			onClose();
		}
	};

	const onSubmit: SubmitHandler<InventoryExcelExportForm> = async (data) => {
		if (data.fromDate !== null && data.toDate !== null) {
			try {
				setLoading(true);
				const inventoryItemsQuery = query(
					collection(firestore, INVENTORY_COLLECTION),
					where(
						"clientId",
						"==",
						getClientBasedOnRole(loggedInUserRole, loggedInUserClient, data.client, clients).id,
					),
					orderBy("createdAt", "asc"),
				);

				await getDocs(inventoryItemsQuery)
					.then(async (snapshot) => {
						if (data.fromDate !== null && data.toDate !== null) {
							const inventory: Array<Inventory> = [];
							const inventoryItems: Array<InventoryItem> = [];
							// Looping through every inventory item and storing them in a variable
							snapshot.forEach(async (doc) => {
								const data = doc.data();
								const item: Inventory = {
									id: doc.id,
									name: data.name,
									quantity: data.quantity,
									clientId: data.clientId,
									description: data.description,
									createdAt: data.createdAt,
								};
								inventory.push(item);
							});

							// Looping through each item to get their logs. We do it this way because fetching logs in the previous foreach is not possible since await does not work in that. It does however work in this for of loop
							for (const item of inventory) {
								const fetchedLogs = await getItemLogs(item.id, data.fromDate, data.toDate);
								inventoryItems.push({
									...item,
									logs: fetchedLogs,
								});
							}

							// Exporting fetched data to excel
							exportToExcel(
								`${data.fromDate.format("DD/MM/YYYY")} - ${data.toDate.format("DD/MM/YYYY")}`,
								await formatInventoryToExportData(inventoryItems, data.fromDate),
							);
						}
					})
					.finally(() => {
						setLoading(false);
						handleOnClose();
					});
			} catch (error) {
				genericErrorFunction({
					logMessage: "Error occurred while Generating excel File: ",
					error,
					snackbarMessage: "File Could not be generated",
				});
			}
		}
	};

	return (
		<Dialog aria-modal open={open} onClose={handleOnClose}>
			<DialogTitle>Export Items</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<DialogContent>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							marginTop: "20px",
						}}
					>
						<Controller
							name="fromDate"
							control={control}
							rules={{
								required: "From date is required",
								validate: {
									isAfterToDate: (value) => {
										if (dayjs(value).isAfter(toDate)) {
											return "From Date cannot be after to date";
										}
									},
								},
							}}
							render={({ field: { ref, ...rest }, fieldState: { error } }) => (
								<CustomDatePicker
									{...rest}
									label="From Date"
									fullWidth
									required
									disableFuture
									maxDate={toDate}
									error={error ? true : false}
									helperText={error ? error.message : ""}
								/>
							)}
						/>
						<Controller
							name="toDate"
							control={control}
							rules={{
								required: "To date is required",
								validate: {
									isBeforeFromDate: (value) => {
										if (dayjs(value).isBefore(fromDate)) {
											return "To date cannot be before from date";
										}
									},
									isAfterTodaysDate: (value) => {
										if (dayjs(value).isAfter(dayjs())) {
											return "To date cannot be after todays date";
										}
									},
								},
							}}
							render={({ field: { ref, ...rest }, fieldState: { error } }) => (
								<CustomDatePicker
									{...rest}
									label="To Date"
									fullWidth
									required
									disableFuture
									minDate={fromDate}
									error={error ? true : false}
									helperText={error ? error.message : ""}
								/>
							)}
						/>
						{loggedInUserRole === "ADMIN" && loggedInUserClient === null && (
							<Controller
								name="client"
								control={control}
								rules={
									loggedInUserRole === "ADMIN" && loggedInUserClient === null
										? {
												required: "Client is required",
										  }
										: {}
								}
								render={({ field: { ref, value, onChange, ...rest }, fieldState: { error } }) => {
									return (
										<Autocomplete
											disablePortal
											disabled={clientLoading}
											options={clients}
											value={value}
											onChange={(e, value) => {
												onChange(value);
											}}
											isOptionEqualToValue={(option, value) => {
												return option.id === value.id;
											}}
											getOptionLabel={(value) => value.name || ""}
											renderInput={(params) => (
												<TextField
													{...params}
													{...rest}
													label="Client"
													required
													error={error ? true : false}
													helperText={error ? error.message : ""}
												/>
											)}
										/>
									);
								}}
							/>
						)}
					</Box>
				</DialogContent>
				<DialogActions>
					<LoadingButton
						disabled={loading}
						loading={loading}
						loadingPosition="start"
						startIcon={<FontAwesomeIcon icon={faCheck} />}
						type="submit"
					>
						Yes
					</LoadingButton>
					<Button disabled={loading} startIcon={<FontAwesomeIcon icon={faXmark} />} onClick={handleOnClose}>
						No
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
